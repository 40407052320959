import { ISupportWatchedIssues } from "../../interfaces/support.interfaces";
import { IssueCounter, Nyomda, OAuth2User } from "../scroll";

export class OAuth2UserProfileExtensions {
    
    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó nyomda objektum, ha van
     * 
     * @var Nyomda
     */
    nyomda?: Nyomda;

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó hatókörök, ha vannak
     * 
     * @var string[]
     */
    scopes?: string[] = [];

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó OAuth user objektum, ha van
     * 
     * @var OAuth2User
     */
    user?: OAuth2User;

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó megfigyelés lista ha van
     * 
     * @var ISupportWatchedIssues[]
     */
    watchedIssues?: ISupportWatchedIssues[] = [];

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó counterek, ha vannak
     * 
     * @var IssueCounter
     */
    counters?: IssueCounter;

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó nyomda objektum, ha van
     * 
     * @param nyomda 
     * @returns 
     */
    setNyomda(nyomda: Nyomda): OAuth2UserProfileExtensions {
        this.nyomda = nyomda;
        return this;
    }

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó hatókörök tömb formátumban, ha vannak
     * 
     * @param scopes
     * @returns
     */
    setScopesArray(scopes: string[]): OAuth2UserProfileExtensions {
        this.scopes = scopes;
        return this;
    }

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó hatókörök, ha vannak
     * 
     * @param scopes 
     * @returns 
     */
    setScopes(scopes: string): OAuth2UserProfileExtensions {
        this.scopes = scopes.split(' ');
        return this;
    }

    /**
     * Egyedi tulajdonság, később seteljük be! 
     * A felhasználóhoz tartozó OAuth user objektum, ha van
     * 
     * @param user 
     * @returns 
     */
    setUser(user: OAuth2User): OAuth2UserProfileExtensions {
        this.user = user;
        return this;
    }

    setIssueWatchList(list: ISupportWatchedIssues[]): OAuth2UserProfileExtensions {
        this.watchedIssues = list;
        return this;
    }

    getIssueWatchList(): ISupportWatchedIssues[] {
        return this.watchedIssues;
    }

    setIssueCounters(counters: IssueCounter): OAuth2UserProfileExtensions {
        this.counters = counters;
        return this;
    }

    getIssueCounters(): IssueCounter {
        return this.counters;
    }

}