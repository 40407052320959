import { Entities } from '@ratkaiga/core-nextgen';

export class CoreUserProfile extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Auth\\Entity\\UserProfile';

    user_id: number;

    client_id: number;

    nyomda_id: number;

    nyomda_nev: string;

    email: string;

    display_name: string;

    last_seen_at: string;

    parameters?: unknown;

    phone_number?: string;

    phone_verified: boolean = false;

    avatar: 'default.png' | 'blank-user.jpg' | string = 'blank-user.jpg';

    constructor() {
        super();
    }

    /**
     * A felhasználó egyedi azonosítója (gyakorlatilag az oauth id)
     * @returns
     */
    getUserId(): number {
        return this.user_id;
    }

    getClientId(): number {
        return this.client_id;
    }

    getNyomdaId(): number {
        return this.nyomda_id;
    }

    getNyomdaNev(): string {
        return this.nyomda_nev;
    }

    getEmail(): string {
        return this.email;
    }

    getPhoneNumber(): string {
        return this.phone_number;
    }

    isPhoneNumberVerified(): boolean {
        return this.phone_verified;
    }

    getDisplayName(): string {
        return this.display_name;
    }

    getLastSeen(): Date {
        return new Date(this.last_seen_at);
    }

    getAvatar(): string {
        return this.avatar;
    }

    isAvatar(): boolean {
        return !(this.avatar === 'default.png' || this.avatar === 'blank-user.jpg');
    }

}
