import { IProgressButtonState } from "../interfaces/common.interfaces";

export class ProgressButtonState implements IProgressButtonState {

    id: string;
    
    progress: boolean = false;
    
    buttonLabel: string;
    
    progressLabel: string;
    
    formDisabled: boolean = false;

    formError: boolean = false;

    submitError: boolean = false;

    setId(id: string): ProgressButtonState {
        this.id = id;
        return this;
    }

    getId(): string {
        return this.id;
    }

    setProgress(progress: boolean): ProgressButtonState {
        this.progress = progress;
        return this;
    }

    isProgress(): boolean {
        return this.progress;
    }

    setButtonLabel(label: string): ProgressButtonState {
        this.buttonLabel = label;
        return this;
    }

    getButtonLabel(): string {
        return this.buttonLabel;
    }

    setProgressLabel(label: string): ProgressButtonState {
        this.progressLabel = label;
        return this;
    }

    getProgressLabel(): string {
        return this.progressLabel;
    }

    setFormDisabled(disabled: boolean): ProgressButtonState {
        this.formDisabled = disabled;
        return this;
    }

    isFormDisabled(): boolean {
        return this.formDisabled;
    }

    setFormError(error: boolean): ProgressButtonState {
        this.formError = error;
        return this;
    }

    isFormError(): boolean {
        return this.formError;
    }

    setSubmitError(error: boolean): ProgressButtonState {
        this.submitError = error;
        return this;
    }

    isSubmitError(): boolean {
        return this.submitError;
    }

}