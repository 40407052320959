import { AfterContentInit, AfterViewInit, Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Issue } from 'src/app/_core/entities/scroll';

@Component({
  selector: 'spirit-issue-content',
  templateUrl: './issue-content.component.html',
  styleUrls: ['./issue-content.component.scss']
})
export class IssueContentComponent implements OnInit, OnChanges {

  @Input() issue: Issue;

  preloading: boolean;
  preloadingMessage: string = 'Az adatok betöltés alatt';

  language: string;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.language = locale;
  }  

  ngOnInit(): void {
    
    this.preloading = true;
    if (!this.issue) {
      this.issue = new Issue();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.issue) {
      this.preloading = false;
    }
  }

}
