export class TokenEntity {

    access_token: string;

    expires_in: number;

    refresh_token: string; 

    scope: string;

    token_type: string;

    setTokenEntity(_token: unknown): TokenEntity {
        
        const token = _token as TokenEntity;
        this.access_token = token.access_token;
        this.expires_in = token.expires_in;
        this.refresh_token = token.refresh_token;
        this.scope = token.scope;
        this.token_type = token.token_type;

        return this;
    }

    getToken(): string {
        return this.access_token;
    }

    getExpiresIn(): number {
        return this.expires_in;
    }

    getRefreshToken(): string {
        return this.refresh_token;
    }

    getScope(): string {
        return this.scope;
    }

    getScopesArray(): string[] {
        return this.scope.split(" ");
    }

}