import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'spirit-ckeditor-instance',
  templateUrl: './ckeditor-instance.component.html',
  styleUrls: ['./ckeditor-instance.component.scss']
})
export class CkeditorInstanceComponent implements OnInit, OnDestroy {

  editorForm: UntypedFormGroup;

  @Input() editorName: string;

  @Input() editorContentInitial: string;

  @Input() editorReset: Subject<string> = new Subject();

  @Output() editorContent: EventEmitter<string> = new EventEmitter();

  destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.editorForm = new UntypedFormGroup({
      fileserviceApp: new UntypedFormControl('ScrollMax\\Scrollsupport'),
      fileserviceEntity: new UntypedFormControl('ScrollMax\\Scrollsupport\\Entity\\Issue'),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onEditorChanged(event: string): void {
    this.editorContent.emit(event);
  }

}
