import { Entities, MetaArray } from '@ratkaiga/core-nextgen';
import { SupportUserProfile } from './support-user-profile.entity';

export class TodoItem extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgTodoItem';

    owner: SupportUserProfile;

    title: string;

    severity_level: string;

    readiness_level: number;

    need_doc: boolean;

    need_test: boolean;

    tags: string[] = [];

    estimated_time: string;

    remarks: string;

    getOwner(): SupportUserProfile {
        return this.owner;
    }

    getTitle(): string {
        return this.title;
    }

    getSeverityLevel(): string {
        return this.severity_level;
    }

    getReadlinessLevel(): number {
        return this.readiness_level;
    }

    isNeedDoc(): boolean {
        return this.need_doc ?? false;
    }

    isNeedTest(): boolean {
        return this.need_test ?? false;
    }

    getTags(): string[] {
        return this.tags;
    }

    getEstimatedTime(): string {
        return this.estimated_time;
    }

    getRemarks(): string {
        return this.remarks;
    }

    /**
     * Felveszünk egy tag-et, amennyiben azt még nem tartalmazza a tömbünk
     * 
     * @param tag 
     * @returns 
     */
    addTag(tag: string): TodoItem {

        if (!this.tags.includes(tag)) {
            this.tags.push(tag);
        }

        return this;
    }

    /**
     * Törlünk egy elemet a listából
     * 
     * @param tag 
     * @returns 
     */
    removeTag(tags: { value: string, __isValid: boolean, __tagId: string}[]): TodoItem {
        
        const changes: string[] = [];
        tags.forEach(item => {
            changes.push(item.value);
        });
        
        this.tags.forEach((item, index) => {
            if (!changes.includes(item)) {
                this.tags.splice(this.tags.indexOf(item, 0), 1)
            }
        });

        return this;
    }

}