import { Component, Input } from '@angular/core';
import { ICoreFileserviceQuote } from 'src/app/_core/interfaces/fileservice.interfaces';

@Component({
  selector: 'spirit-drive-header-widget',
  templateUrl: './drive-header-widget.component.html',
  styleUrls: ['./drive-header-widget.component.scss']
})
export class DriveHeaderWidgetComponent {

  @Input() driveStats: ICoreFileserviceQuote;

}
