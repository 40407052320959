import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbrevation'
})
export class AbbrevationPipe implements PipeTransform {

  transform(value: string): string {

    try {
      const p = value.split(' ');
      return [p[0].slice(0, 1).toUpperCase(), p[p.length - 1].slice(0, 1).toUpperCase()].join('');
    } catch (e) {
      return (value.slice(0, 1)).toUpperCase();
    }
    
  }

}
