import { Entities } from '@ratkaiga/core-nextgen';
import { Nyomda } from './nyomda.entity';

export class TransactionSMS extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Message\\Entity\\SMS';

    phone_number: string;
    
    message: string;

    retries: number;

    quota: boolean;

    lastRetry: string;

    callbackLastError: string;

    callbacklasterrormessage: string;

    callbackDate: string;

    lastError: string;

    lasterrormessage: string;

    nyomda: Nyomda = new Nyomda();

    status: 'SENT' | 'ERROR' | 'QUEUED';

    split: number;

    price: number;

    getPhoneNumber(): string {
        return this.phone_number;
    }

    getMessage(): string {
        return this.message;
    }

    getRetries(): number {
        return this.retries;
    }

    getLastError(): string {
        return this.lastError;
    }

    getLastErrorMessage(): string {
        return this.lasterrormessage;
    }

    getNyomdaId(): number {
        return this.nyomda.id;
    }

    getNyomdaNev(): string {
        return this.nyomda.nev;
    }

    getStatus(): string {
        return this.status;
    }

    getCallbackDate(): string {
        return this.callbackDate;
    }

    getPrice(): number {
        return this.price;
    }

    isError(): boolean {
        return (this.status === 'ERROR');
    }
}