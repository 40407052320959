import { Entities } from '@ratkaiga/core-nextgen';

export class SupportUserAutocomplete extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgUserAutocomplete';

    user_id: number;
    
    user_name: string; 
    
    scopes: string[] = [];
    
    nyomda_id: number;

    nyomda_nev: string;

    getId(): number {
        return this.user_id;
    }

    getName(): string {
        return this.user_name;
    }

    getNyomdaId(): number {
        return this.nyomda_id;
    }

    getNyomdaNev(): string {
        return this.nyomda_nev;
    }

    hasScope(scope: string): boolean {
        return this.scopes.includes(scope);
    }

}