 import Command from '@ckeditor/ckeditor5-core/src/command';
 import { createInlineImageViewElement, isImageAllowed } from '@ckeditor/ckeditor5-image/src/image/utils';

 export default class AssetExplorerCommand extends Command {
     /**
      * @inheritDoc
      */
     refresh() {
         // this.isEnabled = isImageAllowed( this.editor.model );
     }
 
     /**
      * Executes the command.
      *
      * @fires execute
      * @param {Object} options Options for the executed command.
      * @param {String|Array.<String>} options.source The image source or an array of image sources to insert.
      */
     execute( options ) {
         const model = this.editor.model;
 
         model.change( writer => {
             const sources = Array.isArray( options.source ) ? options.source : [ options.source ];
 
             for ( const src of sources ) {
                 createInlineImageViewElement( writer, model, { src } );
             }
         } );
     }
 }
 