
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoreService } from '../service/core.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private coreService: CoreService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        
        if (environment.debug) {
            console.log('[DEBUG] AuthInterceptor', req.url, 'canIntercept:', this.coreService.canAuthAndIntercept());
        }        
        
        if (this.coreService.canAuthAndIntercept(req.url)) 
        {
            let authReq = req;
            const token = localStorage.getItem(environment.storage.token);
            if (token != null) {
                authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
            }

            return next.handle(authReq);
        }

        return next.handle(req);
    }
}

export const AuthInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];