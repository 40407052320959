import { Entities, CoreResponseData } from '@ratkaiga/core-nextgen';
import { OAuth2Client } from './oauth-client.entity';

export class OAuth2User extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Auth\\Entity\\OAuth2Users';

    email: string;

    first_name: string;

    last_name: string;

    oAuth2Clients: OAuth2Client;

    scopes: CoreResponseData<unknown>;

    /**
     * Belső változóként használjuk és gyakorlatilag ha lekérdezünk akkor ebből 
     * fogunk lekérdezni, nem pedig az objektumból.
     */
    private _scopes: string[];

    constructor() {
        super();
    }

    getClient(): OAuth2Client {
        return this.oAuth2Clients;
    }

    getNyomdaId(): number {
        return this.getClient().getNyomdaId();
    }

    getNyomdaNev(): string {
        return this.getClient().getNyomdaNev();
    }

    getFirstName(): string {
        return this.first_name;
    }

    getLastName(): string {
        return this.last_name;
    }

    getEmail(): string {
        return this.email;
    }

    getRawScopes(): CoreResponseData<unknown> {
        return this.scopes;
    }

    /**
     * Kényelmi funkció annak a megállapításához, hogy a felhasználó rendelkezik -e az adott 
     * scope -val vagy sem.
     * 
     * @param scope 
     * @returns 
     */
    hasScope(scope: string): boolean {
        this.parseScopes(); return this._scopes.includes(scope);
    }

    private parseScopes(): void {
        this._scopes = [];
        Object.keys(this.scopes).forEach(k => {
            this._scopes.push(this.scopes[k] as string);
        });
    }

}