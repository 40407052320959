import { Directive, Injectable, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Directive({
  selector: '[unavailableScopes]'
})
export class ScopeUnavailableDirective {

  private hasView: boolean = false;

  @Input() set unavailableScopes(scopes: string) {
    
    if (!this.hasAvailable(scopes) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    } else if (this.hasAvailable(scopes) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) {}


  private hasAvailable(scope: string): boolean {
   return !this.authService.getUserCurrentScopes()?.includes(scope);
  }

}
