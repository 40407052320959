import { Entities, MetaArray } from '@ratkaiga/core-nextgen';
import { Nyomda } from '../nyomda.entity';
import { SupportUserProfile } from './support-user-profile.entity';
import { Issue } from './support-issue.entity';

export class IssueOffer extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssueOffer';

    remarks?: string;

    nyomda: Nyomda;

    worktime: number;

    netprice: number;

    discount: number;

    deadline?: string;

    sla_hours: number;

    sender: SupportUserProfile;

    responser?: SupportUserProfile;

    accepted: boolean = false;

    declined: boolean = false;

    pending: boolean = true;

    issue: Issue;

    issueupdate_id: number;

    getTotal(): number {

        const total = this.worktime * this.netprice;
        const discount = this.discount / 100;

        return total - (total * discount);

    }

    isDeclined(): boolean {
        return this.declined;
    }

    isAccepted(): boolean {
        return this.accepted;
    }

    getResponser(): SupportUserProfile {
        return this.responser;
    }

}