import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CoreEntity } from '@ratkaiga/core-nextgen/lib/entity';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

    constructor() { }

    static formatDateYMD(date: string): string {

        const dt = new Date(date);
        return StaticService.padNumber(dt.getFullYear(), 4) + '-' + StaticService.padNumber(dt.getMonth() + 1, 2) + '-' + StaticService.padNumber(dt.getDate(), 2);
    }

    static convertDateStructToString(value: NgbDate | NgbDateStruct): string {
        return StaticService.padNumber(value.year, 4) + '-' + StaticService.padNumber(value.month, 2) + '-' + StaticService.padNumber(value.day, 2);
    }

    static convertDateToStruct(value: string): NgbDate | NgbDateStruct {
        return new NgbDate(new Date(value).getFullYear(), new Date(value).getMonth() + 1, new Date(value).getDate());
    }

    static getCurrentLocaleDateTime(): string {
        let dt = new Date();
        return dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString();
    }

    static convertToHours(value: string): string {        
        
        if (value && value.endsWith('h')) {
            return value.replace('h', '');
        }

        if (value && value.endsWith('d')) {
            return (parseFloat(value.replace('d', '')) * 8).toFixed(0);
        }

        if (value && value.endsWith('w')) {
            return (parseFloat(value.replace('w', '')) * (5 * 8)).toFixed(0);
        }

        return value;
    }

    /**
     * Kényelmi funkció egy adott típusú entitás tulajdonságának összehasonlításához. Ezt egyedi submitoknál 
     * használjuk és azt is teszteljük, hogy a tulajdonság nem undefined-e.
     * 
     * @param newEntity 
     * @param originalEntity 
     * @param field 
     * @returns 
     */
    static isEntityChanged<T>(newEntity: T, originalEntity: T, field: string): boolean {
        return (newEntity[field] !== originalEntity[field] && newEntity[field] !== undefined);
    }

    /**
     * Kényelmi funkció mindenféle objektumokat tartalmazó tömb 
     * sortolásához valamely objektum tulajdonság alapján.
     * 
     * @param array 
     * @param propName 
     * @param order 
     */
    static sortObjectArrayByProperty<T>(array: T[], propName: keyof T, order: 'ASC' | 'DESC'): void {
      
      array.sort((a, b) => {
          if (a[propName] < b[propName]) {
              return -1;
          }
          if (a[propName] > b[propName]) {
              return 1;
          }
          return 0;
      });

      if (order === 'DESC') {
          array.reverse();
      }

    }

    static isNull(value: unknown): boolean {
        return (value === undefined || value === '' || value === null);
    }

    /**
     * Ez a funkció kezeli a speciális null értékeket. Ha nincs érték, üres, undefined, null, akkor egy üres stringet 
     * adunk vissza a REST API felé. Ellenkező esetben az aktuális bemeneti értékkel térünk vissza.
     * 
     * @param value 
     */
    static handleNullOrValueFields(value: any): any {

      if (value === undefined || value === '' || value === null) {
          return '';
      } else {
          return value;
      }
    }

    /**
     * Ez a funkció egy boolean-al tér vissza egy numerikus bemenet után, ahol csak az 1 érték az igaz, minden más hamis.
     * 
     * @param value 
     */
    static handleLogicalValueFromNumericValue(value: number) {
        return (value === 1) ? true : false;
    }

    /**
     * Ez a funkció checkbox típusú mezők validálásához nyújt kényelmes megoldást. Ha az érték true 
     * vagy 1 akkor a kimenet true, minden más esetben false (null, undefined, stb.)
     * 
     * @param value 
     */
    static validateCheckboxFieldValue(value: any): boolean {
        return (value === true || value === 1) ? true : false;
    }

    /**
     * Ez a funkció a handleNullOrValueFields-hez hasonlóan ellenőriz, de egy logikai érték a kimenete. Opcionális paraméterezésnél 
     * használjuk.
     * 
     * @param value 
     */
    static checkFieldValueAvailable(value: any): boolean {
        return (value === undefined || value === '' || value === null) ? false : true;
    }

    /**
     * Saját logikánt szerint validálunk egy FormGroup objektumot. Ezt Angular15-ben 
     * már csak UntypedFormGroup esetén csináljuk.
     * 
     * @param formGroup 
     */
    static validateAllFormFields(formGroup: UntypedFormGroup) {

        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                StaticService.validateAllFormFields(control);
            }
        });
    }

    /**
     * Kémnyelmi funkció egy adott UntypedFormGroup objektum adott 
     * mezőjének validációjához.
     * 
     * @param form 
     * @param field 
     * @returns 
     */
    static isFieldValid(form: UntypedFormGroup, field: string): boolean {
        return ((!form.get(field).valid && form.get(field).touched) ||
            (form.get(field).untouched && !form.get(field).pristine));
    }    

    /**
     * Kényelmi metódus a CoreEntity-re épülő entitásokból történő HttpParams objektum készítésre. A metódus egyenesen 
     * fordítja át az értékeket, tehát bármilyen egyéb transzformációt, ha kell akkor a visszaadott HttpParams-on érdemes végezni.
     * 
     * @param entity 
     * @param excludeMetaFields 
     * @returns 
     */
    static createHttpParamsFromEntity<T extends CoreEntity>(entity: T, excludeMetaFields: boolean = true): HttpParams {
        
        const _metaFields: string[] = [
            'enabled',
            'deleted',
            'created_at',
            'updated_at',
            '_errors'
        ];

        let params = new HttpParams();

        Object.keys(entity).forEach((key: string) => {
            if (excludeMetaFields) {
                if (!_metaFields.includes(key)) {
                    params = params.set(key, (entity[key] === null || entity[key] === undefined) ? '' : entity[key]);
                } 
            } else {
                params = params.set(key, (entity[key] === null || entity[key] === undefined) ? '' : entity[key]);    
            }                    
        });

        return params;
    }


    /**
     * Kényelmi funkció v4 formátumú UUID-ok készítéséhez.
     * TODO ha csak lehet ezt kliens oldalról ne használjuk, minden ilyet backend oldalon generáljuk
     * @returns 
     */
    static createUUIDv4(): string {
      function chr4() {
          return Math.random().toString(16).slice(-4);
      }

      return chr4() + chr4() +
          '-' + chr4() +
          '-' + chr4() +
          '-' + chr4() +
          '-' + chr4() + chr4() + chr4();    
    }

    /**
     * Kényelmi funkció speciális stringek készítéséhez (általában valamilyen attributumhoz, kt-s hülyeséghez)
     * 
     * @param values 
     * @param separator 
     * @returns 
     */
    static concatenateString(values: string[], separator?: string) {

        let str = '';

        if (separator) {
            values.forEach((val: string, index: number) => {
                str = (index > 0) ? (str + '_' + val).toString() : (str + val).toString();
            });
        } else {
            values.forEach((val: string, index: number) => { str = str + val; });     
        }

        return str;

    }

    
    /**
     * Visszaadjuk egy adott méret érték emberek által is olvasható formátumát (SI formátumban)
     * 
     * @param size int
     */
    static getFileSizeHumanReadable(size: number): string {

      const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(size) / Math.log(1024));

      return !size && '0 Bytes' || (size / Math.pow(1024, i)).toFixed(2) + ' ' + sufixes[i];
    }

    /**
     * Visszaadjuk a byte-ben megadott méret gigabyte értékét
     *  
     * @param size int
     */
    static getFileSizeInGigabytes(size: number): string {
        return (size * 9.3132257462E-10).toFixed(2);
    }    

    /**
     * Egyelőre beletesszük a + jelet tartalmazó stringek kezelését.
     * 
     * @param value 
     */
    static encodeString(value?: string): string | null {
      return (value) ? value.replace(/\+/gi, '%2B') : ''; 
    }    

    /**
     * Dekódóljuk a backend felöl visszaérkező stringeket, ha esetleg 
     * lenne benne olyan amit ay encodeString kódolt.
     * 
     * @param value 
     * @returns 
     */
    static decodeString(value?: string): string | null {
        return (value) ? value.replace(/\%2B/gi, '+') : '';
    }

    /**
     * Promise a várakoztatáshoz
     * TODO ha csak lehet ilyet ne használjunk, mert akkor már régen rossz
     * 
     * @param ms 
     * @returns 
     */
    private delay(ms: number): Promise<void> {
        return new Promise<void>(resolve =>
            setTimeout(resolve, ms));
    }

    public static padNumber(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

}
