import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Entities } from '@ratkaiga/core-nextgen';
import { SupportUserProfile } from './support-user-profile.entity';
import { SprintGroup } from './support-sprint-group.entity';

export class Sprint extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgSprint';

    name: string;

    owner: SupportUserProfile;

    target_score: number;

    completed: number = 0;

    in_progress: number = 0;

    unhandled: number = 0;

    closed_at: string;

    group: SprintGroup;

    getName(): string {
        return this.name;
    }

    getUser(): SupportUserProfile {
        return this.owner;
    }

    getCompleted(): number {
        return this.completed;
    }

    getInProgress(): number {
        return this.in_progress;
    }

    getUnhandled(): number {
        return this.unhandled;
    }

    getClosedAt(): string {
        return this.closed_at;
    }

    getGroup(): SprintGroup {
        return this.group;
    }

}