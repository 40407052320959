import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreDictionary } from '../models/dictionary';

@Directive({
    selector: '[spTableColumn]'
})
export class TableColumnDirective implements OnInit {

    @Input() reference: CoreDictionary[];
    @Output() init: EventEmitter<{ name: string, el: ElementRef }> = new EventEmitter();
    
    private _columnName;

    constructor(private el: ElementRef) { }

    ngOnInit(): void {

        this.init = new EventEmitter();

        this._columnName = this.el.nativeElement.getAttribute('data-field');
        this.el.nativeElement.classList.add('datatable-cell');

        // visible vizsgálata
        if (this.reference)
            this.el.nativeElement.style.display = this.onColumnVisible() ? '' : 'none';

        this.init.emit({ name: this._columnName, el: this.el });
        
    }

    /**
     * Az attributumban kapott mezőt vizsgáljuk, hogy regisztrálva van -e, illetve ha igen 
     * akkor milyen értékkel szerepel a láthatóságot tartalmazó tömbünkben.
     * 
     * @param field 
     * @returns 
     */
    private onColumnVisible(): boolean {

        const v = this.reference.find(c => c.getKey() === this.el.nativeElement.getAttribute('data-field'));
        if (v instanceof CoreDictionary) {
        return v.getValue() as boolean;
        }

        return true;
    }

}