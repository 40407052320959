import { Pipe, PipeTransform } from '@angular/core';
import { ScrollService } from '../service/scroll.service';

@Pipe({
  name: 'fileExtension'
})
export class FileExtensionPipe implements PipeTransform {

  transform(value?: string, ...args: unknown[]): unknown {
    return (value.indexOf('/') > -1) ? value.split('/')[1] : value;
  }

}
