import { Entities } from '@ratkaiga/core-nextgen';
import { Nyomda } from './nyomda.entity';
import { TransactionEmail } from './transaction-email.entity';

export class TransactionEmailQueue extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Message\\Entity\\EMailQueue';

    email: TransactionEmail;
    
    pending: boolean;

    trap: boolean;

    approved: boolean;

    getEmail(): TransactionEmail {
        return this.email;
    }

    getNyomdaId(): number {
        return this.email.getNyomdaId();
    }

    isPending(): boolean {
        return this.pending;
    }

    isTrap(): boolean {
        return this.trap;
    }

    isApproved(): boolean {
        return this.approved;
    }
   
}