import { Entities, MetaArray } from '@ratkaiga/core-nextgen';
import { SupportUserProfile } from './support-user-profile.entity';

export class WatchlistUser extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgWatchlist';

    id: number; // csak placeholder

    issue_id: number;

    userprofile: SupportUserProfile;

    getIssueId(): number {
        return this.issue_id;
    }

    getUserName(): string {
        return this.userprofile.getName();
    }

    getUserId(): number {
        return this.userprofile.getId();
    }

}