import { Entities } from '@ratkaiga/core-nextgen';
import { Nyomda } from './nyomda.entity';

export class TransactionEmailBlacklist extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Message\\Entity\\EmailBlacklist';

    public nyomda_id: number;

    public email: string;

    public status: string;

    public attempts: number;

    public errorcode: number;

    public errormsg: string;

    public reason: string;

    public severity: string;
  
    getEmail(): string {
        return this.email;
    }

    getNyomdaId(): number {
        return this.nyomda_id;
    }

}