import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {  
  CoreRequestFilterModel,
  CoreResponsePagination,
  CoreResponse,
  MetaArray,
  CoreRequestParams
} from '@ratkaiga/core-nextgen';

import { CoreAbstractComponent } from 'src/app/_core/core-abstract.component';
import { CoreTableSortDirectionEnum } from 'src/app/_core/models/table-sort';
import { CoreTableColumn } from 'src/app/_core/models/table-column';
import { CoreDictionary } from 'src/app/_core/models/dictionary';
import { Select2UpdateEvent, Select2Value } from 'ng-select2-component';

import { 
  FileserviceFolder,
  ScrollFile 
} from 'src/app/_core/entities/scroll';

import { ICoreExtendedComponentOptions } from 'src/app/_core/interfaces/core.interfaces';
import { ICoreFileserviceQuote } from 'src/app/_core/interfaces/fileservice.interfaces';
import { DriveService } from 'src/app/_core/service/scroll/drive.service';
import { Params } from '@angular/router';

class ComponentSearch {
  nyomda_id?: number;
  search_text?: string;
}

@Component({
  selector: 'spirit-drive-files',
  templateUrl: './drive-files.component.html',
  styleUrls: ['./drive-files.component.scss']
})
export class DriveFilesComponent extends CoreAbstractComponent implements OnInit {

  folders: FileserviceFolder[] = [];
  folderSelected: FileserviceFolder;
 
  driveStats: ICoreFileserviceQuote;

  driveCurrentNyomda: string;
  drivePaginatorTotalFiles: number;

  componentView: string = 'folders';
  componentRouteParams: Params;
 
  validScopes: string[] = ['scroll:access']
 
  constructor(
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    private driveService: DriveService   
    ) {

    super(injector, document);

    const options: ICoreExtendedComponentOptions = {
      dataTableOptions: {
        fetchUrl: `${environment.endpoints.fileservice}/file`
      }
    }

    this.componentOptions = options;
    this.preAuth();
  }

  ngOnInit(): void {
 
    /**
     * Minden származtatott komponensünket inicializálnunk kell, ha a komponens szintű szolgáltatásokat automatikusan igénybe akarjuk venni.
     * A preInitComponent kezelni nekünk az activeRoute-n keresztül beérkező ID paramétereket, beállítja a tábla rendezést, lapozást és 
     * az egyéb listázással kapcsolatos dolgainkat.
     */
    this.preInitComponent<ScrollFile>();

    /**
     * Regisztrálnunk kell a keresőhöz tartozó objektumunkat is
     */
    this.setComponentSearch(new ComponentSearch());

    this.componentSearchOptions = {
      paramsVersion: 2,
      virtualTextField: 'search_text',
      virtualFields: ['file_name','remarks']
    }

    // itt állíthatjuk be, hogy mely mezőket szeretnénk alapértelmezetten megjeleníteni (vagy eltüntetni)
    this.tableColumnVisibility.push(new CoreDictionary().setKey('id').setValue(false));
    this.tableColumnVisibility.push(new CoreDictionary().setKey('file_name').setValue(true));
    this.tableColumnVisibility.push(new CoreDictionary().setKey('created_at').setValue(true));

    /**
     * A rendezésünk alapértelmezéseit állítjuk be legelőször.
     */
    this.tableSort.setColumn('created_at').setDirection(CoreTableSortDirectionEnum.DESC);

    /**
     * Definiálnunk kell az összes olyan oszlopot, amit meg szeretnénk jeleníteni (dinamikus megjelenítést ez nem tartalmaz mint az SS2 esetében).
     * A sortable paraméter fogja jelezni, hogy az oszlopunk alapján rendezhetünk ASC|DESC formában.
     */
    this.tableColumns.push(new CoreTableColumn().setTable(this.table).setKey('id').setWidthPixel(35).setSortable(false));
    this.tableColumns.push(new CoreTableColumn().setTable(this.table).setKey('file_name').setWidthPixel(250).setSortable(true));
    this.tableColumns.push(new CoreTableColumn().setTable(this.table).setKey('created_at').setWidthPixel(100).setSortable(true));

    // a totál értéket a paginátorból vesszük, mert az lesz az akkurátus értékünk
    this.componentCollectionMetadata.pipe(takeUntil(this.destroy$)).subscribe((r: CoreResponsePagination) => {
      this.drivePaginatorTotalFiles = r.getTotalItems();
    });
    
    this.driveService.fileserviceQuoteSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: (result: ICoreFileserviceQuote) => {
        if (result)
          this.driveStats = result;
      }
    });

    this.driveService.fileserviceSelectedNyomda.pipe(takeUntil(this.destroy$)).subscribe({
      next: (result: string) => {
        if (result)
          this.driveCurrentNyomda = result;
      }
    });

    // lekérjük a drive statokat
    // this.driveService.fetchDriveStats<ComponentSearch>(this.getComponentSearch<ComponentSearch>());

    this.componentSearchForm = new UntypedFormGroup({
      search_text: new UntypedFormControl(),
      nyomda_id: new UntypedFormControl()
    });

  }

  protected subscribeActivatedRoute<T>(): void {

    // a route értelmezése után haladunk tovább az init során a lekérdezéseinkkel
    this.activatedRoute.params.subscribe(params => {

      this.componentRouteParams = params;
      this.refetchRouteSpecificData();

    });

  }

  protected refetchRouteSpecificData(): void {

      if (this.driveService.fileserviceCoreRequestParams.getValue()) {

        // ha van keresésünk, vagy bármilyen filterünk beállítva, akkor annak megfelelően kezdjük el a view-et felépíteni
        this.componentRequestParams = this.driveService.fileserviceCoreRequestParams.getValue();

        if (this.componentRequestParams) {
        
          // ha a paramétereken belül találunk nyomda id-t
          let params = this.componentRequestParams.filter.find(f => f.field === 'nyomda_id');
          if (params instanceof CoreRequestFilterModel)
            this.driveService.fileserviceSelectedNyomda.next(this.scrollService.nyomdaListaStatic.find(n => n.id === params.value).nev);

        }        

    } else {

      // alapértelmezésben a felhasználó saját nyomdáját írjuk ki
      this.driveService.fileserviceSelectedNyomda.next(this.authService.getCurrentUserProfile().getNyomdaNev());
    }

      // komponens specifikus feliratkozás, a drive könyvtár jelzéséhez
      this.scrollService.fsGetOwnerApps(this.componentRequestParams.getQuery()).subscribe((r: CoreResponse) => {

        if (r.getData()) {

          this.folders = r.getData<FileserviceFolder[]>() as unknown[] as FileserviceFolder[];            
          this.folderSelected = this.folders.find(f => f.getUUID() === this.componentRouteParams.hash ?? '');
          
          let forceFiles = false;
          const filters = [];

          // megpróbáljuk megkeresni a filterek között az esetleges nyomda filterezést
          const nyomda: CoreRequestFilterModel = this.componentRequestParams.filter.find(f => f.field === 'nyomda_id');
          
          // ha volt nyomdánk akkor hozzáadjuk a filterhez            
          if (nyomda) {
            filters.push(nyomda);
            this.componentSearchForm.controls.nyomda_id.setValue(nyomda.value);
          }
            

          // virtuális mezők hozzáadása a filterhez
          const vtext: CoreRequestFilterModel = this.componentRequestParams.filter.find(f => f.field === 'v!search_text');
          
          if (vtext)
            filters.push(vtext);

          const vref: CoreRequestFilterModel = this.componentRequestParams.filter.find(f => f.field === 'vref!search_text');
          
            if (vref)
              filters.push(vref);

          // ha volt virtuális keresésünk, akkor felülírjuk a kiválasztott folder-t undefinedre és beállítunk forced flaget, 
          // mert nekünk mindenképpen file lista fog kelleni
          if (vtext && vref) {
            this.componentSearchForm.controls.search_text.setValue(vtext.value);
            this.folderSelected = undefined;
            forceFiles = true;
          }

          if (this.folderSelected) {

            // mivel van folder kiválasztva, ezért mindenképpen filterezünk folderre is
            filters.push(new CoreRequestFilterModel('owner_app', this.folderSelected.getOwnerApp()));

            // összeállítjuk a filtereinket
            this.componentRequestParams.filter = filters;

            // névtérre filterezünk, ha van hash értékünk. ehhez mindenek előtt az kell nekünk az owner apps lista
            this.fetchComponentData(this.componentRequestParams.getQuery());
            this.componentView = 'files';

          } else if (this.componentRouteParams.hash === '_all' || forceFiles) {

            // összeállítjuk a filtereinket
            this.componentRequestParams.filter = filters;

            // az alap könyvtárat fogjuk megjeleníteni, mert nincsen hash. üres értékre nem tudunk filterezni, 
            // mert ugyan a null értékkel ki tudnánk venni ami nekünk kell, de a request filtereinket szétverné
            this.folderSelected = this.folders.find(f => f.filtered === false);
            this.fetchComponentData(this.componentRequestParams.getQuery());
            this.componentView = 'files';

          } else {

            // default view
            this.componentView = 'folders';
          }
        } 
      });

      // lekérjük a drive statokat, ugyanazok a filterek fognak bekerülni mint ami alapján keresünk
      // itt a backend a nyomda_id-n kívül nem vesz semmit figyelembe, azt is csak akkor ha a felhasználó dev:dev
      // tehát más nevében megnézhet adatokat
      this.scrollService.fsGetLimits(this.componentRequestParams.getQuery()).subscribe({ 
        next: (response: CoreResponse) => {
          this.driveService.fileserviceQuoteSubject.next(response.getDataByIndex<MetaArray<ICoreFileserviceQuote>>());
        },
        error: (error: unknown) => {
          console.log(error);
        }
      });

  }

  isScopeAvailable(scopes: string[]): boolean {
    return this.authService.isUserScopesAvailable(scopes);
  }

  setNyomdaSearch(event: Select2UpdateEvent): void {
    if (event.value) {
      this.getComponentSearch<ComponentSearch>().nyomda_id = (event.value as number);
    } else {
      this.getComponentSearch<ComponentSearch>().nyomda_id = undefined;
    }
  }

  public onSearch(): void {
    super.componentSearchSubmit<ComponentSearch>();
    this.driveService.fileserviceCoreRequestParams.next(this.componentRequestParams);
    this.refetchRouteSpecificData();
  }

  public onSearchReset(): void {
    super.componentSearchReset<ComponentSearch>(new ComponentSearch());
    this.driveService.fileserviceCoreRequestParams.next(this.componentRequestParams);
    this.refetchRouteSpecificData();
  }

}
