import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CoreResponse } from '@ratkaiga/core-nextgen';
import { Subject, catchError, map, take, takeUntil, throwError } from 'rxjs';
import { ScrollSupportService } from 'src/app/_core/service/scroll-support.service';

@Component({
  selector: 'spirit-upload-form-widget',
  templateUrl: './upload-form-widget.component.html',
  styleUrls: ['./upload-form-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFormWidgetComponent implements OnInit, OnDestroy {

  @Input() uuid: string;
  @Input() namespace: string;
  @Input() referenceId?: number;

  @Input() upload$ = new Subject<void>();
  @Input() uploadButton: boolean = false;

  @Input() reset$ = new Subject<void>();
  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  attachments: File[] = [];
  attachmentProgresses: number[] = [];
  attachmentMessages: string[] = [];

  attachmentUploaded: Subject<void> = new Subject();

  destroy$ = new Subject<void>();

  constructor(private supportService: ScrollSupportService, private cdk: ChangeDetectorRef) {

  }

  ngOnInit(): void {

    this.reset$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onInitObservers();
    });

    this.onInitObservers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onInitObservers(): void {

    // a take(1) garantálja, hogy csak egyszer futtatjuk meg az uploadot
    this.upload$.pipe(take(1)).subscribe(() => { 
      // ha vannak attachmentek akkor elindítjuk az uploadot, ha nincsenek akkor azonnal visszatérünk true értékkel
      if (this.attachments.length > 0) {
        this.upload(); 
      } else {
        this.complete.emit(true);
      }
      
    });

    this.attachmentUploaded.pipe(takeUntil(this.destroy$)).subscribe(() => {
      
      let d: boolean = true;
      this.attachmentProgresses.forEach((p: number, index: number) => {

        if (p < 100 && this.attachmentMessages[index] === 'In Progress') {
          // még feltöltjük az aktuális elemet, tehát false-re váltjuk a flag-et
          d = false;
        } else if (p >= 100 && this.attachmentMessages[index] === 'Finished') {
          // ha feltöltődött és a státusza is finished, akkor a flaget beállítjuk true-ra 
          // ha ezelőtt bármilyen nem true érték volt, akkor az aktuális érték marad
          d = (d) ? true : d;
        } else if (this.attachmentMessages[index] != 'In Progress' && this.attachmentMessages[index] != 'Finished' && this.attachmentMessages[index] != null) {
          d = (d) ? true : d;
        } else {
          d = false;
        }

      });

      if (d) {
        this.attachments = [];
        this.attachmentProgresses = [];
        this.attachmentMessages = [];

        this.complete.emit(true);
        // csak egyszer emittelünk
        this.destroy$.next();
      }

    });

  }

  onSelectFiles(event: any): void {

    for (let i = 0; i < (event.target.files as FileList).length; i++) {
      this.attachments.push(event.target.files[i]);
      this.attachmentProgresses.push(0);
    }

  }

  onDeleteFile(index: number): void {
    
    const at: File[] = [];
    for (let i = 0; i < this.attachments.length; i++) {
      if (index !== i)
        at.push(this.attachments[i]);
    }

    this.attachments = at;

  }

  onUpload(): void {
    this.upload();
  }

  protected upload(): void {

    this.attachments.forEach((file, index) => {
      // csak azokat az állományokat töltjük fel, amelyeknek a progress értéke zéró.. 
      // ez az esetleges dupla kattintások elkerülése miatt is egy szükséges védelem lehet
      if (this.attachmentProgresses[index] === 0) {
        this.supportService.uploadFile(file, this.uuid, this.namespace, this.referenceId).pipe(
          map((event: any) => {
            
            if (event.type == HttpEventType.UploadProgress) {
              // nézzük, hogy a feltöltésünk hány százalékon van
              this.attachmentProgresses[index] = Math.round((100 / event.total) * event.loaded);
              
              if (this.attachmentProgresses[index] >= 100) {
                this.attachmentMessages[index] = 'Finished';
              } else {
                this.attachmentMessages[index] = 'In Progress';
              }
              
            } else if (event.type == HttpEventType.DownloadProgress) {
              
              // visszakapjuk éppen a response-t
              this.attachmentProgresses[index] = 100;
              this.attachmentMessages[index] = 'Finished';

            } else if (event.type == HttpEventType.Response || event instanceof CoreResponse) {
              
              // VAGY visszakaptuk a response-t, ez aztán lehet bármi de feltételezzük, hogy a catchError megfogja ha hiba van
              // VAGY visszakaptuk a CoreResponse-t, amit a saját szabványos API ad vissza és feltételezzük, hogy a catchError megfogja ha 500-as hiba van
              this.attachmentProgresses[index] = 100;
              this.attachmentMessages[index] = 'Finished';

            }

            if (event.type == HttpEventType.Response || event instanceof CoreResponse)
              this.attachmentUploaded.next();

          }),
          catchError((err: any) => {
            this.attachmentProgresses[index] = 0;
            this.attachmentMessages[index] = err.message;

            this.attachmentUploaded.next();
            return throwError(err);
          })
        )
        .subscribe();
      }
    });

  }


}
