import { ICoreTableSortState } from "../interfaces/core-table.interfaces";

/**
 * Enumerációk, interfészek és osztály implemetációk a szabványos táblakezeléshez. 
 */
export enum CoreTableSortDirectionEnum {
    ASC = 'asc',
    DESC = 'desc'
}

export type CoreTableSortDirection = CoreTableSortDirectionEnum.ASC | CoreTableSortDirectionEnum.DESC;

/**
 * CoreSortState
 * 
 * Az aktuális tábla állapot rögzítéséhez és kezeléséhez használjuk.
 */
export class CoreTableSortState implements ICoreTableSortState {
    
    private _table: string;

    private _column: string = 'id';

    private _direction: CoreTableSortDirectionEnum.ASC | CoreTableSortDirectionEnum.DESC;

    constructor(table?: string, column?: string, direction?: CoreTableSortDirection) {
        this._table = table;
        this._column = column;
        this._direction = direction;
    }

    setTable(value: string): CoreTableSortState {
        this._table = value;
        return this;
    }

    getTable(): string {
        return this._table;
    }

    setColumn(value: string): CoreTableSortState {
        this._column = value;
        return this;
    }

    getColumn(): string {
        return this._column;
    }

    setDirection(value: CoreTableSortDirectionEnum.ASC | CoreTableSortDirectionEnum.DESC): CoreTableSortState {
        this._direction = value;
        return this;
    }

    getDirection(): string {
        return this._direction;
    }
}