import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'spirit-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() required: boolean = false;
  _
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;

  @Output() dateSelect: EventEmitter<NgbDate> = new EventEmitter();

  onDateSelect(value: NgbDate): void {
    this.dateSelect.emit(value);
  }

}
