import { ConfigProjectEnum, IConfigEnvironment } from "src/app/_core/configs/config.environment";

export const environment: IConfigEnvironment = {
  production: false,
  profiler: false,
  debug: true,
  app: {
    name: 'msp-fw',
    version: '0.1',
    theme: 'dark',
    project: ConfigProjectEnum.MYSCROLL,
    title: 'myScroll',
    params: {
      pagination: {
        max_buttons: 6
      },
      pusher: {
        // TODO production key-t használunk, mert csak így tudunk fejleszteni általában
        id: 1576257, // 1576256, 
        secret: '311afa9316be657e7d1a', // '34b525648cc3511dc82b',
        key: 'e7730e7293ef3eacf053' // '10ba4966fac7e9c42e03'
      }
    }
  },
  layout: {
    client: 0,
    version: 'generic',
    versions: [
      {
        name: 'generic',
        visibility: {
          aside: true,
          header: true,
          toolbar: false,
        }
      }
    ]
  },
  storage: {
    token: 'spirit-token',
    websocket: 'websocket'
  },   
  translate: {
    storage: 'spirit-locale',
    project: 'spirit-ng',
    versions: [
      {
        name: 'generic',
        default: 'hu',
        locales: [
          'hu',
          'en',
          'de'
        ],
        labels: [
          ['hu', 'Magyar'],
          ['en', 'English'],
          ['de', 'Deutsch']
        ]
      }
    ]
  },
  endpoints: {
    options: {
      profile: 'auth',
      websockets: false,
    },
    app: 'https://staging.api.admin.scrollmax.net', 
    auth: 'https://staging.api.auth.scrollmax.net', 
    locale: 'https://staging.api.translate.scrollmax.net', 
    message: 'https://staging.api.message.scrollmax.net', 
    fileservice: 'https://staging.api.fileservice.scrollmax.net',
    datapump: 'https://staging.api.adatpumpa.scrollmax.net',
    support: 'https://staging-ng.api.scrollsupport.hu',
    socket: 'https://staging.api.websocket.scrollmax.net:8080',
    release: 'https://staging.api.release.scrollmax.net',
  }
};
