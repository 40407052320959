import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'spirit-form-validation-message-widget',
  templateUrl: './form-validation-message-widget.component.html',
  styleUrls: ['./form-validation-message-widget.component.scss']
})
export class FormValidationMessageWidgetComponent implements OnInit {

  @Input() control: AbstractControl;

  constructor() { }

  ngOnInit(): void { }

}
