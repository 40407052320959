import { Component, Input } from '@angular/core';

@Component({
  selector: 'spirit-alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent {

  @Input() label: string = 'A funkció jogosultság hiányában nem érhető el!';
  @Input() hint: string = 'A lekérdezés naplózásra került.'

}
