import { Entities } from '@ratkaiga/core-nextgen';

export class IssueSLAType extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssueSLAType';

    key: string;

    description: string;

    hour_limit: number;

    getKey(): string {
        return this.key;
    }

    getDescription(): string {
        return this.description;
    }

    getHourLimit(): number {
        return this.hour_limit;
    }

}