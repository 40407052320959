import { Component, Input } from '@angular/core';
import { ICoreTableSortState } from 'src/app/_core/interfaces/core-table.interfaces';

@Component({
  selector: 'spirit-core-table-sortstate',
  template: `
  <div class="d-flex me-5">                         
    <span class="badge badge-scroll-brown me-2">{{ this.state.getColumn() | uppercase }}</span>
    <span class="badge badge-scroll-purple">{{ this.state.getDirection() | uppercase }}</span>
  </div>`,
})
export class CoreTableSortstateComponent {

  @Input() table: string;
  @Input() state?: ICoreTableSortState;

}
