import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: '[hasScope]'
  })
export class ScopeHasDirective {
    
    private currentScopes = [];
    private scopeNeed;
  
    constructor(
      private element: ElementRef,
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef
    ) {
    }
  
    @Input()
    set hasScope(val) {
        this.currentScopes = val[0];
        this.scopeNeed = val[1];
        this.updateView();
    }
  
    private updateView() {
      if (this.checkPermission()) {
          this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  
    private checkPermission() {
      let hasPermission = false;
  
      if (this.scopeNeed && this.currentScopes) {
        return this.currentScopes.includes(this.scopeNeed);
      }
  
      return hasPermission;
    }
  }