export interface IConfigGlobalLayout {
    title: string,
    assets: {
        logo: {
            url: string;
            title: string;
            themes: {
                light: string;
                dark: string;
            }
        },
        logobig: {
            title: string;
            themes: {
                light: string;
                dark: string;
            }
        }
    },
    aside: {
        toggleIcon: string;
        footerButton: {
            show: boolean;
            style: string;
        },
        userinfo: boolean;
    },
    top: {
        toolbar: {
            search: boolean;
            activities: boolean;
            notifications: boolean;
            chat: boolean;
            quicklinks: boolean;
            userinfo: boolean;
        },
        userinfo: 'simple' | 'detailed';
    },
    footer: {
        visible: boolean;
        copyright: string;
        url: string;
        poweredby: string;
        nav: { url: string, label: string }[];
    },
    auth: {
        nav: { url: string, label: string }[];
    },
    app: {
        toolbars: boolean;
        auth: {
            forgotpassword: boolean,
            registration: boolean
        }        
    }
}

export const globalLayoutConfig: IConfigGlobalLayout = {
    title: 'ScrollMax NextGen UI Framework',
    assets: {
        logo: {
            url: '#',
            title: 'ScrollMax NextGen UI Framework',
            themes: {
                light: './assets/media/scroll-sm.png',
                dark: './assets/media/scroll-white-sm.png',
            }
        },
        logobig: {
            title: 'ScrollMax NextGen UI Framework',
            themes: {
                light: './assets/media/scroll-sm.png',
                dark: './assets/media/scroll-white-sm.png',
            }
        }
    },
    aside: {
        toggleIcon: './assets/media/icons/duotune/arrows/arr079.svg',
        footerButton: {
            show: true,
            style: 'btn-info'
        },
        userinfo: true
    },
    top: {
        toolbar: {
            search: false,
            activities: false,
            notifications: false,
            chat: false,
            quicklinks: false,
            userinfo: false
        },
        userinfo: 'detailed'
    },
    footer: {
        visible: true,
        copyright: 'ScrollMax Zrt.',
        url: 'https://scrollmax.hu',
        poweredby: 'Powered by Scroll Angular Core Lib v2.0 & ScrollMax NextGen UI Framework',
        nav: []
    },
    auth: {
        nav: []
    },
    app: {
        toolbars: false,
        auth: {
            forgotpassword: true,
            registration: false
        }
    }
}