import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spirit-hint-panel',
  templateUrl: './hint-panel.component.html',
  styleUrls: ['./hint-panel.component.scss']
})
export class HintPanelComponent implements OnInit {

  @Input() content: string;

  @Input() textColor: string = 'muted';
  @Input() textSize: number = 7;

  @Input() iconColor: string = 'muted';
  @Input() iconSize: number = 2;
  @Input() iconClass: string = '';
  @Input() iconPaths: number = 2;
  @Input() icon: boolean = false;

  paths: number[] = [];

  ngOnInit(): void {
    
    for (let i = 1; i < this.iconPaths + 1; i++) { 
      this.paths[i] = i; 
    }

    this.paths = this.paths.splice(1, this.paths.length - 1);
    
  }

}
