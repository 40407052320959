import { Entities, MetaArray } from '@ratkaiga/core-nextgen';
import { ISupportWatchedIssues } from 'src/app/_core/interfaces/support.interfaces';
import { OAuth2User } from './../oauth-user.entity';
import { IssueCounter } from './support-counter.entity';

export class SupportUserProfile extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\UserProfile';

    id: number;

    oAuth2Users: OAuth2User;

    frontend_parameters: string | null;

    watchedIssues: ISupportWatchedIssues[] = [];

    counters: IssueCounter;

    getId(): number {
        return this.id;
    }

    getName(): string {
        return [this.oAuth2Users.getLastName(), this.oAuth2Users.getFirstName()].join(' ');
    }

    /**
     * Proxy metódus a getName metódushoz
     * @returns 
     */
    getUserName(): string {
        return this.getName();
    }

    getNameAbbr(): string {
        return [this.oAuth2Users.getLastName().substring(0, 1), this.oAuth2Users.getFirstName().substring(0, 1)].join('');
    }

    /**
     * Proxy metódus a getNameAbbr metódushoz
     * @returns 
     */
    getUserNameAbbr(): string {
        return this.getNameAbbr();
    }

    isDeveloper(): boolean {        
        return this.oAuth2Users.hasScope('dev:dev');
    }

    getWatchedIssues(): ISupportWatchedIssues[] {
        return this.watchedIssues;
    }

    isFrontendParameters(): boolean {
        return (this.frontend_parameters !== null);
    }

    getFrontendParametersRaw(): string | null {
        return this.frontend_parameters;
    }

    getCounters(): IssueCounter {
        return this.counters;
    }

    isAvatar(): boolean {
        return false;
    }

}