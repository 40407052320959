import { CommonModule } from '@angular/common';
import { Component, Injectable, Directive, TemplateRef, NgModule } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions, NgbModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * Options passed when opening a confirmation modal
 */
interface ConfirmOptions {
  title: string,
  message: string,
  ok?: string,
  cancel?: string,
  icon?: boolean
}

/**
 * An internal service allowing to access, from the confirm modal component, the options and the modal reference.
 * It also allows registering the TemplateRef containing the confirm modal component.
 *
 * It must be declared in the providers of the NgModule, but is not supposed to be used in application code
 */
@Injectable()
export class ConfirmState {
  /**
   * The last options passed ConfirmService.confirm()
   */
  options: ConfirmOptions;

  /**
   * The last opened confirmation modal
   */
  modal: NgbModalRef;

  /**
   * The template containing the confirmation modal component
   */
  template: TemplateRef<any>;
}

/**
 * A confirmation service, allowing to open a confirmation modal from anywhere and get back a promise.
 */
@Injectable()
export class ConfirmService {

  constructor(private modalService: NgbModal, private state: ConfirmState) {}

  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */
  confirm(options?: ConfirmOptions): Promise<any> {
    this.state.options = options;
    this.state.modal = this.modalService.open(this.state.template);
    
    return this.state.modal.result;
  }
}

/**
 * The component displayed in the confirmation modal opened by the ConfirmService.
 */
@Component({
  selector: 'confirm-modal-component',
  template: `
    <div class="modal-header border-0 px-10 pt-5 pb-0">
      <div class="d-flex justify-content-between align-content-top w-100">
          <div class="fs-3 fw-normal text-gray-600 me-2 mt-2 z-index-1">
            {{ options.title}}
          </div>
          <div (click)="no()" class="btn btn-sm btn-icon btn-active-color-primary mt-0 mb-5 me-n2 z-index-1" data-bs-dismiss="modal">
              <i class="ki-outline ki-cross-square fs-1">
              </i>                
          </div>
      </div>
    </div>

    <div class="card card-flush">
        <div class="card-body">

            <div class="mb-5 fs-3 text-danger">
                <p>{{ options.message }}</p>
            </div>

            <div class="mb-5 text-right">
                <div class="d-flex justify-content-end">
                    <a href="javascript:;" (click)="no()" class="btn btn-bg-light btn-active-color-primary me-5">
                        {{ options.cancel }}
                    </a>
                    <a href="javascript:;" (click)="yes()" class="btn btn-active-light-danger btn-icon-muted btn-active-color-danger" [class.btn-icon]="options.icon">
                        <i class="ki-duotone ki-trash fs-1" *ngIf="options.icon">
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                          <span class="path4"></span>
                          <span class="path5"></span>
                        </i>
                        <span *ngIf="!options.icon">{{ options.ok }}</span>
                    </a>
                </div>

            </div>
        </div>
    </div>`
})
export class ConfirmModalComponent {

  options: ConfirmOptions;

  constructor(private state: ConfirmState) {

    this.options = state.options;

    if (this.options === undefined) {
      this.options = ({} as ConfirmOptions);
    }

    this.options.ok = this.options.ok ?? 'Ok';
    this.options.cancel = this.options.cancel ?? 'Mégsem';
    this.options.icon = this.options.icon ?? true;
    this.options.title = this.options.title ?? 'Biztosan töröljük?';
    this.options.message = this.options.message ?? 'Figyelem! A művelet nem visszavonható, az elem véglegesen törlésre kerül.';

  }

  yes() {
    this.state.modal.close('confirmed');
  }

  no() {
    this.state.modal.dismiss('not confirmed');
  }
}

/**
 * Directive allowing to get a reference to the template containing the confirmation modal component,
 * and to store it into the internal confirm state service. Somewhere in the view, there must be
 *
 * ```
 * <template confirm>
 *   <confirm-modal-component></confirm-modal-component>
 * </template>
 * ```
 *
 * in order to register the confirm template to the internal confirm state
 */
@Directive({
  selector: "[confirm]"
})
export class ConfirmTemplateDirective {
  constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
    state.template = confirmTemplate;
  }
}
