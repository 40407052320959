import { Entities } from '@ratkaiga/core-nextgen';

export class ScrollProgramType extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Release\\Entity\\ScrollProgramType';

    nev: string;

    mutex: string;

    updater_mutex?: string;

    getName(): string {
        return this.nev;
    }

    getMutex(): string {
        return this.mutex;
    }

}