import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocalstorageService } from 'src/app/_core/service/localstorage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'spirit-hint-content',
  templateUrl: './hint-content.component.html',
  styleUrls: ['./hint-content.component.scss']
})
export class HintContentComponent implements OnInit, OnChanges {

  // technikai paraméter, a localstorageből vesszük de nem akarjuk publikálni a service-t
  theme: string;

  storage: string = 'spirit-hints';

  @Input() tag: string;
  @Input() content: string;
  @Input() title: string;

  visible: boolean = false;

  constructor(private storageService: LocalstorageService){}

  ngOnInit(): void {

    this.theme = this.storageService.getTheme();

    if (this.tag) {
      this.visible = this.storageService.hasHint(this.tag);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tag) {
      this.visible = this.storageService.hasHint(this.tag);
    }
  }

  onCloseCard(event: MouseEvent): void {
    this.storageService.changeHint(this.tag, false);
    this.visible = false;
  }

}
