import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

// RXJS importok
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Module importok
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Scroll CORE lib importjai
import { Entities } from '@ratkaiga/core-nextgen';

// Shared service importok
import { ScrollService } from 'src/app/_core/service/scroll.service';

// CK Imports
// import * as ClassicEditor from '../../../../../../assets/ckeditor-custom/default/ckeditor.js';
// import * as ClassicEditor from '../../../../../../assets/ckeditor-custom/ballon/ckeditor.js';
import * as ClassicEditor from '../../../../../../assets/ckeditor-custom/inline/ckeditor.js';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { ScrollUploadAdapter, ScrollUploadAdapterPlugin } from 'src/app/_core/adapters/upload.adapter-scroll';
import { EditorExplorerComponent } from '../editor-explorer/editor-explorer.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'spirit-ck-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

    /**
     * Mindenképpen kell nekünk egy editorForm referencia, mivel ezt fogjuk átadni az editor-explorer komponensünknek amikor popupot nyitunk
     */
    @Input() editorForm: FormGroup;

    /**
     * Átadunk egy element ID-t, hogy többször is használhassuk magát a komponenst egy űrlapon belül
     * @var string
     */
    @Input() editorName: string;

    /**
     * A szerkesztendő tartalmat ebben a változóban kapjuk meg, ezt fogjuk setelni az editorba. Arra kell figyelni, hogy ezt ha lehet 
     * ne változtassuk menet közben. 
     */
    @Input() editorInputContent: string;

    /**
     * A szerkesztett tartalmat egy emitter segítségével adjuk vissza. Minket csak a text érdekel, tehát mással nem foglalkozunk
     */
    @Output() editorOutputContent: EventEmitter<string> = new EventEmitter();

    /**
     * Destroy esetén használjuk
     */
    destroy$: Subject<void> = new Subject();

    /**
     * Az editor betöltődését figyeljük
     */
    editorCreated: Subject<boolean> = new Subject();

    /**
     * Az editor resetelését figyeljük
     */
    @Input() editorReset: Subject<string> = new Subject();

    /**
     * @var ClassicEditor
     */
    public editor = ClassicEditor;

    /**
     * @var object
     */
    public ckconfig = {};

    /**
     * Az onReady esemény után ebben tároljuk az editor instance-t
     * @var any
     */
    editorInstance: any;

    /**
     * Az onReady esetmény után ebben tároljuk az editor DOM elementjét (erre teszünk majd esemény figyelést, stb.)
     * @var any
     */
    editorElement: any;

    /**
     * @var editorContent
     */
    editorContent: string;

    constructor(
      @Inject(DOCUMENT) private document: Document,
      private modalService: NgbModal, 
      private scrollService: ScrollService, 
      private toastr: ToastrService, 
      private route: ActivatedRoute, 
      private el: ElementRef, 
      private renderer: Renderer2) {

        this.ckconfig = {
          // extra pluginként adjuk hozzá az upload adapterünket, ami natív angular script
          // minden mást a ck custom buildben kell befordítani és az assets könyvtárba bemásolni az új editort
          // @see https://bitbucket.org/frxtechnology/ckeditor-custom-build/src/develop/
          extraPlugins: [ScrollUploadAdapterPlugin]
        };

    }

    ngOnInit(): void {

      // felirtakozunk a CKEditor ready eseményére, amely után újra inicializáljuk a teljes űrlapot
      this.editorCreated.pipe(takeUntil(this.destroy$)).subscribe((r) => {
        if (r) {
          this.initEditor();
        }
      });

      this.editorReset.pipe(takeUntil(this.destroy$)).subscribe((r) => {
        if (r) {
          this.editorInstance.setData(r);
        }
      });

    }

    /**
     * Destructor
     */
    ngOnDestroy(): void {
      this.editorElement.removeEventListener('open-asset-explorer-popup', () => {});

      this.destroy$.next();
      this.destroy$.complete();
    }

    /**
     * Ezt a funkciót csak akkor hívjuk, amikor a CKEditor instance ready lesz. Ezzel egyben újra beállítjuk az űrlapunkat is
     */
    initEditor(): void {
      // seteljük az editor tartalmat
      this.editorInstance.setData(this.editorInputContent ?? '');
    }

    /**
     * Az onReady esemény után az aktuális editor instance-t kapjuk vissza, ezzel fogunk 
     * dolgozni a továbbiakban. Közvetlenül az publikus "editor" nem tartalmazza az instance adatait.
     */
    editorOnReady(event: any): void {

      // regisztráljuk az editor instance-t
      this.editorInstance = event;

      // regisztráljuk az UploadAdapter-t
      event.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new ScrollUploadAdapter(loader, this.editorForm);
      };

      // tslint:disable-next-line:no-angle-bracket-type-assertion
      // const editorButtonElement = (this.el.nativeElement as HTMLElement).querySelector('.asset-button');
      this.editorElement = document.getElementById('editor');

      // sima click event nem fog nekünk kelleni, azért van custom eventünk, mert az tartalmazza az 
      // editor pointer adatait is (jelenleg nem használjuk)
      this.editorInstance.sourceElement.addEventListener('open-asset-explorer-popup', (e) => {
        console.log(e.detail);
        if (e.detail.id === this.editorInstance.id) {

          const modalRef = this.modalService.open(EditorExplorerComponent, { size: 'xl', centered: true });
          modalRef.componentInstance.editorForm = this.editorForm;
          modalRef.closed.pipe(takeUntil(this.destroy$)).subscribe(r => {
            
            // ha kaptunk vissza file property-t a modal close után akkor meghívjuk az insert image metódusunkat
            if (r.hasOwnProperty('file'))
              this.insertImageFromExplorer(r.file, e.detail);
    
          });

        }
   
      }, false);

      this.editorCreated.next(true);
    }

    /**
     * Az editorban történő bármilyen change eseményre ezzel tudunk feliratkozni. Az eventben 
     * visszakapjuk az aktuális editor instance-t (tehát nem szükséges a globálisat használnunk)
     * @param ChangeEvent
     */
    onChange({ editor }: ChangeEvent): void {

      try {
        this.editorOutputContent.emit(editor.getData());     
      } catch (e) {
        // elnyomjuk, akkor következik be ha képet töltünk fel saját gépről.. ilyen esetben több alkalommal lefut a change esemény?
        // console.log('[Editor ChangeEvent Error]', e);
      }
        
    }

    /**
     * A kapott url string-el összeállítunk egy custom eventet, amelynek bekövetkeztét a CKEditor custom buildben fixen figyeljük (!), tehát az `editorElement`-en, 
     * ami egy natív HTMLElement ezt a custom eventet dispatcheljük (máshogy nem tudnánk elkapni a másik oldalon).
     * 
     * @param url 
     */
    insertImageFromExplorer(url: string, sourceDetail: any): void {

      // itt kezeljük a beszúrandó képet, ehhez kell nekünk a referencia
      const editorElement = (this.el.nativeElement as HTMLElement).querySelector('#' + this.editorName); // '#editor';
  
      const e = new CustomEvent(
        'ckInsertAssetExplorerContent', {
        detail: {
          source: sourceDetail,
          format: 'url',
          html: '',
          url: url.toString()
        }
      });

      this.editorInstance.sourceElement.dispatchEvent(e);

      // editorElement.dispatchEvent(e);

    }

}
