import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Issue, ScrollFile } from '../entities/scroll';
import { SupportStatusOption } from '../models/support/status-option';

@Injectable({
  providedIn: 'root'
})
export class SupportIssueService {

  issueMainStatusOptions: SupportStatusOption[] = [];
  
   attachmentsLoaded$ = new Subject<ScrollFile[]>();

  constructor() { 
    this.setStatusOptions();
  }

  /**
   * Kényelmi funkció az állomány adatainak visszaadásához ahol szükséges egy ScrollFile tömb, ami a teljesen 
   * feltöltött entitásokat tartalmazza (lehetőleg collection_group-al listázva a backenden). A file maga lehet sima 
   * referencia is, csak a guest hash fog nekünk kelleni a komparációhoz.
   * 
   * @param file 
   * @param files 
   * @returns 
   */
  getFileDetails(file: ScrollFile, files: ScrollFile[]): ScrollFile | undefined {
    
    let r;
    files.forEach((f: ScrollFile) => {
      if (f.getGuestHash() === file.getGuestHash())
        r = f;
    });

    return r;
  }

  isAvailableWithMainState(issue: Issue, availableStates: string[]): boolean {
    
    if (issue instanceof Issue) {
      return availableStates.includes(issue.main_status);
    }

    return false;
  }

  isAvailableWithMainType(issue: Issue, availableTypes: string[]): boolean {
    
    if (issue instanceof Issue) {
      return availableTypes.includes(issue.main_type);
    }

    return false;
  }

  getStatusOptions(main_type: string, current_status: string): string[] {
    return this.issueMainStatusOptions.find(o => o.getMainType() === main_type && o.getCurrentStatus() === current_status)?.getAvailableStatuses() ?? [];
  }

  setStatusOptions(): void {

    this.issueMainStatusOptions =       
    [
      new SupportStatusOption('support', 'new', [
        'new',
        'need_information',
        'in_progress',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('support', 'need_information', [
        'need_information',
        'in_progress',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('support', 'in_progress', [
        'in_progress',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('support', 'closed', [
        'closed',
        'archived'
      ]),
      new SupportStatusOption('support', 'archived', [
        'archived'
      ]),

      new SupportStatusOption('hiba', 'new', [
        'new',
        'need_information',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('hiba', 'need_information', [
        'need_information',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('hiba', 'open', [
        'need_information',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('hiba', 'in_progress', [
        'need_information',
        'separator',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('hiba', 'in_testing', [
        'need_information',
        'separator',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('hiba', 'closed', [
        'closed',
        'archived'
      ]),
      new SupportStatusOption('hiba', 'archived', [
        'archived'
      ]),

      new SupportStatusOption('fejlesztes', 'new', [
        'new',
        'need_information',
        'separator',
        'denied',
        'devlisted',
        'planning',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'need_information', [
        'need_information',
        'separator',
        'denied',
        'devlisted',
        'planning',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'denied', [
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'devlisted', [
        'need_information',
        'separator',
        'devlisted',
        'planning',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'planning', [
        'need_information',
        'separator',
        'denied',
        'devlisted',
        'planning',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'open', [
        'need_information',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'in_progress', [
        'need_information',
        'separator',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'in_testing', [
        'need_information',
        'separator',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'closed', [
        'closed',
        'archived'
      ]),
      new SupportStatusOption('fejlesztes', 'archived', [
        'archived'
      ]),

      // speciális státuszok lekezelése
      // ha elfogadták
      new SupportStatusOption('fejlesztes', 'label_offer_accepted', [
        'label_offer_accepted',
        'need_information',
        'separator',
        'open',
        'in_progress',
        'in_testing',
        'separator',
        'closed',
        'archived'
      ]),

      // ha visszautasították
      new SupportStatusOption('fejlesztes', 'label_offer_denied', [
        'label_offer_denied',
        'separator',
        'closed',
        'archived'
      ]),

      // ha számlázzuk
      new SupportStatusOption('fejlesztes', 'label_invoicing', [
        'label_invoicing',
        'separator',
        'closed',
        'archived'
      ]),

      // ha kiadtuk
      new SupportStatusOption('fejlesztes', 'label_released', [
        'label_released',
        'separator',
        'closed',
        'archived'
      ]),
      
    ];


  }

}
