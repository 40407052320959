import { Entities } from '@ratkaiga/core-nextgen';
import { Nyomda } from '../nyomda.entity';
import { SupportUserProfile } from './support-user-profile.entity';

export class IssueCounter extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgCounter';

    owner_count: number;
    
    developer_count: number;

    tester_count: number;

    watch_count: number;

    getMyOwnedIssuesCount(): number {
        return this.owner_count;
    }

    getMyDeveloperIssuesCount(): number {
        return this.developer_count;
    }

    getMyTestingIssuesCount(): number {
        return this.tester_count;
    }

    getWatchedIssueCount(): number {
        return this.watch_count;
    }

}


