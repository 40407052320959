import { Component, Inject, Input, LOCALE_ID, OnInit, SimpleChanges } from '@angular/core';
import { Issue, SupportPreload } from 'src/app/_core/entities/scroll';
import { ScrollSupportService } from 'src/app/_core/service/scroll-support.service';

@Component({
  selector: 'spirit-issue-details-widget',
  templateUrl: './issue-details-widget.component.html',
  styleUrls: ['./issue-details-widget.component.scss']
})
export class IssueDetailsWidgetComponent implements OnInit {

  @Input() issue: Issue;

  preloading: boolean;
  preloadingMessage: string = 'Az adatok betöltés alatt';

  language: string;

  constructor(@Inject(LOCALE_ID) locale: string, private supportService: ScrollSupportService) { 
    this.language = locale;
  }

  ngOnInit(): void {
    
    this.preloading = true;
    if (!this.issue) {
      this.issue = new Issue();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.preloading = false;
    }
  }

}
