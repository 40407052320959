import { Pipe, PipeTransform } from '@angular/core';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';

@Pipe({
  name: 'supportStatusLabel'
})
export class SupportStatusLabelPipe implements PipeTransform {

  constructor(private translateService: CoreTranslateService) {}

  transform(value: string): string {
    return this.translateService.translate('label_' + value);
  }

}
