import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StaticService } from './static.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  public disallowedForAuthAndIntercepts = [
    'auth/login',
    'auth/forgot-password',
    'auth/registration',
    'drive/download'
  ];

  constructor() { }

  canAuthAndIntercept(path?: string): boolean {
    
    let intercept = true;
    let current = (path) ? path : window.location.pathname;
    
    if (environment.debug) {
      console.log('[DEBUG] canAuthAndIntercept path:', current);
    }
    
    this.disallowedForAuthAndIntercepts.forEach(route => {
        intercept = (current.indexOf(route) > -1) ? false : intercept;
    });

    return intercept;
  }

  /**
   * Vigigfutunk a controls objektumon, ide mindenképpen FormControlokat tartalmazó objektumot várunk. 
   * Teszteljük minden control valid tulajdonságát és ha valahol failedre futunk akkor onnantól végig 
   * false lesz a kimenetünk.
   * 
   * @param controls 
   * @returns 
   */
  formValidate(controls: unknown): boolean {
    
    let valid = true;
    Object.keys(controls).forEach((key: string) => {
      valid = ((controls[key] as UntypedFormControl).valid) ? valid : false;
    });

    return valid;
  }

  /**
   * Végigfutunk a controls objektumon, ide mindenképpen FormControlokat tartalmazó objektumot várunk. Jelenleg nem 
   * nagyon tudjuk szebben megoldani a dolgot. A működést tekintve a controls tehát egy formGroup.controls objektum 
   * lesz, amit kulcsonként ellenőrzünk és az adott kontrol aktuális értékét átküldjük a sanitizeren.
   * 
   * @param controls 
   */
  formSanitize(controls: unknown): void {
    Object.keys(controls).forEach((key: string) => {
      (controls[key] as UntypedFormControl).setValue(this.sanitizeValue((controls[key] as UntypedFormControl).value));
    });
  }

  /**
   * Bejövő skaláris értéket fogunk sanitizálni, majd visszaadjuk. 
   * 
   * @param value 
   * @returns 
   */
  sanitizeValue(value: unknown): unknown {
    
    // csak stringet sanitizálunk
    if (typeof(value) === 'string') {
      return StaticService.decodeString(value);
    }

    return value;
  }

}
