import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ScrollService } from '../service/scroll.service';
import { Observable, Subscribable, filter, find, first, firstValueFrom, map, tap } from 'rxjs';
import { Nyomda } from '../entities/scroll';

@Pipe({
  name: 'getNyomdaNev',
  pure: false
})
export class NyomdaNevPipe implements PipeTransform {

  private _nyomda: Nyomda;

  constructor(private scrollService: ScrollService, private cdr: ChangeDetectorRef) {}

  transform(value?: number, ...args: unknown[]): unknown {
    
    if (this.scrollService.nyomdaListaStatic.length > 0) {
      
      // mivel inpure a pipe az obszerveres verzió miatt, ezért leválasszuk a detection-t
      // this.cdr.detach();

      // TODO ideiglenesen debugoljuk, hogy honnan olvassuk a nyomda adatokat
      // console.info('[DEBUG] Nyomda pipe running from static data');

      // normális esetben a statikus listából próbálunk meg valamit előszedni
      return (value) ? this.scrollService.nyomdaListaStatic.find(n => n.id === value)?.nev : 'Nincs nyomda társítva';

    } else {

      const obs = this.scrollService.nyomdaLista.asObservable();

      obs.subscribe((observableResult: Nyomda[]) => {
        console.log('subscribe running');
        this._nyomda = observableResult.find(nyomda => nyomda.id === value);
      }).unsubscribe();

      this.cdr.markForCheck();

      return this._nyomda?.nev;

    }
    
  }

}
