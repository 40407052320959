import { Entities, MetaArray } from '@ratkaiga/core-nextgen';

export class CmsContentTitle extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Admin\\Entity\\CmsContentTitle';

    id: number;

    slug?: string;

    scope?: string;

    title: string;

    status: string;

    content_type: string;

    getId(): number {
        return this.id;
    }

    getSlug(): string {
        return this.slug;
    }

    getScope(): string {
        return this.scope;
    }

    getTitle(): string {
        return this.title;
    }

    getStatus(): string {
        return this.status;
    }

    getContentType(): string {
        return this.content_type;
    }

    isActive(): boolean {
        return (this.status === 'active');
    }

}