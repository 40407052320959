import { IssueGroupItemModel } from "./support.issuegroup-item.model";

export class IssueGroupModel {

    name: string;

    tab: string = 'group-list';

    items: IssueGroupItemModel[] = [];

    setName(name: string): IssueGroupModel {
        this.name = name;
        return this;
    } 

    getName(): string {
        return this.name;
    }

    setTab(tab: string): IssueGroupModel {
        this.tab = tab;
        return this;
    }

    getTab(): string {
        return this.tab;
    }

    setItems(items: IssueGroupItemModel[]): IssueGroupModel {
        this.items = items;
        return this;
    }

    getItems(): IssueGroupItemModel[] {
        return this.items;
    }

    countItems(): number {
        return this.items.length;
    }

}