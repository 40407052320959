import { Injectable } from '@angular/core';

export interface ISpiritHints {
  key: string;
  state: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  static hintStorage = 'spirit-hints';

  constructor() { 
    if (!localStorage.getItem(LocalstorageService.hintStorage)) {
      localStorage.setItem(LocalstorageService.hintStorage, JSON.stringify([]));
    }
  }

  getHints(): ISpiritHints[] {
    return JSON.parse(localStorage.getItem(LocalstorageService.hintStorage)) as unknown[] as ISpiritHints[];
  }

  /**
   * Egy a key argumentumban átadott hint értékének beállítása és mentése a hintek adatok közé
   * 
   * @param key 
   * @param state 
   */
  addHint(key: string, state: boolean): void {
    
    const hints = this.getHints();
    hints.push({ key: key, state: state});
    
    localStorage.setItem(LocalstorageService.hintStorage, JSON.stringify(hints));    
  }

  /**
   * Egy bizonyos hint láthatósóágának állítása
   * 
   * @param key 
   * @param state 
   */
  changeHint(key: string, state: boolean): void {
    
    let hints = this.getHints();
    
    // egy a kulcs argumentumban kapott hint értékét állítjuk át a state argumentumban kapott értékre 
    hints.find(h => h.key === key).state = state;

    // mentünk mindent
    localStorage.setItem(LocalstorageService.hintStorage, JSON.stringify(hints));
  }

  /**
   * A kulcsnak megfelelő hintet vizsgáljuk, hogy meg kell-e jeleníteni a felhasználónak.
   * 
   * @param key 
   * @returns 
   */
  hasHint(key: string): boolean {
    
    if (localStorage.getItem(LocalstorageService.hintStorage)) {
      // megpróbáljuk előszedni a hint keyhez rendelt objektumot
      let hint = this.getHints().find(h => h.key === key);

      // ha van ilyen objektumunk, akkor visszatérünk a state tulajdonsággal
      if (hint) {
        return hint.state;
      } else {

        // ha nincs ilyen objektumunk, akkor létrehozunk egy alapértelmezett objektumot, true értékkel. 
        // visszaírjuk a localStorageba mert később is szükségünk lesz rá
        this.addHint(key, true);

        return true;
      }
    }

    return false;
  }

  getTheme(): string {
    return (localStorage.getItem('kt_theme_mode_value') === 'light') ? 'light' : 'dark';
  }

}
