import { Entities } from '@ratkaiga/core-nextgen';
import { Issue } from './support-issue.entity';
import { SupportUserProfile } from './support-user-profile.entity';
import { ScrollFile } from '../fileservice-file.entity';
import { IssueOffer } from './support-issue-offer.entity';

export class Issueupdate extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssueupdate';

    issue: Issue;

    owner: SupportUserProfile;

    content: string;

    schema: string;

    files: ScrollFile[] = [];

    offer?: IssueOffer | undefined;

    parameters: any;

    getIssue(): Issue {
        return this.issue;
    }

    getOwner(): SupportUserProfile {
        return this.owner;
    }

    getOwnerName(): string {
        return this.owner.getName();
    }

    getOwnerNameAbbr(): string {
        return this.owner.getNameAbbr();
    }

    getContent(): string {
        return this.content;
    }

    getSchema(): string {
        return this.schema;
    }

    getFiles(): ScrollFile[] {
        return this.files;
    }

    getParameters(): any {
        return JSON.parse(this.parameters);
    }

    isIssueOffer(): boolean {
        return (this.offer instanceof IssueOffer);
    }

    getIssueOffer(): IssueOffer {
        return this.offer;
    }

    /**
     * Kényelmi funkció ami megmondja nekünk, hogy az adott user az eredeti hibajegy ownere vagy sem
     * @returns 
     */
    isIssueOwner(): boolean {
        return (this.issue.getOwnerId() === this.owner.getId());
    }

    /**
     * Shortcut funkció a profil felé, amiből megtudjuk hogy az userünk developer -e
     * @returns 
     */
    isDeveloper(): boolean {
        return this.owner.isDeveloper();
    }

}