import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollFile } from 'src/app/_core/entities/scroll';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'spirit-issue-attachment-list-widget',
  templateUrl: './issue-attachment-list-widget.component.html',
  styleUrls: ['./issue-attachment-list-widget.component.scss']
})
export class IssueAttachmentListWidgetComponent {

  @Input() breadcrumbList: { label: string, url: string, active: boolean; }[] = [];
  @Input() fileList: ScrollFile[] = [];

  onDownload(hash: string): void {
    window.open((environment.endpoints.fileservice + '/guest/download/' + hash).toString(), "_blank");
  }

}
