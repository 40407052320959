import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { CoreResponse } from '@ratkaiga/core-nextgen';
import { Subject } from 'rxjs';
import { Issueupdate } from 'src/app/_core/entities/scroll';
import { ScrollSupportService } from 'src/app/_core/service/scroll-support.service';
import { SupportIssueService } from 'src/app/_core/service/support-issue.service';

@Component({
  selector: 'spirit-issueupdate-offer-interactive',
  templateUrl: './issueupdate-offer-interactive.component.html',
  styleUrls: ['./issueupdate-offer-interactive.component.scss']
})
export class IssueupdateOfferInteractiveComponent implements OnInit, OnDestroy {

  @Input() issueupdate: Issueupdate;
  @Input() order: number;

  // kimenő eventünk a változások jelzéséhez
  @Output() componentChange: EventEmitter<string> = new EventEmitter();

  destroy$ = new Subject<void>();

  language: string;

  constructor(@Inject(LOCALE_ID) locale: string, private issueService: SupportIssueService, private supportService: ScrollSupportService) { 
    this.language = locale;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAccept(): void {
    this.supportService.acceptIssueOffer(this.issueupdate.getIssue().getId()).subscribe({
      next: (response: CoreResponse) => {
        this.componentChange.emit('changes');
      }
    })
  }

  onDecline(): void {
    this.supportService.declineIssueOffer(this.issueupdate.getIssue().getId()).subscribe({
      next: (response: CoreResponse) => {
        this.componentChange.emit('changes');
      }
    })
  }

}
