import { Component, Input } from '@angular/core';

@Component({
  selector: 'spirit-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss']
})
export class AlertCardComponent {

  @Input() color: string = 'danger';
  @Input() title: string;
  @Input() content: string;

}
