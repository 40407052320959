import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreResponsePagination } from '@ratkaiga/core-nextgen';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICoreTablePaginatorOptions } from 'src/app/_core/interfaces/core-table.interfaces';
import { CoreTablePaginatorOptions } from 'src/app/_core/models/table-pagination';

@Component({
  selector: 'spirit-core-table-paginator',
  templateUrl: './core-table-paginator.component.html',
  styleUrls: ['./core-table-paginator.component.scss']
})
export class CoreTablePaginatorComponent implements OnInit, OnDestroy {

  @Input() table: string;
  @Input() options?: ICoreTablePaginatorOptions;
  @Input() coreResponsePagination: Subject<CoreResponsePagination>;

  @Output() paginationEvent: EventEmitter<{ startIndex: number, itemsPerPage: number }> = new EventEmitter();

  destroy$: Subject<void> = new Subject();

  defaults: CoreTablePaginatorOptions = new CoreTablePaginatorOptions();

  paginatorOptions: CoreTablePaginatorOptions;
  paginatorMetadata: CoreResponsePagination = new CoreResponsePagination();

  loading: Subject<boolean> = new Subject();

  constructor(private rendrer: Renderer2, private cdr: ChangeDetectorRef, private translateService: CoreTranslateService) { }

  ngOnInit(): void {

    this.loading.next(true);

    if (this.table === undefined) {
      throw Error('[DEBUG] Tábla név megadása kötelező a helyes működéshez');
    }

    this.paginatorOptions = this.defaults;
    this.coreResponsePagination.subscribe((r) => {
      this.paginatorMetadata = r;

      if (sessionStorage.getItem('pp-' + this.table) !== null) {
        const p = JSON.parse(sessionStorage.getItem('pp-' + this.table));
        this.paginatorOptions.pagerCurrentPage = p.startIndex;
        this.paginatorOptions.pagerItemsPerPage = p.itemsPerPage;
      }

      this.initPaginator();
    });

    this.paginationEvent.pipe(takeUntil(this.destroy$)).subscribe((r) => {
      sessionStorage.setItem('pp-' + this.table, JSON.stringify(r));
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initPaginator(): void {

    this.paginatorOptions.maxPage = Math.ceil(this.paginatorMetadata.totalItems / this.paginatorOptions.pagerItemsPerPage);
    this.paginatorOptions.pagerCurrentPage = this.paginatorMetadata.startIndex / this.paginatorOptions.pagerItemsPerPage;
    
    const treshold = environment.app.params.pagination.max_buttons / 2;
    const max = environment.app.params.pagination.max_buttons;

    this.paginatorOptions.pagerButtons = [];
    if (this.paginatorOptions.maxPage > max) 
    {      
      // több oldalunk van, mint ami maximálisan megjeleníthető
      const start = (this.paginatorOptions.pagerCurrentPage < treshold) ? 0 : this.paginatorOptions.pagerCurrentPage - treshold; 
      let end; 

      if (this.paginatorOptions.maxPage <= max) {
        end = this.paginatorOptions.maxPage;
      } else {
        end = ((start + max) > this.paginatorOptions.maxPage) ? this.paginatorOptions.maxPage : (start + max);
      }      
            
      for (let i = start; i < end; i++) {
        this.paginatorOptions.pagerButtons.push({ index: i, label: (i + 1) });
      }
    } 
    else 
    {    
      for (let i = 0; i < this.paginatorOptions.maxPage; i++) {
        this.paginatorOptions.pagerButtons.push({ index: i, label: (i + 1) });
      }
    }

    this.loading.next(false);
    this.cdr.detectChanges();
  }

  /**
   * Step esetén léptetjük valamerre a kurzort, egzakt oldal esetén fixen oda léptetjük a kurzort
   * 
   * @param event 
   * @param page 
   * @param step 
   */
  onClickPagination(event: MouseEvent, page: number, step?: number): void {

    const el: ElementRef = new ElementRef(event.currentTarget);

    // ha disable state-ban vagyunk akkor preventelünk mindent. ez egy általános workaround, mivel 
    // a class alapú disabled state nem preventálja a klikkelést, de nem akarunk engedni semmit
    if (!el.nativeElement.classList.contains('datatable-pager-link-disabled')) {

      // egzakt oldalra fogunk léptetni
      if (page > -1) {
        this.paginatorOptions.pagerCurrentPage = page;
      } else {
        if ((this.paginatorOptions.pagerCurrentPage + step) >= 0 && (this.paginatorOptions.pagerCurrentPage + step) <= (this.paginatorOptions.maxPage - 1)) {
          this.paginatorOptions.pagerCurrentPage = this.paginatorOptions.pagerCurrentPage + step;
        }
      }

      this.paginationEvent.emit({ startIndex: this.paginatorOptions.pagerCurrentPage, itemsPerPage: this.paginatorOptions.pagerItemsPerPage });
    }
  }

  onClickPageSize(event: MouseEvent, size: number): void {
    this.paginatorOptions.pagerItemsPerPage = size;
    this.paginationEvent.emit({ startIndex: 0, itemsPerPage: this.paginatorOptions.pagerItemsPerPage })
  }

}
