import { HttpClient, HttpParams, HttpEvent, HttpEventType, HttpResponse, HttpProgressEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EntityService, CoreResponse, MetaArray } from '@ratkaiga/core-nextgen';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { distinctUntilChanged, map, retry, scan } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Nyomda } from '../entities/scroll';
import { CoreFileDownload, ICoreFileserviceQuote } from '../interfaces/fileservice.interfaces';
import { Saver, SAVER } from '../providers/saver.provider';
import { CoreEntity } from '@ratkaiga/core-nextgen/lib/entity';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  nyomdaLista: ReplaySubject<Nyomda[]> = new ReplaySubject(1);
  
  nyomdaListaStatic: Nyomda[] = [];

  constructor(private http: HttpClient, private entityService: EntityService, @Inject(SAVER) private save: Saver) { 
    this.nyomdaLista.subscribe(r => {
      this.nyomdaListaStatic = r;
    })
  }

  private scrollEngagesSubject: BehaviorSubject<{ name: string, state: boolean }[]> = new BehaviorSubject([]);
  
  public scrollEngagesObservable = this.scrollEngagesSubject.asObservable();


  addOrChangeEngageComponentState(name: string, state: boolean): void {

    // lekérdezzük az összes regisztrált engage-t
    const engages = this.scrollEngagesSubject.getValue();

    // ha találunk ilyet akkor visszatérünk
    if (engages.findIndex(e => e.name === name) > -1) {
      // csak a state paramétert változtatjuk
      engages[engages.findIndex(e => e.name === name)].state = false;
    } else {
      // ha nem találtunk ilyet, akkor pusholjuk az értéket
      engages.push({ name, state });
    }
      
    // feladjuk a behaviorsubject számára
    this.scrollEngagesSubject.next(engages);

  }

  /**
   * Entitás mentése vagy megváltoztatása a baseUrl-ben átadott végponton keresztül.
   * 
   * @example
   * createOrChangeEntity<User>(user, params, 'https://staging.api.test.scrollmax.net/user').subscribe(console.log); 
   * 
   * @param entity 
   * @param params 
   * @param baseUrl 
   * @returns 
   */
  createOrChangeEntity<T extends CoreEntity>(entity: T, params: HttpParams, baseUrl: string): Observable<CoreResponse> {
    if (entity.getId()) {
      return this.http.put(`${baseUrl}/${entity.getId()}`, params).pipe(map((r) => this.entityService.parse(r).getResponse()));
    } else {
      return this.http.post(`${baseUrl}`, params).pipe(map((r) => this.entityService.parse(r).getResponse()));
    }
  }

  /**
   * Entitás törlése a baseUrl-ben átadott végponton keresztül.
   * 
   * @example
   * deleteEntity<User>(user, 'https://staging.api.test.scrollmax.net/user').subscribe(console.log); 
   * 
   * @param entity 
   * @param baseUrl 
   * @returns 
   */
  deleteEntity<T extends CoreEntity>(entity: T, baseUrl: string): Observable<CoreResponse> {
    return this.http.delete(`${baseUrl}/${entity.getId()}`).pipe(map((r) => this.entityService.parse(r).getResponse()));
  }

  /**
   * Teljes nyomdalistát kérünk le, ami nem tartalmaz szenzitív információkat csak id-név-rövidnév adatokat. 
   * Több helyen használjuk az eredményt a különböző űrlapok filterezésétől kezdve az egyéb 
   * nyomdaválasztásokig (iterátorokhoz, spec. scriptekhez, stb.)
   * 
   * @returns 
   */
  globalFetchNyomdaList(): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/nyomda`).pipe(retry(1),
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott fileservice slug értéket fordít ás guest hashra, ha lehet. Ha nem lehet, akkor hibával térünk 
   * vissza. Az eredmény szabványos scroll rest response.
   * 
   * @param slug 
   * @returns 
   */
  fsExchangeSlugToHash(slug: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.fileservice}/slug/exchange/${slug}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  fsGetOwnerApps(filter?: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.fileservice}/folder/owners/${filter ?? ''}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  fsGetLimits(filter?: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.fileservice}/folder/quote/${filter ?? ''}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * CK Editor buildek esetében használjuk a saját fejlesztésű explorer pluginban 
   * @param filter 
   * @returns 
   */
  fsGetFileEntities(filter?: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.fileservice}/${filter ?? ''}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  fsGetFiles(filter?: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.fileservice}/file${filter ?? ''}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // >> DOKUMENTUM KEZELÉS: BLOG, WIKI, HÍREK, EGYEBEK
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /**
   * 
   * @param filter 
   * @returns 
   */
  cmsGetTocTitles(filter?: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.app}/cms_content_toc${filter ?? ''}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  cmsCreateTocTitle(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.app}/cms_content_toc`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  cmsChangeTocTitle(params: HttpParams): Observable<CoreResponse> {
    return this.http.put(`${environment.endpoints.app}/cms_content_toc`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
  }
  
  isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress 
        || event.type === HttpEventType.UploadProgress
  }

  /**
   * Egy adott fileservice guest hash alapján indítunk egy letöltést, ami kezeli a progress-t is.
   * 
   * @param hash 
   * @param filename 
   * @returns 
   */
  fsDownloadByGuestHash(hash: string, filename?: string): Observable<CoreFileDownload> {
    return this.http.get(`${environment.endpoints.fileservice}/guest/download/${hash}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(this.downloadFile(blob => this.save(blob, filename)))
  }


  /**
   * Egy adott guest hash alapján töltünk le egy file-t vagy blob vagy arraybuffer típussal.
   * 
   * @param hash 
   * @param type 
   * @returns 
   */
  fsDownloadRawdataByGuestHash(hash: string, type: string = 'blob'): Observable<Object | Blob | ArrayBuffer> {
    return this.http.get(`${environment.endpoints.fileservice}/guest/download/${hash}`, { 
      responseType: type as 'json'
    });
  }

  /**
   * Save JS-re épített http progress-t is használó letöltés.
   * 
   * @param saver 
   * @returns 
   */
  downloadFile(
    saver?: (b: Blob) => void
  ): (source: Observable<HttpEvent<Blob>>) => Observable<CoreFileDownload> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan(
          (download: CoreFileDownload, event): CoreFileDownload => {
            if (this.isHttpProgressEvent(event)) {
              return {
                progress: event.total
                  ? Math.round((100 * event.loaded) / event.total)
                  : download.progress,
                state: "IN_PROGRESS",
                content: null
              };
            }
            if (this.isHttpResponse(event)) {
              if (saver) {
                saver(event.body);
              }
              return {
                progress: 100,
                state: "DONE",
                content: event.body
              };
            }
            return download;
          },
          { state: "PENDING", progress: 0, content: null }
        ),
        distinctUntilChanged((a, b) => a.state === b.state
          && a.progress === b.progress
          && a.content === b.content
        )
      );
  }

  /**
   * Format bytes as human-readable text.
   * 
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use 
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   * 
   * @return Formatted string.
   */
  static convertBytesToHumanReadableFormat(bytes: number, si: boolean = false, dp: number = 1) {
  
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
      }

      const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      
      let u = -1;        
      const r = 10**dp;

      do {
      bytes /= thresh;
      ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
      return bytes.toFixed(dp) + ' ' + units[u];
  }

}
