import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'filehash'
})
export class FileHashPipe implements PipeTransform {

  transform(value: string): string {
    return [environment.endpoints.fileservice, 'guest', 'download', value].join('/');
  }

}
