import { AfterContentInit, Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Scroll } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Issueupdate, ScrollFile } from 'src/app/_core/entities/scroll';
import { SupportIssueService } from 'src/app/_core/service/support-issue.service';

@Component({
  selector: 'spirit-issueupdate-default',
  templateUrl: './issueupdate-default.component.html',
  styleUrls: ['./issueupdate-default.component.scss']
})
export class IssueupdateDefaultComponent implements OnInit, OnChanges, OnDestroy {

  @Input() issueupdate: Issueupdate;
  @Input() order: number;

  @Output() replyTo: EventEmitter<Issueupdate> = new EventEmitter();

  preloading: boolean;
  preloadingMessage: string = 'Az adatok betöltés alatt';

  destroy$ = new Subject<void>();

  language: string;

  constructor(@Inject(LOCALE_ID) locale: string, private issueService: SupportIssueService) { 
    this.language = locale;
  }

  ngOnInit(): void {

    this.preloading = true;
    if (!this.issueupdate) {
      this.issueupdate = new Issueupdate();
    }

    this.issueService.attachmentsLoaded$.pipe(takeUntil(this.destroy$)).subscribe((files: ScrollFile[]) => {
      if (this.issueupdate.files) {
        this.issueupdate.files.forEach((f: ScrollFile, index: number) => {
          this.issueupdate.files[index] = this.issueService.getFileDetails(f, files);
        });
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.preloading = false;
    }
  }

  onReplyTo(): void {
    this.replyTo.emit(this.issueupdate);
  }

}
