import { Entities } from '@ratkaiga/core-nextgen';

export class Nyomda extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Db\\Entity\\Nyomda';

    id: number;

    nev: string;

    getName(): string {
        return this.nev;
    }

}