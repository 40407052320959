import { Entities, MetaArray } from '@ratkaiga/core-nextgen';

export class CmsContentToc extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Admin\\Entity\\CmsContentToc';

    id: number;

    parent_id: number;

    title: string;

    image: string;

    subs: CmsContentToc[] = [];

    status: string;

    getId(): number {
        return this.id;
    }

    setParentId(parent_id: number): CmsContentToc {
        this.parent_id = parent_id;
        return this;
    }

    getParentId(): number {
        return this.parent_id;
    }

    getTitle(): string {
        return this.title;
    }

    getImage(): string {
        return this.image;
    }

    setStatus(status: string): CmsContentToc {
        this.status = status;
        return this;
    }

    getStatus(): string {
        return this.status;
    }

}