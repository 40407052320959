import { Entities } from '@ratkaiga/core-nextgen';

export class IssueSchema extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssueSchema';

    schema: string;

    value: string;

    description: string;

    is_internal: boolean;

    is_system: boolean;

    order: number;

    is_separator: boolean = false;

    sub_schema: string;

    getSchema(): string {
        return this.schema;
    }

    getValue(): string {
        return this.value;
    }

    getDescription(): string {
        return this.description;
    }

    isInternal(): boolean {
        return this.is_internal;
    }

    isSystem(): boolean {
        return this.is_system;
    }

    getOrder(): number {
        return this.order;
    }

    isSeparator(): boolean {
        return this.is_separator;
    }

    getSubSchema(): string {
        return this.sub_schema;
    }

    /**
     * Kényelmi megoldás a sub schema vizsgálathoz
     * 
     * @param value 
     * @returns 
     */
    isSubSchemaAvailable(value: string): boolean {
        
        if (this.sub_schema) {
            return (this.sub_schema.indexOf(value) > -1);
        }

        return false;
    }

    /**
     * Kényelmi megoldás, hogy tudjunk érték nélküli separator-t csinálni. 
     * Mivel objektum típusokkal dolgozunk, így másképpen nem is lehet
     */
    setSeparator(): void {
        this.is_separator = true;
        this.description = '----------------------------------------------------------';
    }

}