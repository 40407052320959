import { Entities } from '@ratkaiga/core-nextgen';

export class TranslateInstance extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Translate\\Entity\\TranslateInstance';

    tag_id: number;

    locale: string;

    value: string;

    nyomda_id: number;

    getTagId(): number {
        return this.tag_id;
    }

    getLocale(): string {
        return this.locale;
    }

    getValue(): string {
        return this.value;
    }

    getNyomdaId(): number {
        return this.nyomda_id;
    }
    
}