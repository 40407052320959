import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { ScrollService } from '../service/scroll.service';

@Pipe({
  name: 'scopeAvailable'
})
export class ScopeAvailablePipe implements PipeTransform {

    constructor(private authService: AuthService) {}

    transform(value?: string[], ...args: unknown[]): boolean {
        return (value) ? this.authService.isUserScopesAvailable(value) : false;
    }

}