import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { AppGuard } from './app.guard';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router,
    private appGuard: AppGuard) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      
      if (this.authService.currentUser()) {

        // van user profile, tehát úgy vesszük, hogy az user be van lépve
        // egyben azt is nézzük, hogy az AppGuard-on átmegy -e az éppen aktuális route
        return resolve(this.appGuard.canActivate(route, state));
      } 
  
      if (this.authService.isFirstLoading) {
  
        this.authService.isFirstLoading = false;

        setTimeout(() => {
          if (this.authService.currentUser()) {
            // van user profile, tehát úgy vesszük, hogy az user be van lépve
            resolve(true);
          } else {
            // ha idáig eljutunk, akkor nem talátunk user profile-t, tehát elirányítjuk a felhasználót a login oldalra
            this.authService.logout();
            return resolve(false);
          }
        }, 3000);      
  
      } else {
  
        // ha idáig eljutunk, akkor nem talátunk user profile-t, tehát elirányítjuk a felhasználót a login oldalra
        this.authService.logout();
        resolve(false);
      }
      
    });

  }

}
