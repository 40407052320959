import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

export class ScrollUploadAdapterPlugin {

    constructor(editor: any) {
        console.log('ScrollUploadAdapterPlugin called');
        editor.plugins.get('FileRepository').createUploadAdapter = (loader, form) => {
            return new ScrollUploadAdapter(loader, form);
        };
    }

}

export class ScrollUploadAdapter {

    /**
     * Opcionális, az aktuális editor példányt befoglaló űrlap adatait kezeljük itt.
     * 
     * @TODO try-catchban kell kezelni minden formmal kapcsolatos műveletet 
     * @var FormGroup
     */
    form;

    /**
     * Az adapter ezen a változón keresztül kommunikál a CKEditor példánnyal
     */
    loader;

    /**
     * @var XMLHttpRequest
     */
    xhr;

    /**
     * A konstruktorban inicializáljuk a lokális scope-ban használandó változókat. A loader-en keresztül fogjuk elérni az editor-t, 
     * tehát ez fog kapcsolatot biztosítani nekünk az editor felé. A form egy opcionális paraméter, de ha szeretnénk a file mellé 
     * az űrlapnak megfelelő paraméterezést is küldeni, akkor szükség lehet rá.
     * 
     * 
     * @param loader 
     * @param form 
     */
    constructor(loader: any, form: FormGroup) {
        this.form = form;
        this.loader = loader;
    }

    /**
     * A funkció az image upload button megnyomása után feljövő system window OK eseményére triggerelődik és a 
     * kiválasztott állomány beolvasása után elindít egy új Promise-t, ami valójában a fájl feltöltését végzi.
     * 
     * @returns unknown
     */
    upload(): unknown {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            }));
    }

    /**
     * A funkció az image upload button megnyomása után feljövő system window Cancel eseményére triggerelődik és 
     * leállít minden az xhr példányban futó műveletet.
     */
    abort(): void {
        console.log('UploadAdapter abort');
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    /**
     * Inicializáljuk az XHR osztály példányunkat, amivel a későbbiekben műveleteket fogunk végezni. Az init során beállítjuk a megfelelő 
     * paramétereket, az Authorization tokent és a visszatérési értéket.
     */
    _initRequest(): void {

        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', environment.endpoints.fileservice + '/secure/upload', true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(environment.storage.token));
        xhr.responseType = 'json';

    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject, file): void {

        // xhr 
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;

            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            resolve({
                default: environment.endpoints.fileservice + '/guest/download/' + response.data.payload.file_request_hash_guest
            });
        });

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest(file): void {
        // Prepare the form data.
        const data = new FormData();

        let ownerApp = 'ScrollMax\\MyScroll'; // environment.ckEditor.defaultApp;
        if (this.form.controls.hasOwnProperty('fileserviceApp')) {
            ownerApp = this.form.controls.fileserviceApp.value;
        }

        let ownerEntity = 'Editor'; // environment.ckEditor.defaultEntity;
        if (this.form.controls.hasOwnProperty('fileserviceEntity')) {
            ownerEntity = this.form.controls.fileserviceEntity.value;
        }

        data.append('scrollFiles', file);
        data.append('owner_id', '0');
        data.append('owner_app', ownerApp);
        data.append('owner_entity', ownerEntity);
        data.append('public', '1');

        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.

        // Send the request.
        this.xhr.send(data);
    }

}