import { Entities } from '@ratkaiga/core-nextgen';
import { ScrollService } from '../../service/scroll.service';

export class FileserviceFolder extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Fileservice\\Entity\\FilestorageFolder';

    id: number;

    uuid: string;

    owner_app: string;

    label: string;

    count: number;

    size: number;

    filtered: boolean;

    getUUID(): string {
        return this.uuid;
    }

    getOwnerApp(): string {
        return this.owner_app;
    }

    getLabel(): string {
        return this.label;
    }

    getSize(): number {
        return this.size;
    }

    getFileCount(): number {
        return this.count;
    }

}