import { Entities } from '@ratkaiga/core-nextgen';
import { Nyomda } from '../nyomda.entity';
import { SupportUserProfile } from './support-user-profile.entity';

export class IssueGroup extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssueGroup';

    public static _private: string = 'private';

    nyomda: Nyomda;

    owner: SupportUserProfile;

    name: string;

    description: string;

    private: boolean;

    count: number = 0;

    setName(name: string): IssueGroup {
        this.name = name;
        return this;
    }

    getName(): string {
        return this.name;
    }

    setDescription(desc: string): IssueGroup {
        this.description = desc;
        return this;
    }

    getDescription(): string {
        return (this.description !== 'null') ? this.description : '';
    }

    setPrivate(state: boolean): IssueGroup {
        this.private = state;
        return this;
    }

    isPrivate(): boolean {
        return this.private;
    }

    getNyomda(): Nyomda {
        return this.nyomda;
    }

    getNyomdaName(): string {
        return this.nyomda.getName();
    }

    getOwner(): SupportUserProfile {
        return this.owner;
    }

    getOwnerName(): string {
        return this.owner.getName();
    }

    getIssueCount(): number {
        return this.count;
    }

}