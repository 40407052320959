import { Injectable } from '@angular/core';
import { Issue, IssueGroup, IssueGroupItemModel, IssueGroupModel, IssueSchema, IssueSearchFormModel, Nyomda, OAuth2User, SprintGroup, SupportPreload, SupportUserProfile } from '../entities/scroll';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';

import { EntityService, CoreResponse, CoreRequestParams, CoreRequestFilterModel } from '@ratkaiga/core-nextgen';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { ISupportSeverity } from '../interfaces/support.interfaces';
import { SupportProgressPopupState } from '../models/progress-popup-state';
import { OAuth2UserProfile, OAuth2UserProfileExtensions } from '../entities/auth';
import { AuthService } from './auth.service';
import { AppLog } from '../util/log.util';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';
import { isLabeledStatement } from 'typescript';
import { MultiselectOptGroup } from '@ratkaiga/core-nextgen/ui';

@Injectable({
  providedIn: 'root'
})
export class ScrollSupportService {

  /**
   * Entitások előtöltésekre kerültek -e
   */
  protected preloaded: boolean = false;

  protected preloadEntity: SupportPreload;

  public issueSearch: Subject<IssueSearchFormModel> = new Subject();

  /**
   * Az issue csoportok oldal állapotát tároljuk itt központilag, 
   * hogy route váltás esetén is vissza atudjuk olvasni.
   */
  public supportGroupPageState: BehaviorSubject<IssueGroupModel> = new BehaviorSubject(new IssueGroupModel());

  /**
   * Helper a súlyosság lista egyszerűbb kezeléséhez, nem deklaráljuk 500 helyen ugyanazt 
   */
  public static SeverityList: ISupportSeverity[] = [
    { value: 'none', label: 'label_none', level: 0, icon: 'Trivial.png', todo: true },
    { value: 'lowest', label: 'label_lowest', level: 1, icon: 'Lowest.png', todo: false },
    { value: 'low', label: 'label_low', level: 2, icon: 'Low.png', todo: true },    
    { value: 'medium', label: 'label_medium', level: 3, icon: 'Medium.png', todo: true },
    { value: 'high', label: 'label_high', level: 4, icon: 'High.png', todo: true },
    { value: 'highest', label: 'label_highest', level: 5, icon: 'Highest.png', todo: false },
    { value: 'critical', label: 'label_critical', level: 6, icon: 'Blocker.png', todo: true },
    { value: 'blocker', label: 'label_blocker', level: 7, icon: 'Blocker.png', todo: false },
  ];

  public static AllowedActors: { value: string, description: string }[] = [
    { value: 'developer', description: 'actor_label_developer' },
    { value: 'tester', description: 'actor_label_tester' },
    { value: 'documenter', description: 'actor_label_documenter' },
  ];

  currentIssueGroups: BehaviorSubject<IssueGroup[]> = new BehaviorSubject([]);
  getCurrentIssueGroups = this.currentIssueGroups.getValue();

  currentSprintGroups: BehaviorSubject<SprintGroup[]> = new BehaviorSubject([]);
  getCurrentSprintGroups = this.currentSprintGroups.getValue();

  /**
   * Issue search form állapotát tároljuk itt, későbbi visszaolvasáshoz, illetve route váltáshoz
   */
  protected supportIssueSearchFormStateSubject: BehaviorSubject<IssueSearchFormModel> = new BehaviorSubject(new IssueSearchFormModel());
  public supportIssueSearchFormState = this.supportIssueSearchFormStateSubject.asObservable(); 

  protected supportProgressPopupStateSubject: BehaviorSubject<SupportProgressPopupState[]> = new BehaviorSubject([]);
  public supportProgressPopupState = this.supportProgressPopupStateSubject.asObservable();

  constructor(private http:HttpClient, private entityService: EntityService, private authService: AuthService, private translateService: CoreTranslateService) { }

  /**
   * Helper metódus, egy adott schema objektumának visszaadásához, filtereye vagy alapértelmezetten az összes elemével
   * 
   * @param schema 
   * @returns 
   */
  getSchemaObjects(schema: string, filtered: boolean = false, isInternal: boolean = false, isSystem: boolean = false): IssueSchema[] {
    if (filtered) {
      return this.getPerloadEntity().getSchemas().filter(s => (s.schema === schema && s.is_internal === isInternal && s.is_system === isSystem)).sort((a, b) => a.order - b.order);
    } else {
      return this.getPerloadEntity().getSchemas().filter(s => s.schema === schema).sort((a, b) => a.order - b.order);
    }
  }

  /**
   * Helper metódus, egy adott schemaban szereplő státusz translate mezőjének visszaadásához
   * 
   * @param schema 
   * @param status 
   * @returns 
   */
  getSchemaStatusDescription(schema: string, status: string): string {
    return this.getSchemaObjects(schema)?.find(s => s.getValue() === status)?.getDescription();
  }
 
  /**
   * Autocomplete lista készítése a felhasználókból
   * 
   * @param nyomda_id 
   * @returns 
   */
  fetchUserAutocomplete(nyomda_id?: number): Observable<CoreResponse> {
    if (nyomda_id) {
      return this.http.get(`${environment.endpoints.support}/ngactor/list-by-nyomda/${nyomda_id}`).pipe(
        map((r) => this.entityService.parse(r).getResponse())
      );
    } else {
      return this.http.get(`${environment.endpoints.support}/ngactor/list-by-dev`).pipe(
        map((r) => this.entityService.parse(r).getResponse())
      );
    }
  }

  /**
   * Autocomplete lista készítése a felhasználókból, dev:dev scope-val együtt használjuk, általában az issue keresőhöz
   * 
   * @param filter 
   * @returns 
   */
  fetchUserAutocompleteAll(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngactor/list-all${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Issue vagy issue update rögzítése esetén kérünk le egy request id-t ami egy v4-es uuid lesz.
   * 
   * @returns 
   */
  fetchRequestID(): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissue/request-id`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issue adatainak lekérdezése a kiszolgálótól
   * 
   * @returns 
   */
  fetchIssueDetails(id: number): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissue/${id.toString()}`).pipe(
      map((r) => this.entityService.parse(r, environment.debug).getResponse())
    );
  }

  /**
   * A filternek megfelelő issue lista lekérdezése, illetve filter nélkül a backend kontroller 
   * által visszaadott alapértelmezett lista.
   * 
   * @param filter 
   * @returns 
   */
  fetchIssue(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissue${filter}`).pipe(
      map((r) => this.entityService.parse(r, environment.debug).getResponse())
    );
  }

  /**
   * A filternek megfelelő issue lista lekérdezése, illetve filter nélkül a backend kontroller 
   * által visszaadott alapértelmezett lista.
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueSimple(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissue/list-simple${filter}`).pipe(
      map((r) => this.entityService.parse(r, environment.debug).getResponse())
    );
  }

  /**
   * Egy adott issue adatait változtatjuk meg.
   * 
   * @param params 
   * @param id 
   * @returns 
   */
  changeIssue(params: HttpParams, id: number): Observable<CoreResponse> {
    return this.http.put(`${environment.endpoints.support}/ngissue/${id}`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Elfogadunk egy issue-re adott ajánlatot.
   * 
   * @param id 
   * @returns 
   */
  acceptIssueOffer(id: number): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissueoffer/accept/${id}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Elutasítunk egy issue-re adott ajánlatot.
   * 
   * @param id 
   * @returns 
   */
  declineIssueOffer(id: number): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissueoffer/decline/${id}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Az id-nek megfelelő issue törlése, ahol az eredmény egy üres collection lesz vagy permission 
   * probléma esetén resource unavailable error
   * 
   * @param id 
   * @returns 
   */
  deleteIssue(id: number): Observable<CoreResponse> {
    return this.http.delete(`${environment.endpoints.support}/ngissue/${id.toString()}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issue-hoz tartozó összes issueupdate lekérdezése a kiszolgálótól
   * 
   * @returns 
   */
  fetchIssueupdate(filter: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissueupdate${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy szöveges issueupdatet rögíztünk a paramsban kapott értékek alapján 
   * @param params 
   * @returns 
   */
  addIssueupdateMessage(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngissueupdate/text-update`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issue-hoz tartozó összes issueupdate lekérdezése a kiszolgálótól
   * 
   * @returns 
   */
  fetchIssuechange(filter: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissuechange${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issuehez az összes scroll file entitást visszaadjuk
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueAttachment(filter: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngfileref${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );    
  } 

  /**
   * Egy adott issuehez az összes megfigyelőt visszaadjuk
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueWatchers(id: number): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissue/watchers/${id}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );    
  } 

  /**
   * Egy adott issuehez tartozó összes aktort visszaadjuk
   *
   * @param issueId 
   * @returns 
   */
  fetchIssueActors(filter: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngactor${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );    
  }

  /**
   * Egy adott issuehez rögzít fel egy aktort
   * 
   * @param params 
   * @returns 
   */
  addIssueActor(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngactor/add`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issueről töröl egy aktort
   * 
   * @param params 
   * @returns 
   */
  removeIssueActor(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngactor/remove`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issuet rögzítünk a paramsban kapott adatok alapján
   * 
   * @param params 
   * @returns 
   */
  addIssue(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngissue`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issue változást rögzítünk a paramsban kapott adatok alapján
   * 
   * @param params 
   * @returns 
   */
  addIssueChange(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngissuechange`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Rögzítünk egy issue csoportot, majd visszakapjuk az eredményt
   * 
   * @param params 
   * @returns 
   */
  addIssueGroup(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngissuegroup/`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Megváltoztatunk egy issue csoportot, majd visszakapjuk az eredményt
   * 
   * @param params 
   * @returns 
   */
  changeIssueGroup(params: HttpParams, id: number): Observable<CoreResponse> {
    return this.http.put(`${environment.endpoints.support}/ngissuegroup/${id}`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Felveszünk az issuehez egy ajánlatot
   * 
   * @param params 
   * @returns 
   */
  addIssueOffer(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngissueoffer/create`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Törlünk egy issue csoportot, majd visszakapunk egy üres eredményt (200-as státusszal)
   * 
   * @param id 
   * @returns 
   */
  deleteIssueGroup(id: number): Observable<CoreResponse> {
    return this.http.delete(`${environment.endpoints.support}/ngissuegroup/${id}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük az issue csoport összes elemét (nem túl részletes lista, csak menedzseléshez)
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueGroupItems(filter: string): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissuegroup/listissues${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük az összes a felhasználó számára elérhető csoportot a kiszolgálótól és tároljuk a service-n 
   * belül egy behaviorSubjectben. Jellemzően ezt a metódust az app init során fogjuk használni.
   */
  fetchIssueGroupsToSubject(): void {
    this.fetchIssueGroups().subscribe((r: CoreResponse) => {
      this.currentIssueGroups.next(r.getData<IssueGroup[]>() as unknown[] as IssueGroup[]);
    });
  }

  /**
   * Kényelmi metódus a csoportokat tartalmazó behaviorSubject frissítéséhez. Ennek a segítségével 
   * frissíthetjük a "központi" listánkat anélkül, hogy újra le kellene kérdezzük a kiszolgálótól az adatokat.
   * 
   * @param groups 
   */
  refreshIssueGroupsInSubject(groups: IssueGroup[]): void {
    this.currentIssueGroups.next(groups);
  }

  /**
   * Lekérdezzük az összes a felhasználó számára elérhető csoportot a kiszolgálótól és visszatérünk egy 
   * CoreResponse típusú obszerverrel.
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueGroups(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissuegroup${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük az issuehez tartozó összes csoportot (nyomda szinten), általában ez a csoport szelektorok kiválasztottságához kell
   * 
   * @param issue 
   * @returns 
   */
  fetchGroupsByIssue(issue: Issue): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngissuegroup/listgroupsbyissue/${issue.getId()}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Egy adott issue-t vagy issueket adunk hozzá egy csoporthoz. A visszatérés minden esetben 
   * a csoport entitása lesz, tehát nem egy issue listát fogunk visszakapni.
   * 
   * @param group 
   * @param issues 
   * @returns 
   */
  addIssueToGroup(groupId: number, issueId: number): Observable<CoreResponse> {

    let params = new HttpParams();
    params = params.set('group_id', groupId.toString());
    params = params.set('issue_id', issueId.toString());

    return this.http.post(`${environment.endpoints.support}/ngissuegroup/add_issue_to_group`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );

  }

  /**
   * Egy adott issue-ről eltávolítjuk a csoportot. A visszatérés minden esetben az issue entitása lesz.
   * 
   * @param issueId 
   * @param groupId 
   * @returns 
   */
  removeIssueFromGroup(issueId: number, groupId: number): Observable<CoreResponse> {

    let params = new HttpParams();
    params = params.set('group_id', groupId.toString());
    params = params.set('issue_id', issueId.toString());

    return this.http.post(`${environment.endpoints.support}/ngissuegroup/remove_group_from_issue`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Hozzáadunk egy adott issueId-t a Pre-Sprint listához. A groupId opcionális, ezt a parammétert csak a 
   * Pre-Sprint komponensből használjuk majd, ha az issue-t nem osztjuk ki, hanem pl. várakoztatjuk.
   * 
   * @param issueId 
   * @param groupId 
   * @returns 
   */
  addIssueToPreSprint(issueId: number, groupId?: number): Observable<CoreResponse> {

    let params = new HttpParams();
    params = params.set('issue_id', issueId.toString());

    if (groupId) 
      params = params.set('group_id', groupId.toString());

    return this.http.post(`${environment.endpoints.support}/ngpresprint`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Töröljük az adatbázisból a megadott ID-vel rendelkező elemet
   * @param id 
   * @returns 
   */
  deleteIssueFromPreSprint(id: number): Observable<CoreResponse> {
    return this.http.delete(`${environment.endpoints.support}/ngpresprint/${id}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Véglegesen kezelünk egy pre-sprint issue-t, tehát ezt már nem fogjuk a listánkból visszakapni, 
   * mert bekerül a sprintekbe.
   * 
   * @param params 
   * @returns 
   */
  hendlePreSprintIssue(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngpresprint/handle`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Legyűjtünk az API-tól egy pre sprint listát
   * 
   * @param filter 
   * @returns 
   */
  fetchPreSprintIssues(filter:string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngpresprint${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Rögzítünk egy sprint csoportot
   * 
   * @param params 
   * @returns 
   */
  addSprintGroup(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngsprintgroup`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse()),
      tap(this.fetchSprintGroups())
    );
  }

  /**
   * Legyűjtjük az API-tól az összes Sprint csoportot, majd visszaadjuk a feliratkozónak. Menet 
   * közben beseteljük a központi behaviorsubjectbe is.
   * 
   * @param filter 
   * @returns 
   */
  fetchSprintGroups(filter:string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngsprintgroup${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse()),
      tap(r => this.currentSprintGroups.next(r.getData<SprintGroup[]>() as unknown[] as SprintGroup[]))
    );
  }

  /**
   * Legyűjtjük a sprintek listáját, ne felejtsük el paraméterezni a lekérdezésünket.
   * 
   * @param filter 
   * @returns 
   */
  fetchSprints(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngsprint${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Mutex lista lekérdezése, feltöltéskéréshez használjuk
   * 
   * @param filter 
   * @returns 
   */
  fetchScrollProgramTypeList(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.release}/scroll_program_type${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }





  setIssueFormState(state: IssueSearchFormModel): void {
    this.supportIssueSearchFormStateSubject.next(state);
  }

  setSupportProgressPopupState(state: SupportProgressPopupState[]): void {
    this.supportProgressPopupStateSubject.next(state);
  }

  /**
   * Lekérdezzük a felhasználó összes tag elemét, amit bármikor is todo bejegyzésekhez rögzített. 
   * Itt nincs filterezés, gyakorlatilag egy flat array-t fogun visszakapni.
   * 
   * @returns 
   */
  fetchTodoTags(): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngtodoitem/tags/`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük a felhasználó összes to do itemjét. Teljesen normális core funkcionalitás
   * 
   * @param filter 
   * @returns 
   */
  fetchTodoItems(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngtodoitem${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Rögzítünk egy to do itemet, majd visszakapjuk az eredményt
   * 
   * @param params 
   * @returns 
   */
  addTodoItem(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/ngtodoitem/`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Megváltoztatunk egy to do itemet, majd visszakapjuk az eredményt
   * 
   * @param params 
   * @returns 
   */
  changeTodoItem(params: HttpParams, id: number): Observable<CoreResponse> {
    return this.http.put(`${environment.endpoints.support}/ngtodoitem/${id}`, params).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Törlünk egy to do itemet, majd visszakapjuk az aktuális listát
   * 
   * @param id 
   * @returns 
   */
  deleteTodoItem(id: number): Observable<CoreResponse> {
    return this.http.delete(`${environment.endpoints.support}/ngtodoitem/${id}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük a havi issue számokat, illetve ha ezt egy nyomda_id filterrel kombináljuk 
   * akkor lekérdezzük ugyanezeket a számokat egy adott nyomdára nézve.
   * 
   * @param filter 
   * @returns 
   */
  fetchMonthlyIssueRates(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngnyomda/stat/issues-by-month${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük az issue státuszokhoz kapcsolódó számokat, illetve ha ezt egy nyomda_id filterrel kombináljuk 
   * akkor lekérdezzük ugyanezeket a számokat egy adott nyomdára nézve.
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueStatusDispersion(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngnyomda/stat/issues-by-status${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Lekérdezzük az issue státuszokhoz kapcsolódó számokat, illetve ha ezt egy nyomda_id filterrel kombináljuk 
   * akkor lekérdezzük ugyanezeket a számokat egy adott nyomdára nézve.
   * 
   * @param filter 
   * @returns 
   */
  fetchUnresolvedIssueCount(days: number, filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngnyomda/stat/unresolved-issues/${days}${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Ongoing issues 
   * 
   * @param filter 
   * @returns 
   */
  fetchOngoingIssueCount(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngnyomda/stat/ongoing-issues/${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Ongoing issues 
   * 
   * @param filter 
   * @returns 
   */
  fetchIssueLifecycles(filter: string = ''): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngnyomda/stat/issue-lifecycles/${filter}`).pipe(
      map((r) => this.entityService.parse(r).getResponse())
    );
  }

  /**
   * Minden suppport feltöltést ezen a végponton keresztül csináljunk, már amennyiben lehetséges. Az UUID segítségével 
   * a későbbiekben vagy akár külön is összecsatolhatók a feltöltések.
   * 
   * @param file 
   * @param uuid 
   * @param namespace 
   * @param referenceId 
   * @returns 
   */
  uploadFile(file: File, uuid: string, namespace: string, referenceId?: number): Observable<HttpEvent<any>> {
    
    const formData: FormData = new FormData();
    formData.append('referenceId', (referenceId) ? referenceId.toString() : '');
    formData.append('namespace', namespace);
    formData.append('file', file);
    formData.append('uuid', uuid);
    
    return this.http.post<HttpEvent<any>>(`${environment.endpoints.support}/ngfileref/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
    });

  }

  /**
   * Seteljük a preload entitásunkat. Ez egy csomó olyan dolgot tartalmazhat amit csak egyszer, illetve 
   * előre még a felület felépítése előtt szükséges betöltenünk. Azokat az entitás szetteket, amiket nem 
   * csak egyszer töltünk be, már itt a besetelés során próbáljuk meg kezelni, tehát pl. a groups entitásokra
   * szükségünk lesz később, de ezek változhatnak ezért egy behavior subject is tárolja ezeketet, ezért itt adjuk 
   * át az első értéket a subjectnek. 
   * 
   * @param value 
   */
  setPreloadEntity(value: SupportPreload): void {

    // preload entitás setelése
    this.preloadEntity = value;

    // további egyedi logikák a preload tartalma alapján, lásd. a metódus leírását
    if (this.preloadEntity.getGroups()) {
      this.currentIssueGroups.next((this.preloadEntity.getGroups() as unknown[] as IssueGroup[]).filter(r => r instanceof IssueGroup));
    }    

    if (this.preloadEntity.getSprintGroups()) {
      this.currentSprintGroups.next((this.preloadEntity.getSprintGroups() as unknown[] as SprintGroup[]).filter(r => r instanceof SprintGroup));
    }

  }

  getPerloadEntity(): SupportPreload {
    return this.preloadEntity;
  }

  /**
   * Setelhetjük a preloaded flage -et 
   * @param value 
   */
  setPreloaded(value: boolean): void {
    this.preloaded = value;
  }

  /**
   * Megmondja nekünk, hogy a support előtöltötte -e a preload entitást
   * @returns 
   */
  isPreloaded(): boolean {
    return this.preloaded;
  }

  loadPreloadAsync(): Promise<void> {

    return this.fetchPreload().toPromise().then((u: CoreResponse[]) => {

        if (u[0] instanceof CoreResponse && u[1] instanceof CoreResponse) {

          // OAuth user profile objektumot fogunk visszakapni
          const preload = (u[0].getData<SupportPreload>() as SupportPreload);
    
          // betöltjük az user profile-t
          const profile = this.authService.getCurrentUserProfile();
    
          // létrehozunk egy extension osztályt
          const extension = new OAuth2UserProfileExtensions();
    
          // OAuth kiegészítések objektumát kapjuk vissza (nyomda, user)
          const ext = (u[1].getData<[]>() as unknown[])[0];
    
          // seteljük a felhasználó hatóköreit, ezt a tokenből is tudjuk már
          extension.setScopesArray(this.authService.getUserCurrentScopes());
    
          // seteljük a felhasználóhoz lekérdezett objektumokat
          extension
            .setNyomda(ext['nyomda'] as Nyomda)
            .setUser(ext['user'] as OAuth2User)
            .setIssueWatchList(preload.getWatechedIssues())
            .setIssueCounters(preload.getCounters());
    
          profile.setProfileExtension(extension);

          // seteljük a preload entitást
          this.setPreloadEntity(preload);

        }
      
    });

  }

  private fetchPreload(): Observable<CoreResponse[]> {

    return this.http.get(`${environment.endpoints.support}/ngpreload`).pipe(
      map((m) => this.entityService.parse(m).getResponse()),
      switchMap(extensions =>         
      this.authService.getUserDetails().pipe(
        map(profile => [extensions, profile]
      )
    )));

  }

  /**
   * Proxy funkció az alapértelmezett request paramsok általánosabb használatához.
   * TODO ha esetleg kell akkor itt felülírhatjuk az IssueDefaultRequestParams hívását
   */
  getDefaultRequestParams(): CoreRequestParams {
    return this.getIssueDefaultRequestParams();
  }

  /**
   * Issue listához adunk vissza egy alapértelmezett request paramsot
   * @returns 
   */
  getIssueDefaultRequestParams(): CoreRequestParams {

    const params = new CoreRequestParams([]);

    params.pageNumber = 0;
    params.pageSize = 25;
    params.sortField = 'created_at';
    params.sortOrder = 'desc';

    return params;
  }

  /**
   * Összerakunk egy struktúrált listát a multiselecthez, felhasználó típus alapján, hiszen a dev felhasználók 
   * mást látnak mint a normál felhasználók.
   * 
   * @param listType 
   * @returns 
   */
  getIssueSchemaOptGroups(listType: string = 'user'): MultiselectOptGroup[] {

    const allowedForUser = [
      'invoicing',
      'new',
      'denied',
      'in_progress',
      'need_information',
      'offered',
      'closed',
      'archived',
      'released'
    ];

    const groupsForUser: MultiselectOptGroup[] = [
      {
        name: 'Általános',
        refs: [
          'new',
          'need_information',
          'in_progress',
          'released',
          'closed'
        ],
        items: []
      },
      {
        name: 'Üzleti',
        refs: [
          'offered',          
          'invoicing'
        ],
        items: []
      },
      {
        name: 'Egyéb',
        refs: [
          'denied',
          'archived'
        ],
        items: []
      }
    ];

    const allowedList = [];
    const statusList: MultiselectOptGroup[] = [];
    const sl = this.getPerloadEntity().getSchemas().filter(s => s.schema === 'issue')

    if (listType === 'user') {
      sl.forEach((item: IssueSchema) => {
        // ez engedélyezett a felhasználó számára, ha megtaláljuk a referenciák között. felvesszük a listába
        if (allowedForUser.includes(item.getValue())) 
          allowedList.push({ value: item.getValue(), label: this.translateService.translate(item.getDescription()) });
      });

      groupsForUser.forEach(group => {
        group.refs.forEach(ref => {
          if (allowedList.findIndex(a => a.value === ref) > -1) {
            group.items.push(allowedList.find(a => a.value === ref));
          }
        });
      });

    }
    
    return groupsForUser;
  }

}
