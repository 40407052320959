import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreRequestFilterModel, CoreRequestParams, CoreResponse } from '@ratkaiga/core-nextgen';
import { Subject } from 'rxjs';
import { Issue } from 'src/app/_core/entities/scroll';
import { ScrollSupportService } from 'src/app/_core/service/scroll-support.service';

@Component({
  selector: 'spirit-issue-last-changed-widget',
  templateUrl: './issue-last-changed-widget.component.html',
  styleUrls: ['./issue-last-changed-widget.component.scss']
})
export class IssueLastChangedWidgetComponent implements OnInit, OnDestroy {

  params: CoreRequestParams;

  issues: Issue[] = [];

  destroy$: Subject<void> = new Subject();

  preloading: boolean;
  preloadingMessage: string = 'Az adatok betöltés alatt';

  constructor(private supportService: ScrollSupportService) {}

  ngOnInit(): void {
        
    this.preloading = true;
    this.params = this.supportService.getDefaultRequestParams();
    // max 15 bejegyzést szeretnénk itt látni, az utolsó módosítás sorrendjében
    this.params.sortField = 'updated_at';
    this.params.pageSize = 10;
    this.params.filter = [];

    this.fetchComponentData();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchComponentData(): void {
    this.supportService.fetchIssue(this.params.getQuery()).subscribe({
      next: (response: CoreResponse) => {
        this.issues = response.getData<Issue[]>() as unknown[] as Issue[];  
        this.preloading = false;      
      }
    })
  }

}
