import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    
    if (value) {    
      return (value.slice(0, 1)).toUpperCase(); 
    } else {
      return value;
    }
    
  }

}
