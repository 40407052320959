import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';

import { OAuth2UserProfile } from 'src/app/_core/entities/auth';

import { AuthService } from 'src/app/_core/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'spirit-user-inner-simple',
  templateUrl: './user-inner-simple.component.html',
  styleUrls: ['./user-inner-simple.component.scss']
})
export class UserInnerSimpleComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  locales: string[];
  labels: string[][] = [];
  currentLocale: string;

  user$: Observable<OAuth2UserProfile>;

  constructor(private authService: AuthService, private translateService: CoreTranslateService) { }

  selectLanguage(language: string): void {
    
  }

  getLabel(locale: string): string {
    return this.labels.find(l => l[0] === locale)[1];
  }

  getCurrentLocaleLabel(): string {
    return this.labels.find(l => l[0] === this.translateService.getCurrentLocale())[1];
  }

  /**
   * Kiléptetjük a felhasználót az authService-n keresztül és újratöltjük az oldalt (forceoljuk)
   * 
   * @param event 
   * @param self 
   */
  onLogout(event: unknown, self: this): void {
    if (event) {
      self.authService.logout();
      location.reload();
    }      
  }

  ngOnInit(): void {
    this.user$ = of(this.authService.currentUser());

    this.currentLocale = this.translateService.getCurrentLocale();
    this.locales = environment.translate.versions.find(v => v.name === environment.layout.version).locales;
    this.labels = environment.translate.versions.find(v => v.name === environment.layout.version).labels;
  }
  
  ngOnDestroy(): void {
    
  }

}
