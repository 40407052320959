import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Issue } from 'src/app/_core/entities/scroll';

@Component({
  selector: 'spirit-issue-list-item',
  templateUrl: './issue-list-item.component.html',
  styleUrls: ['./issue-list-item.component.scss']
})
export class IssueListItemComponent {

  @Input() issue: Issue = new Issue();
  @Input() index: number = 0;

  language: string;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.language = locale;
  }  

}
