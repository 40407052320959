import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IProgressButtonState } from 'src/app/_core/interfaces/common.interfaces';
import { ProgressButtonState } from 'src/app/_core/models/progress-button-state';

@Component({
  selector: 'spirit-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('button', { static: false }) button;

  @Input() options: Subject<ProgressButtonState>;
  @Output() click: EventEmitter<string> = new EventEmitter();

  state: ProgressButtonState = new ProgressButtonState();

  destroy$: Subject<void> = new Subject();

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {

    this.state
      .setButtonLabel('Rögzítés');

    this.options.pipe(takeUntil(this.destroy$)).subscribe((opt: ProgressButtonState) => {
    
      this.state = opt;
      if (opt.isProgress()) {
        this.renderer.setAttribute(this.button.nativeElement, 'disabled', 'disabled');
      } else {
        this.renderer.removeAttribute(this.button.nativeElement, 'disabled');
      }

    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {  }

  onClick(event: Event): void {
    this.click.emit(this.state.getId());
  }

}
