
import { Entities } from '@ratkaiga/core-nextgen';

export class DataPump extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Adatpumpa\\Entity\\DataPump';

    nyomda_id: number;

    name: string;

    ip_addresses: string;

    query: string;

    attributes: string;

    ip_pool: Array<string> = [];

    getNyomdaId(): number {
        return this.nyomda_id;
    }

    setNyomdaId(nyomda_id: number): DataPump {
        this.nyomda_id = nyomda_id;
        return this;
    }

    getName(): string {
        return this.name;
    }

    setName(name: string): DataPump {
        this.name = name;
        return this;
    }

    getIPAddresses(): string {
        return this.ip_addresses;
    }

    setIPAddresses(ip_addresses: string): DataPump {
        this.ip_addresses = ip_addresses;
        this.ip_pool = this.ip_addresses.split(';');
        return this;
    }

    getQuery(): string {
        return this.query;
    }

    setQuery(query: string): DataPump {
        this.query = query;
        return this;
    }

    getAttributes(): string {
        return this.attributes;
    }

    setAttributes(attr: string): DataPump {
        this.attributes = attr;
        return this;
    }

    getIPPool(): string[] {
        return this.ip_pool;
    }
    
}