import { Entities } from '@ratkaiga/core-nextgen';
import { Issue } from './support-issue.entity';
import { SupportUserProfile } from './support-user-profile.entity';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SupportUserAutocomplete } from './support-user-autocomplete.entity';

export class PreSprint extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgPreSprint';

    issue: Issue;

    handler: SupportUserProfile;

    status: string;

    target_group_id: number;

    // virtuális mezők amik abban segítenek nekünk, hogy a pre-sprint elemmel kapcsolatos kezelési 
    // feladatokat OOP módon tudjuk megoldani

    protected _deadline: string | NgbDateStruct | NgbDate;

    protected _assignedUsers: number[] = [];

    protected _workHours: number = 0;

    /**
     * A ticketet adjuk vissza
     * @returns 
     */
    getIssue(): Issue {
        return this.issue;
    }

    /**
     * A listához rendelő felhasználó objektumát adjuk vissza
     * @returns 
     */
    getHandler(): SupportUserProfile {
        return this.handler;
    }

    /**
     * Proxy funkció a listához rendelő felhasználó objektumához
     * @returns 
     */
    getUser(): SupportUserProfile {
        return this.getHandler();
    }

    /**
     * A státusz azonosítóját adjuk vissza
     * @returns 
     */
    getStatus(): string {
        return this.status;
    }

    /**
     * A fordítható label-t adjuk vissza
     * @returns 
     */
    getStatusDescription(): string {
        return 'label_' + this.status;
    }

    /**
     * A cél sprint csoportot adjuk vissza
     * @returns 
     */
    getTargetGroupId(): number {
        return this.target_group_id;
    }

    setTargetGroupId(id: number): PreSprint {
        this.target_group_id = id;
        return this;
    }

    setDeadline(deadline: string | NgbDateStruct | NgbDate): PreSprint {
        this._deadline = deadline;
        return this;
    }

    getDeadline(): string | NgbDateStruct | NgbDate {
        return this._deadline;
    }

    setAssignedUsers(users: number[]): PreSprint {
        this._assignedUsers = users;
        return this;
    }

    getAssignedUsers(): number[] {
        return this._assignedUsers;
    }

    addAssignedUser(id: number): void {
        if (!this._assignedUsers.includes(id))
            this._assignedUsers.push(id);
    }

    removeAssignedUser(id: number): void {
        if (this._assignedUsers.includes(id))
            this._assignedUsers.splice(this._assignedUsers.findIndex(i => i === id), 1);
    }

    setWorkhours(hours: number): PreSprint {
        this._workHours = hours;
        return this;
    }

    getWorkhours(): number {
        return this._workHours;
    }

}