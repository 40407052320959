import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreRequestFilterModel, CoreRequestParams, CoreResponse } from '@ratkaiga/core-nextgen';
import { Subject } from 'rxjs';
import { Issue } from 'src/app/_core/entities/scroll';
import { ScrollSupportService } from 'src/app/_core/service/scroll-support.service';

@Component({
  selector: 'spirit-issue-offer-list-widget',
  templateUrl: './issue-offer-list-widget.component.html',
  styleUrls: ['./issue-offer-list-widget.component.scss']
})
export class IssueOfferListWidgetComponent implements OnInit, OnDestroy {

  params: CoreRequestParams;

  issues: Issue[] = [];

  destroy$: Subject<void> = new Subject();

  constructor(private supportService: ScrollSupportService) {}

  ngOnInit(): void {
    
    this.params = this.supportService.getDefaultRequestParams();
    this.params.filter = [
      new CoreRequestFilterModel('main_status', 'offered')
    ];

    this.fetchComponentData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchComponentData(): void {
    this.supportService.fetchIssueSimple(this.params.getQuery()).subscribe({
      next: (response: CoreResponse) => {
        this.issues = response.getData<Issue[]>() as unknown[] as Issue[];
      }
    });
  }

}
