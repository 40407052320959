import { ICoreTableColumn, ICoreTableColumnWidth } from "../interfaces/core-table.interfaces";

export class CoreTableColumnWidth implements ICoreTableColumnWidth {
    
    pixel: number = 128;
    
    percent: number = 15;

    getPixel(): number {
        return this.pixel;
    }

    getPercent(): number {
        return this.percent;
    }

    setPercent(value: number): ICoreTableColumnWidth {
        this.percent = value;
        return this;   
    }

    setPixel(value: number): ICoreTableColumnWidth {
        this.pixel = value;
        return this;   
    }
}

export class CoreTableColumn implements ICoreTableColumn {

    /**
     * A tábla azonosítója
     * @var string
     */
    public table: string;

    /**
     * A tábla oszlop azonosítója
     * @var string
     */
    public key: string;

    /**
     * A tábla oszlop alapértelmezett szélességei
     * @var ICoreTableColumnWidth
     */
    public width = { pixel: 128, percent: 15 } as unknown as ICoreTableColumnWidth;

    /**
     * A tábla oszlop alapértelmezett rendezhetősége (alapban nem rendezhető)
     * @var boolean
     */
    public sortable: boolean = false;

    /**
     * A tábla oszlop alapértelmezett szélességéhez tartozó egység (percent vagy pixel)
     */
    public method;
    
    public static AvailableWidthMethods = { percent: '%', pixel: 'px' };

    public static DefaultWidthMethod = 'percent';

    constructor() {
        this.width = new CoreTableColumnWidth();
    }

    setTable(table: string): CoreTableColumn {
        this.table = table;
        return this;
    }

    getTable(): string {
        return this.table;
    }

    setKey(key: string): CoreTableColumn {
        this.key = key;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    setMethod(method: string): CoreTableColumn {
        this.method = method;
        return this;
    }

    getMethod(): string {
        return this.method;
    }

    /**
     * Visszakapjuk az aktuális szélesség metódusra a megfelelő suffixet (px, %) 
     * 
     * @param method 
     * @returns 
     */
    public static getMeasurement(method?: string): string {
        return (method) ? CoreTableColumn.AvailableWidthMethods[method] : CoreTableColumn.AvailableWidthMethods[CoreTableColumn.DefaultWidthMethod];
    }

    /**
     * Beállítjuk a szélességet és a metódust % szélességű formára
     * 
     * @param value 
     * @returns 
     */
    setWidthPercent(value: number): CoreTableColumn {
        this.width.setPercent(value);
        this.method = 'percent';
        return this;
    }

    /**
     * Beállítjuk a szélességet és a metódust pixel szélességű formára
     * 
     * @param value 
     * @returns 
     */
    setWidthPixel(value: number): CoreTableColumn {
        this.width.setPixel(value);
        this.method = 'pixel';
        return this;
    }

    /**
     * Visszakapjuk az éppen aktuális szélességet a megfelelő metódus alapján, 
     * HA nem állítottunk szélességet, tehát ezen keresztül nem állítottunk metódust, 
     * akkor megvizsgáljuk az argumentumban érkező metódust. HA ilyet sem találunk, 
     * akkor az alapértelmezett metódusnak megfelelően adjuk vissza az értéket. 
     * 
     * @param method 
     * @returns 
     */
    getWidth(method?: string): number {
        return this.width[this.method ?? method ?? CoreTableColumn.DefaultWidthMethod];
    }

    setSortable(value: boolean): CoreTableColumn {
        this.sortable = value;
        return this;
    }

    isSortable(): boolean {
        return this.sortable;
    }

}