/**
 * IssueGroup kiválasztása esetén a benne lévő itemek betekintő nézetéhez tartozó struktúra
 */
export class IssueGroupItemModel {

    id: number;

    subject: string;

    main_status: string;

    main_type: string;

    schema: string;

    private main_status_label: string;

    private main_type_label: string;

    setId(id: number): IssueGroupItemModel {
        this.id = id;
        return this;
    }

    getId(): number {
        return this.id;
    }

    getSubject(): string {
        return this.subject;
    }

    setSubject(subject: string): IssueGroupItemModel {
        this.subject = subject;
        return this;
    }

    getSchema(): string {
        return this.schema;
    }

    setSchema(schema: string): IssueGroupItemModel {
        this.schema = schema;
        return this;
    }

    setStatusLabel(label: string): IssueGroupItemModel {
        this.main_status_label = label;
        return this;
    }

    getStatusLabel(): string {
        return this.main_status_label;
    }

    setTypeLabel(label: string): IssueGroupItemModel {
        this.main_type_label = label;
        return this;
    }

    getTypeLabel(): string {
        return this.main_type_label;
    }

    setMainStatus(status: string): IssueGroupItemModel {
        this.main_status = status;
        return this;
    }

    setMainType(type: string): IssueGroupItemModel {
        this.main_type = type;
        return this;
    }

}