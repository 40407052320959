export interface IPusherMessage {
    message: string;
    datetime: string;
    subevent: string;
    payload: unknown[];
    sender: string;
    level: string;
}

export interface IPusherEvent {
    
    channel: string;
    
    datetime: string;
    
    message: IPusherMessage;

    setChannel(channel: string): PusherEvent;

    getChannel(): string;

    setDateTime(datetime: string): PusherEvent;

    getDateTime(): string;

    setMessage(message: IPusherMessage): IPusherEvent;

    getMessage(): IPusherMessage;
}

export class PusherEvent implements IPusherEvent {
    
    channel: string;
    
    datetime: string;
    
    message: IPusherMessage;

    setChannel(channel: string): PusherEvent {
        this.channel = channel;
        return this;
    }

    getChannel(): string {
        return this.channel;
    }

    setDateTime(datetime: string): PusherEvent {
        this.datetime = datetime;
        return this;
    }

    getDateTime(): string {
        return this.datetime;
    }

    setMessage(message: IPusherMessage): IPusherEvent {
        this.message = message;
        return this;
    }

    getMessage(): IPusherMessage {
        return this.message;
    }

}

export enum PusherChannels {
    GLOBAL = 'global',
    MESSAGE_API = 'message-api'
}