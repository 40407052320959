import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, takeUntil, tap } from 'rxjs';
import { ICustomOptionValue, ICustomSelectOptions } from 'src/app/_core/interfaces/custom-select.interfaces';
import { StaticService } from 'src/app/_core/service/static.service';

@Component({
  selector: 'spirit-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CustomSelectComponent implements OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('dropdownsearchfield', { static: false }) dropdownsearchfield: ElementRef;
  @ViewChild('dropdownsearch', { static: true }) dropdownsearch: ElementRef<HTMLElement>;
  @ViewChild('dropdownvalue', { static: true }) dropdownvalue: ElementRef<HTMLElement>;
  @ViewChild('dropbutton', { static: true }) dropbutton: ElementRef<HTMLElement>;
  @ViewChild('dropdown', { static: true }) dropdown: ElementRef<HTMLElement>;

  @Input() prompt: string;
  @Input() values: unknown[] = [];
  @Input() selectedValue: ICustomOptionValue;

  @Input() options: ICustomSelectOptions = {
    useSearch: false,
    useAvatar: false, 
    keys: { value: 'id', label: 'label' }
  };

  @Output() selected: EventEmitter<unknown> = new EventEmitter();

  currentValues: ICustomOptionValue[] = [];
  currentValuesBackup: ICustomOptionValue[] = [];

  name: string = StaticService.createUUIDv4();

  destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prompt && changes.values && changes.options) {
      this.cdr.detectChanges();
    } else if (changes.values) {      
      this.initValues();
      this.initOptions();
    }
  }

  ngOnDestroy(): void {
    // destroy esetén eldobáljuk a listenereket
    this.dropbutton.nativeElement.removeAllListeners();
    // unsubsribe
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    
    // felkötünk a drop buttonra egy click eventet
    this.dropbutton.nativeElement.addEventListener("click", () => {

      // a getAttributes mindig stringet ad vissza, nem tudunk booleanra komparálni
      if (this.dropbutton.nativeElement.getAttribute("aria-expanded") === "true") {
        this.renderer.setAttribute(this.dropbutton.nativeElement, "aria-expanded", "false");
        this.renderer.removeClass(this.dropbutton.nativeElement.parentNode, "active");
      } else {
        this.renderer.setAttribute(this.dropbutton.nativeElement, "aria-expanded", "true");
        this.renderer.addClass(this.dropbutton.nativeElement.parentNode, "active");
      }

      this.cdr.detectChanges();

    });

    // server-side search
    fromEvent(this.dropdownsearchfield.nativeElement, 'keyup')
    .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        debounceTime(150),
        distinctUntilChanged(),
        tap(async (event:KeyboardEvent) => {
          
          const str: string = (this.dropdownsearchfield.nativeElement.value as string);
          
          if (str.length === 0) 
          {
            this.initValues();
          } 
          else 
          {
            this.currentValues = [...this.currentValuesBackup];
            this.currentValues = this.currentValues.filter(v => v.label.toLocaleLowerCase().indexOf(str.toLocaleLowerCase()) > -1);
            this.cdr.detectChanges();
          }

        })
    )
    .subscribe();

  }

  protected initValues(): void {

    this.currentValues = [];
    this.values.forEach(v => {
      this.currentValues.push({
        value: v[this.options.keys.value],
        label: v[this.options.keys.label],
        image: v[this.options.keys.image],
        uuid: StaticService.createUUIDv4()
      });
    });

    this.currentValuesBackup = [...this.currentValues];
    this.cdr.detectChanges();

  }

  protected initOptions(): void {

    const customSelect = (this.dropbutton.nativeElement.parentNode as HTMLElement); 
    const customSelectValue = (this.dropdownvalue.nativeElement as HTMLElement);
    const customSelectOptionsList = this.dropdown.nativeElement.querySelectorAll("li"); // document.querySelectorAll(".select-dropdown li");
    const self = this;

    customSelectOptionsList.forEach((option) => {

      function handler(event: any) {
        
        // Click Events
        if (event.type === "click" && event.clientX !== 0 && event.clientY !== 0) {
          
          customSelectValue.textContent = this.children[1].textContent;
          customSelectValue.setAttribute('data-value', this.children[1].getAttribute('data-value'));
          customSelectValue.setAttribute('data-label', this.children[1].getAttribute('data-label'));

          customSelect.classList.remove("active");
          self.selected.emit(this.children[1].getAttribute('data-value'));
        }

        // Key Events
        if (event.key === "Enter") {
          
          customSelectValue.textContent = this.textContent;
          customSelectValue.setAttribute('data-value', this.getAttribute('data-value'));
          customSelectValue.setAttribute('data-label', this.getAttribute('data-label'));

          customSelect.classList.remove("active");
          self.selected.emit(this.getAttribute('data-value'));
        }

      }

      option.removeAllListeners();
      option.addEventListener("keyup", handler);
      option.addEventListener("click", handler);

    });

  }

}
