
import { HttpErrorResponse, HttpEvent, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, finalize, retry, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CoreService } from '../service/core.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private router: Router, private coreService: CoreService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
               
        const started = Date.now();
        let ok: string;

        return next.handle(req).pipe(
            tap((event) => {
                ok = event instanceof HttpResponse ? 'succeeded' : '';                
                ok = event instanceof HttpErrorResponse ? "failed" : ok;
            }),
            catchError((error: HttpEvent<any>) => {
              
                if (error instanceof HttpErrorResponse) {

                    if (error.status === 401 && this.coreService.canAuthAndIntercept()) {
                        // töröljük a tokent amennyiben van
                        const token = localStorage.removeItem(environment.storage.token);
                        
                        // kiírunk egy debug üzenetet
                        if (environment.debug) {
                            console.log('[DEBUG] Token Error');
                            console.log('[DEBUG] ResponseInterceptor Error:', error.message);
                        }

                        let currentAuthRedirects = parseInt(localStorage.getItem('scr-ng-auth-retries'));

                        if (isNaN(currentAuthRedirects))
                            currentAuthRedirects = 0;

                        currentAuthRedirects++;

                        // számoljuk, hogy hány redirectünk volt (nem engedjük végtelen loopba a rendszert)
                        localStorage.setItem('scr-ng-auth-retries', currentAuthRedirects.toString());
                        
                        if (currentAuthRedirects < 3) {
                            // átirányítjuk az usert az auth oldalra
                            this.router.navigate(['/auth/login']);
                        } else {
                            // átirányítjuk az usert az error oldalra
                            this.router.navigate(['/auth/error']);
                        }

                    } 
    
                    if (error.status !== 403) {
    
                        // kiírunk egy debug üzenetet
                        if (environment.debug) {
                            console.log('[DEBUG] ResponseInterceptor Error:', error.message);
                        }
                        
                    } 

                }

              return throwError(() => error);
            }),
            // Log when response observable either completes or errors
            finalize(() => {
                if (environment.profiler) {
                    const elapsed = Date.now() - started;
                    const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
                    console.log(msg);
                }
            })
          );

    }
}
