import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, Subject, interval, of, pipe, timer } from 'rxjs';
import { map, repeat, retry, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CoreResponse, EntityService } from '@ratkaiga/core-nextgen';
import { TokenEntity } from '../entities/token.entity';

// auth0 jwt lib import
import { JwtHelperService } from '@auth0/angular-jwt';

// auth importok
import {
  OAuth2UserProfile,
  OAuth2UserProfileExtensions
} from '../entities/auth';

import { CoreService } from './core.service';
import { Nyomda, OAuth2Client, OAuth2User } from '../entities/scroll';
import { AppLog } from '../util/log.util';
import { StaticService } from './static.service';

export interface ITokenData {
  aud: number;
  exp: number;
  iat: number;
  id: string;
  iss: string;
  jti: string;
  scope: string;
  sub: number;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected profileUrl: string;

  public user$: BehaviorSubject<OAuth2UserProfile> = new BehaviorSubject(null);

  // ha a felhasználónak több auth client csatlakozása is lenne, akkor ebben a subjectben tároljuk
  public clients$: BehaviorSubject<OAuth2Client[]> = new BehaviorSubject([]);

  private expires$: BehaviorSubject<number> = new BehaviorSubject(0);
  public expiresObservable = this.expires$.asObservable();
  public stopInterval$ = new Subject<void>();

  public isFirstLoading: boolean = true;
  public isLoading$: Observable<boolean>;

  constructor(private coreService: CoreService, private entityService: EntityService, private http: HttpClient, private router: Router) {     
    this.profileUrl = (environment.endpoints.options.profile === 'auth') ? environment.endpoints.auth : environment.endpoints.app;
    this.reload();        
  } 

  reload(): void {
    
    const token = localStorage.getItem(environment.storage.token);
    
    if (token && this.coreService.canAuthAndIntercept()) {    
      
      AppLog.log('[DEBUG] Reloading and fetching user profile');

      // TODO kiszedni majd ezt, de mivel nincs fent productionban az új support ezért lehetetlen lenne beloginolni!
      // TODO a switchMap-os megoldás marad élesítés után!
      if (environment.production) {

        this.getUserProfile().subscribe((u: CoreResponse) => {

          if (u instanceof CoreResponse) {

            // OAuth user profile objektumot fogunk visszakapni
            const profile = (u.getData<OAuth2UserProfile>() as OAuth2UserProfile);

            // logolunk
            AppLog.log('Reload completed, setting profile object', profile);

            // seteljük az aktuális munkamenetben a behaviorsubjectünknek
            this.setCurrentUserProfile(profile);

          }

        });

      } else {
        
        this.getUserProfile().pipe(switchMap(extensions =>         
            this.getUserDetails().pipe(
              map(profile => [extensions, profile]
            )
          )
        )).subscribe((u: CoreResponse[]) => {

          if (u[0] instanceof CoreResponse && u[1] instanceof CoreResponse) {

            // OAuth user profile objektumot fogunk visszakapni
            const profile = (u[0].getData<OAuth2UserProfile>() as OAuth2UserProfile);

            // létrehozunk egy extension osztályt
            const extension = new OAuth2UserProfileExtensions();

            // OAuth kiegészítések objektumát kapjuk vissza (nyomda, user)
            const ext = (u[1].getData<[]>() as unknown[])[0];

            // seteljük a felhasználó hatóköreit, ezt a tokenből is tudjuk már
            extension.setScopesArray(this.getUserCurrentScopes());

            // seteljük a felhasználóhoz lekérdezett objektumokat
            extension
              .setNyomda(ext['nyomda'] as Nyomda)
              .setUser(ext['user'] as OAuth2User);

            profile.setProfileExtension(extension);

            AppLog.log('Reload completed, setting profile object', profile);

            // seteljük az aktuális munkamenetben a behaviorsubjectünknek
            this.setCurrentUserProfile(profile);

          }

        });

      }

    }

  }

  count(): void {
    of(0).pipe(repeat({ delay: 1000 }), takeUntil(this.stopInterval$)).subscribe((t) => {
      const date = new Date(); this.expires$.next(AuthService.getTokenExpires() - date.getTime());
    });
  }

  logout(): void {
    // ha nem tölti újra az oldalt csak kilép, akkor ezt a flag-et be kell állítsuk.
    this.isFirstLoading = true;
    // a tokent mindenképpen töröljük mert az a cél, hogy teljesen új tokennel menjen a felhasználó
    localStorage.removeItem(environment.storage.token);
    // reseteljük az user-t, mert különben a login komponensben a redirectelés végtelen loopba kerül
    this.user$.next(null);
  }

  login(username: string, password: string, client?: number): Observable<TokenEntity> {

    let params = new HttpParams();
    params = params.set('username', username);
    params = params.set('password', password);
    params = params.set('grant_type', 'password');

    if (client) {
      params = params.set('client_id', client.toString());
    }

    return this.http.post(`${environment.endpoints.auth}/auth/login/v3`, params).pipe(
      map(t => new TokenEntity().setTokenEntity(t))
    );
  }

  /**
   * Speciális lekérdezés a felhasználó authorizációs adataihoz társított oAuth2 kliensek listázásához
   * 
   * @todo Istvánnal átbeszélni egy flat struktúrát, mert túl sok a visszajövő adat, csak a kliens id-t 
   * és a nyomdanevet használjuk innen, a többi felesleges!
   * 
   * @param email 
   * @param password 
   */
  fetchClients(email: string, password: string): void {

    let params: HttpParams = new HttpParams();
    params = params.set('filter[email]', StaticService.encodeString(email));
    params = params.set('filter[password]', StaticService.encodeString(password));

    this.http.post<CoreResponse>(`${environment.endpoints.auth}/custom/oauth2users/list`, params, {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    }).pipe(
      map((m) => this.entityService.parse(m).getResponse())
    ).subscribe((r: CoreResponse) => {

      let items = r.getData<OAuth2User[]>() as unknown[] as OAuth2User[];
      let clients: OAuth2Client[] = [];

      items.forEach(item => {
        if (item instanceof OAuth2User) {
          clients.push(item.getClient());
        }
      })

      this.clients$.next(clients);

    });

  }

  /**
   * Ellenőrizzük, hogy a felhasználó tokenjében megvannak -e azok a scopeok, amelyek 
   * az oldal, erőforrás, stb. betöltéséhez kellenek
   * 
   * @param scopes 
   */
  isUserScopesAvailable(scopes: string[]): boolean {

    let ret = true;
    const availableScopes = AuthService.getCurrentDecodedToken()?.scope.split(' ');
    scopes.forEach((scope: string) => {
      if (!availableScopes.includes(scope)) {
        ret = false;
      }
    });

    return ret;
  }

  /**
   * Statikus helper funkció a scope ellenőrzéshez, a localstorageben tárolt tokenen végzünk el ellenőrzést.
   * 
   * @param scopes 
   * @returns 
   */
  static isScopeAvailable(scopes: string[]): boolean {

    let ret = true;
    const availableScopes = AuthService.getCurrentDecodedToken()?.scope.split(' ');
    scopes.forEach((scope: string) => {
      if (!availableScopes.includes(scope)) {
        ret = false;
      }
    });

    return ret;
  }

  /**
   * Statikus helper funkció a scope ellenőrzéshez, akkor adunk vissza true értéket ha a scope NINCS 
   * meg a felhasználó scope-k között. 
   * 
   * @param scope 
   * @returns 
   */
  static isScopeUnavailable(scope: string): boolean {
    return (AuthService.getCurrentDecodedToken()?.scope.split(' ').includes(scope)) ? false : true;
  }

  /**
   * Kényelmi funkció az aktuális token feldolgozásához
   */
  static getCurrentDecodedToken(): ITokenData {

    const token = localStorage.getItem(environment.storage.token);    
    const helper = new JwtHelperService();

    return helper.decodeToken(token) as unknown as ITokenData;
  }

  /**
   * Kényelmi funkció a token expirációs idejének eléréséhez
   * @returns 
   */
  static getTokenExpires(): number {
    return AuthService.getCurrentDecodedToken()?.exp * 1000;
  }

  /**
   * Kényelmi funkció a felhasználóhoz regisztrált scopeok eléréséhez (token alapján)
   * 
   * @returns 
   */
  getUserCurrentScopes(): string[] {
    return AuthService.getCurrentDecodedToken()?.scope.split(' ');
  }

  getUserProfile(): Observable<CoreResponse> {
    return this.http.get(`${this.profileUrl}/profile/user`).pipe(
      map((m) => this.entityService.parse(m).getResponse())
    );
  }

  /**
   * Kényelmi metódus a felhasználó kiegészítő adatainak lekérdezéséhez,
   * OAuth2UserProfile kiegészítéséhez használjuk
   *  
   * @returns 
   */
  getUserDetails(): Observable<CoreResponse> {
    return this.http.get(`${environment.endpoints.support}/ngpreload/details`).pipe(
      map((m) => this.entityService.parse(m).getResponse())
    );
  }

  setCurrentUserProfile(user: OAuth2UserProfile) {
      this.user$.next(user);
  }

  getCurrentUserProfile(): OAuth2UserProfile {
    return this.user$.getValue();
  }

  currentUser(): OAuth2UserProfile {
    return this.user$.value;
  }

  purgeToken(): void {
    localStorage.removeItem(environment.storage.token);
  }

  getToken(): string {
    return localStorage.getItem(environment.storage.token);
  }

  /**
   * Feltöltünk egy felhasználói profilképet
   * 
   * @param params 
   * @returns 
   */
  uploadUserProfileImage(params: FormData): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.auth}/profile/image`, params).pipe(
      map((p) => this.entityService.parse(p).getResponse())
    );
  }

  /**
   * Mentjük a dev felhasználó egyedi beállításait
   * 
   * @param params 
   * @returns 
   */
  saveSettingsDev(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/nguser/settings-dev`, params).pipe(
      map((p) => this.entityService.parse(p).getResponse())
    );
  }

  /**
   * Mentjük a felhasználó egyedi beállításait
   * 
   * @param params 
   * @returns 
   */
  saveSettings(params: HttpParams): Observable<CoreResponse> {
    return this.http.post(`${environment.endpoints.support}/nguser/settings`, params).pipe(
      map((p) => this.entityService.parse(p).getResponse())
    );
  }

}
