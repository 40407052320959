import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IContextMenu } from 'src/app/_core/interfaces/context-menu.interface';

@Component({
  selector: 'spirit-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {

  @Input()
  contextMenuItems: Array<IContextMenu>;

  @Output()
  onContextMenuItemClick: EventEmitter<{event: Event, data: IContextMenu}> = new EventEmitter<{event: Event, data: IContextMenu}>();

  onContextMenuClick(event: Event, data: IContextMenu): any {
    this.onContextMenuItemClick.emit({
      event,
      data,
    });
  }

}
