import { Entities } from '@ratkaiga/core-nextgen';
import { IssueCounter, Nyomda, OAuth2User } from '../scroll';
import { ISupportWatchedIssues } from '../../interfaces/support.interfaces';
import { OAuth2UserProfileExtensions } from './user.profile-extension';

export class OAuth2UserProfile extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Auth\\Entity\\OAuth2UserProfile';

    /**
     * A felhasználóhoz tartozó OAuth azonosító
     * @var number
     */
    user_id: number;

    /**
     * A felhasználóhoz tartozó OAuth kliens azonosító
     * @var number
     */
    client_id: number;

    /**
     * A felhasználóhoz tartozó nyomda azonosító
     * @var number
     */
    nyomda_id: number;

    /**
     * A felhasználóhoz tartozó nyomda név
     * @var string
     */
    nyomda_nev: string;

    /**
     * A felhasználó e-mail címe
     * @var string
     */
    email: string;

    /**
     * A felhasználó neve
     * @var string
     */
    display_name: string;

    /**
     * A felhasználó utolsó belépésének dátuma
     * @var string
     */
    last_seen_at: string;

    /**
     * A felhasználó paraméterei (json-ban tárolt egyedi paraméterek)
     * @var string
     */
    parameters?: unknown;

    /**
     * A felhasználó elsődleges telefonszáma
     * @var string
     */
    phone_number?: string;

    /**
     * A felhasználó telefonszám ellenőrzésének eredménye
     * @var boolean
     */
    phone_verified: boolean = false;

    /**
     * A felhasználó profilképe
     * @var string
     */
    avatar: 'default.png' | 'blank-user.jpg' | string = 'blank-user.jpg';

    /**
     * @var unknown[];
     */
    custom_data: unknown[];

    /**
     * @var OAuth2UserProfileExtensions
     */
    extension: OAuth2UserProfileExtensions;

    constructor() {
        super();
        this.extension = new OAuth2UserProfileExtensions();
    }

    /**
     * A felhasználóhoz tartozó OAuth azonosító
     * @returns
     */
    getUserId(): number {
        return this.user_id;
    }

    /**
     * A felhasználóhoz tartozó OAuth kliens azonosító
     * @returns 
     */
    getClientId(): number {
        return this.client_id;
    }

    /**
     * A felhasználóhoz tartozó nyomda azonosító
     * @returns 
     */
    getNyomdaId(): number {
        return this.nyomda_id;
    }

    /**
     * A felhasználóhoz tartozó nyomda név
     * @returns 
     */
    getNyomdaNev(): string {
        return this.nyomda_nev;
    }

    getEmail(): string {
        return this.email;
    }

    getPhoneNumber(): string {
        return this.phone_number;
    }

    isPhoneNumberVerified(): boolean {
        return this.phone_verified;
    }

    getDisplayName(): string {
        return this.display_name;
    }

    getLastSeen(): Date {
        return new Date(this.last_seen_at);
    }

    getAvatar(): string {
        return this.avatar;
    }

    isAvatar(): boolean {
        return !(this.avatar === 'default.png' || this.avatar === 'blank-user.jpg');
    }

    setProfileExtension(extension: OAuth2UserProfileExtensions): OAuth2UserProfile {
        this.extension = extension;
        return this;
    }

    getProfileExtension(): OAuth2UserProfileExtensions {
        return this.extension;
    }

    /**
     * Kényelmi metódus a felhasználó megjelenítendő név kezdőbetüinek lekérdezéséhez
     * @returns 
     */
    getNameAbbr(): string {
        return [this.extension.user?.getLastName().substring(0, 1), this.extension.user?.getFirstName().substring(0, 1)].join('');
    }

    /**
     * Kényelmi metódus a felhasználó dev jogosultságának lekérdezéséhez
     * @returns 
     */
    isDeveloper(): boolean {        
        return this.extension.user?.hasScope('dev:dev') || this.extension.scopes?.includes('dev:dev');
    }

    /**
     * Kényelmi metódus a felhasználó adott jogosultságának lekérdezéséhez
     * @returns 
     */
    isScopeAvailable(required: string): boolean {
        return this.extension.user?.hasScope(required) || this.extension.scopes?.includes(required);
    }

    getParameters(): unknown {
        try {
            return JSON.parse(this.parameters?.toString());
        } catch (e) {
            return undefined;
        }
    }

}
