import { Injectable } from '@angular/core';

import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { IPusherEvent, IPusherMessage, PusherChannels, PusherEvent } from '../interfaces/pusher.interfaces';
import { AppLog } from '../util/log.util';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  alive: boolean = false;

  pusher: any;

  private pusherEvents: BehaviorSubject<IPusherEvent[]> = new BehaviorSubject([]);

  public events = this.pusherEvents.asObservable();

  // TODO pre-koncepció: apinként tárolnánk az event üzeneteket?? esetleg mondjuk lehetne event típusonként is
  // public messageApiSubject: BehaviorSubject<IPusherMessage[]> = new BehaviorSubject([]);
  
  constructor() { 

    try {
      
      this.pusher = new Pusher(
        environment.app.params.pusher.key,
        {
          cluster: 'eu'
        }
      );

      this.alive = true;

    } catch (e) {
      AppLog.log('Pusher connection failed because unknown error occured', [e.message]);
    }

  }

  initService(): void {

    try {
      this.pusher.connection.bind("connected", this.connectedExecute());
    } catch(e) {
      AppLog.log('Pusher event bind failed because unknown error occured', [e.message]);
    }
    
  }

  /**
   * Minden központi bindelésünket itt kezeljük, mivel az app szinten "perzisztált" 
   * behavior subjecteket is itt kezeljük.
   */
  connectedExecute(): void {
    
    if (environment.debug)
      console.log('[DEBUG][Pusher] Instance connected');

    this.pusher.subscribe('message-api');

    this.pusher.allChannels().forEach(channel => {
      
      if (environment.debug)
        console.log('[DEBUG][Pusher] Channel subscription: ' + channel.name);

    });

    this.bindMessageAPI();
  }

  /**
   * Minden központi bindelésünket itt kezeljük, mivel az app szinten "perzisztált" 
   * behavior subjecteket is itt kezeljük.
   */
  bindMessageAPI(): void {

      // message api általános bind, amennyiben találunk ilyen channelt
      if (this.pusher.allChannels().find(c => c.name === PusherChannels.MESSAGE_API)) {

        const channel = this.pusher.allChannels().find(c => c.name === PusherChannels.MESSAGE_API); 
        channel.bind('general', (data: IPusherMessage) => {      
          
          let eventList = this.pusherEvents.value as unknown[] as IPusherEvent[];  
          eventList.push(
            (new PusherEvent)
              .setChannel(PusherChannels.MESSAGE_API)
              .setDateTime(data.datetime)
              .setMessage(data)
          );

          this.pusherEvents.next(eventList);                 
        });
  
      }
  }

  /**
   * Kényelmi funkció, ami visszaadja nekünk az argumentumban kapott channel esemény listáját
   * 
   * @param channel 
   * @returns 
   */
  getChannelEvents(channel: string): IPusherEvent[] {
    return this.pusherEvents.value.filter(c => c.channel === channel);
  }

  /**
   * Kényelmi funkció, ami visszaadja nekünk a teljes behavior subject tartalmat
   * 
   * @returns 
   */
  getCurrentEvents(): IPusherEvent[] {
    return this.pusherEvents.value;
  }

  getEvents(): BehaviorSubject<IPusherEvent[]> {
    return this.pusherEvents;
  }

}
