import { Entities, CoreResponseData, MetaArray } from '@ratkaiga/core-nextgen';
import { IssueGroup } from './support-issue-group.entity';
import { IssueModule } from './support-module.entity';
import { IssueSchema } from './support-shema.entity';
import { IssueSLAType } from './support-sla-type.entity';
import { SupportUserProfile } from './support-user-profile.entity';
import { ISupportWatchedIssues } from 'src/app/_core/interfaces/support.interfaces';
import { IssueCounter } from './support-counter.entity';
import { OAuth2UserProfile } from '../../auth';
import { SprintGroup } from './support-sprint-group.entity';

export class SupportPreload extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgPreload';

    slatypes: IssueSLAType[] = [];

    schemas: IssueSchema[] = [];

    modules: IssueModule[] = [];

    severities: string[] = [];

    groups: IssueGroup[] = [];

    sprintgroups: SprintGroup[] = [];

    profile: OAuth2UserProfile;

    watchedIssues: ISupportWatchedIssues[] = [];

    counters: IssueCounter;

    getSLATypes(): IssueSLAType[] {
        return this.slatypes;
    }

    getSchemas(): IssueSchema[] {
        return this.schemas;
    }

    getModules(): IssueModule[] {
        return this.modules;
    }

    getSeverities(): string[] {
        return this.severities;
    }

    /**
     * Setter a sprintgroup objektumokhoz
     * @param sprintgroups 
     * @returns 
     */
    setSprintGroups(sprintgroups: SprintGroup[] | MetaArray<SprintGroup>): SupportPreload {
        this.sprintgroups = sprintgroups;
        return this;
    }

    /**
     * Getter a sprintgroup objektumokhoz
     * @returns 
     */
    getSprintGroups(): SprintGroup[] | MetaArray<SprintGroup> {
        return this.sprintgroups;
    }

    getGroups(): IssueGroup[] | MetaArray<IssueGroup> {
        return this.groups;
    }
    
    getProfile(): OAuth2UserProfile {
        return this.profile;
    }

    getWatechedIssues(): ISupportWatchedIssues[] {
        return this.watchedIssues;
    }

    getCounters(): IssueCounter {
        return this.counters;
    }
}