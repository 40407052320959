import { Directive, Injectable, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Directive({
  selector: '[requiredScopes]'
})
export class ScopeDirective {

  private hasView: boolean = false;

  @Input() set requiredScopes(scopes: string[]) {
    
    if (this.hasAvailable(scopes) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.hasAvailable(scopes) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) {}


  private hasAvailable(scopes: string[]): boolean {

    const availableScopes: string[] = this.authService.getUserCurrentScopes();

    let passed = false;
    scopes.forEach(requiredScope => {
      if (availableScopes?.includes(requiredScope)) {
        passed = true;
      }
    });

    return passed;
  }

}
