import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { environment } from 'src/environments/environment';
import { ConfigProjectEnum } from '../configs/config.environment';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

        switch (route.routeConfig.path) {
            case 'support':
                return (environment.app.project === ConfigProjectEnum.MYSCROLL);
                break;          
            case 'scroll':
                return (environment.app.project === ConfigProjectEnum.MYSCROLL);
                break;
            case 'cee':
                return (environment.app.project === ConfigProjectEnum.CEE);
                break;
            case 'spirit':
                return true;
                break;
        }

    return true;
  }
  
}
