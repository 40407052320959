import { ICoreDictionary } from "../interfaces/core.interfaces";

export class CoreDictionary implements ICoreDictionary {

    key: string;

    value: unknown;

    setKey(value: string): CoreDictionary {
        this.key = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    setValue(value: unknown): CoreDictionary {
        this.value = value;
        return this;
    }

    getValue(): unknown {
        return this.value;
    }
}
