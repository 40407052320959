import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { ScrollService } from '../service/scroll.service';

@Pipe({
  name: 'scopeHas'
})
export class ScopeHasPipe implements PipeTransform {

    constructor() {}

    transform(value?: string[], scope?: string): boolean {
        return (value) ? value.includes(scope) : false;
    }

}