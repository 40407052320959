import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import AssetExplorerCommand from './asset-explorer.command';

export default class AssetExplorerPlugin extends Plugin {

    destroy() {

        // ha destroy van, akkor az event listenert is el kellene távolítani, hogy ne lógjon a levegőben
        this.editor.sourceElement.removeEventListener('ckInsertAssetExplorerContent', (e) => {});

        // az eredeti plugin destroy-t is meghívjuk, biztos ami biztos
        super.destroy();
    }

    init() {       

        // elkapjuk az editor instance-t
        var editor = this.editor;

        // regisztráljuk a commandok közé az insertImage parancsunkat
        editor.commands.add('insertImage', new AssetExplorerCommand(this.editor));       

        editor.sourceElement.addEventListener('ckInsertAssetExplorerContent', (e) => {
            console.log(e);
            if (this.editor.id === e.detail.id) {

                // @see https://stackoverflow.com/questions/47611200/how-to-programatically-insert-link-at-current-position-in-ckeditor-5
                // Editor model change callbackot használjuk, ha be kell szúrni valamit a tartalomba

                const selectedElement = editor.model.document.selection.getSelectedElement();

                if ( this.isImage( selectedElement ) ) {
                    console.log('Editor model change [insertImage through writer]', e.detail.url, selectedElement, editor);
                    editor.model.change( writer => {
                        writer.setAttribute( 'src', e.detail.url, selectedElement );
                        writer.removeAttribute( 'srcset', selectedElement );
                        writer.removeAttribute( 'sizes', selectedElement );
                    } );
                } else {

                    editor.model.change( writer => {
                        const imageElement = writer.createElement( 'image', {
                            src: e.detail.url
                        } );
    
                        // Insert the image in the current selection location.
                        editor.model.insertContent( imageElement, e.detail.source.cursor_position );
                    } );

                    console.log('Editor execute [insertImage]', e.detail.url, editor.id);  
                    this.editor.execute( 'insertImage', { source: e.detail.url } )              
                    // console.log(this.editor.execute( 'insertImage', { source: e.detail.url } ));
                }

            }             
        });

        editor.ui.componentFactory.add('assetExplorer', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Scroll Image Explorer',
                icon: imageIcon,
                tooltip: true,
                class: 'asset-button'
            });

            view.on('execute', (e) => {

                var event = new CustomEvent("open-asset-explorer-popup", {'detail': {
                    id: this.editor.id,
                    cursor_position: editor.model.document.selection.getFirstPosition(),
                    cursor_insertion_mode: 'embedd'
                }});

                editor.sourceElement.dispatchEvent(event);                
            });

            return view;
        });

    }
    
    /**
     * Checks if the provided model element is an `image`.
     *
     * @param {module:engine/model/element~Element} modelElement
     * @returns {Boolean}
     */
    isImage ( modelElement ) {
        return !!modelElement && modelElement.is( 'element', 'image' );
    }

    /**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'AssetExplorerPlugin';
	}
}