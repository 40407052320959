import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Pipe({
  name: 'scopeUnavailable'
})
export class ScopeUnavailablePipe implements PipeTransform {

    constructor(private authService: AuthService) {}

    transform(value?: string, ...args: unknown[]): boolean {
        return (value) ? AuthService.isScopeUnavailable(value) : true;
    }

}