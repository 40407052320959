import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocalstorageService } from 'src/app/_core/service/localstorage.service';

@Component({
  selector: 'spirit-hint-card',
  templateUrl: './hint-card.component.html',
  styleUrls: ['./hint-card.component.scss']
})
export class HintCardComponent implements OnInit, OnChanges {

  // megjeleníthető-e a hint component
  showHint: boolean = false;

  @Input() key: string;
  @Input() title: string;
  @Input() content: string;

  constructor(private storageService: LocalstorageService) { }

  ngOnInit(): void {
    if (this.key) {
      this.showHint = this.storageService.hasHint(this.key);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.key) {
      this.showHint = this.storageService.hasHint(this.key);
    }
  }

  onCloseCard(event: MouseEvent): void {
    this.storageService.changeHint(this.key, false);
    this.showHint = false;
  }

}
