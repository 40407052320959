import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { CoreService } from 'src/app/_core/service/core.service';
import { ScrollService } from 'src/app/_core/service/scroll.service';
import { CollectionMetadata, CoreRequestFilterModel, CoreRequestParams, CoreResponse, Entities } from '@ratkaiga/core-nextgen';
import { ScrollFile } from 'src/app/_core/entities/scroll';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-editor-explorer',
  templateUrl: './editor-explorer.component.html',
  styleUrls: ['./editor-explorer.component.scss']
})
export class EditorExplorerComponent implements OnInit {

  fileList: { url: string, remarks: string, hash: string }[];
  fileSelected: string;

  @ViewChild('previewImage') previewImageElement: ElementRef;

  editorForm: FormGroup;

  explorerFilter: FormGroup;
  explorerFilterOptions = [];
  explorerParams: CoreRequestParams;

  explorerMeta: Subject<CollectionMetadata> = new Subject();

  explorerInsert: boolean = false;

  /**
   * A localConfig objektumban tudjuk deklarálni az alapértelmezetten ismert applikációókat és azok entitásait. 
   * Amennyiben, az inputban érkező űrlapünk hidden mezőinek értéke null, akkor nem használjuk ezeket mert gyakorlatilag 
   * forceoljuk a névtereket.
   *  
   * @var {}
   */
  localConfig = {
    apps: [
      { name: 'ScrollMAX Wiki', value: 'ScrollMax\\ScrollmaxHu\\Wiki', enabled: true },
      { name: 'Scrollsupport v2', value: 'ScrollMax\\Scrollsupport', enabled: true }
    ],
    entities: [
      { name: 'CMS kép entitások', value: 'ScrollMax\\Cms\\Entity', enabled: true, relation: 'ScrollMax\\ScrollmaxHu\\Wiki' },
      { name: 'Issue entitások', value: 'ScrollMax\\Scrollsupport\\Entity\\Issue', enabled: true, relation: 'ScrollMax\\Scrollsupport' }
    ]
  };

  constructor(
    private scrollService: ScrollService, 
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.explorerFilterOptions = [];
    this.explorerFilterOptions.push(new CoreRequestFilterModel('owner_app', this.editorForm.controls.fileserviceApp.value ?? 'ScrollMax\\ScrollmaxHu\\Wiki'));
    this.explorerFilterOptions.push(new CoreRequestFilterModel('owner_entity', this.editorForm.controls.fileserviceEntity.value ?? 'ScrollMax\\Cms\\Entity'));
    // filters.push(new CoreRequestFilterModel('file_mime_type', 'image/jpeg'));

    if (this.editorForm.controls.fileserviceApp.value && this.editorForm.controls.fileserviceEntity.value) {
      
      this.localConfig.apps.forEach(item => {
        item.enabled = false;
      });
      this.localConfig.apps.push({name: 'Rögzített modul beállítás', value: this.editorForm.controls.fileserviceApp.value, enabled: true });

      this.localConfig.entities.forEach(item => {
        item.enabled = false;
      });
      this.localConfig.entities.push({name: 'Rögzített modul beállítás', value: this.editorForm.controls.fileserviceEntity.value, enabled: true, relation: this.editorForm.controls.fileserviceApp.value });

    }

    this.explorerFilter = new FormGroup({
      appSelector: new FormControl(this.editorForm.controls.fileserviceApp.value ?? ''),
      entitySelector: new FormControl(this.editorForm.controls.fileserviceEntity.value ?? ''),
      remarksText: new FormControl()
    });

    this.explorerParams = new CoreRequestParams(this.explorerFilterOptions);
    this.explorerParams.sortField = 'created_at';
    this.explorerParams.sortOrder = 'desc';
    this.explorerParams.pageNumber = 0;
    this.explorerParams.pageSize = 25;

    this.fetchComponentData(this.explorerParams.getQuery());
  }

  fetchComponentData(filter?: string): void {

    this.scrollService.fsGetFileEntities(filter).subscribe((r: CoreResponse) => {

      this.fileList = [];
      (r.getData<ScrollFile[]>() as unknown[] as ScrollFile[]).forEach((f) => {
        if (f instanceof ScrollFile) {
          this.fileList.push({
            url: environment.endpoints.fileservice + '/guest/download/' + (f as ScrollFile).file_request_hash_guest,
            remarks: (f as ScrollFile).remarks,
            hash: (f as ScrollFile).file_request_hash_guest
          });
        }
      });

      this.cdr.detectChanges();
    });

  }

  onCloseModal(): void {
    this.activeModal.dismiss();
  }

  onCloseModalWithInsert(): void {
    if (this.fileSelected) {
      this.activeModal.close({ file: this.fileSelected });
    }
  }


  /**
   * A kiválasztott képet megjelenítjük a betekintő nézetben, ide majd egy scroll logót kell fixen berakni alapértelmezésnek
   * 
   * @param url 
   */
  onShowPreview(url: string): void {
    this.fileSelected = url;
    this.previewImageElement.nativeElement.setAttribute('src', url);
  }

  /**
   * Valós időben szűrjük a related entity tömböt a legördülő menünek.
   * 
   * @param entities 
   * @returns 
   */
  onFilterEntities(entities: any): any {
    if (this.explorerFilter.controls.appSelector.value) {
      return entities.filter(a => a.relation === this.explorerFilter.controls.appSelector.value);
    }

    return entities;
  }

  /**
   * A beállításoknak megfelelően lekérdezzük az explorer számára a fileservice rekordokat.
   * 
   * @param reset 
   */
  onFetchEntities(reset = false): void {

    // csak akkor használjuk, ha applikációt is változtattunk, hiszen több entitás is lehetséges, tehát alaphelyzetbe 
    // kell állítanunk a legördülő menüt.
    if (reset === true) {
      this.explorerFilter.controls.entitySelector.setValue('');
    }

    this.explorerFilterOptions = [];
    if (this.explorerFilter.controls.appSelector.value) {
      this.explorerFilterOptions.push(new CoreRequestFilterModel('owner_app', this.explorerFilter.controls.appSelector.value));
    }

    if (this.explorerFilter.controls.entitySelector.value) {
      this.explorerFilterOptions.push(new CoreRequestFilterModel('owner_entity', this.explorerFilter.controls.entitySelector.value));
    }

    // az első oldalt forceoljuk (új lekérdezés) és beállítjuk a filtereket
    const params = new CoreRequestParams(this.explorerFilterOptions);
    params.pageNumber = 0;

    // minden mást visszaklónozunk a paramsba
    params.pageSize = this.explorerParams.pageSize;
    params.sortField = this.explorerParams.sortField;
    params.sortOrder = this.explorerParams.sortOrder;

    // visszaírjuk globálba
    this.explorerParams = params;

    // lekérdezünk
    this.fetchComponentData(this.explorerParams.getQuery());
  }

  paginateList(params: CoreRequestParams): void {

    this.explorerParams.pageNumber = params.pageNumber;
    this.explorerParams.pageSize = params.pageSize;

    this.fetchComponentData(this.explorerParams.getQuery());
  }

}
