import { Entities } from '@ratkaiga/core-nextgen';
import { Nyomda } from './nyomda.entity';

export class TransactionEmail extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Message\\Entity\\EMail';

    private transportList = [
        { key: 'keskenyhu', name: 'Keskeny SMTP' },
        { key: 'keskenyonline', name: 'Keskeny SMTP' },
        { key: 'printportal', name: 'Printportal Mailgun' },
        { key: 'default', name: 'Scrollmax.net Mailgun' },
        { key: 'scrollmaxnet', name: 'Scrollmax.net Mailgun' }
    ];

    dateSent: string;
    
    from_address: string;

    lastError: number;

    lastErrorMessage: string;

    messageType: string;

    nyomda: Nyomda = new Nyomda();

    recipient: string;

    status: 'SENT' | 'ERROR' | 'QUEUED';

    subject: string;

    transport: string;

    tracked: boolean;

    trackingStatus: string;

    getFrom(): string {
        return this.from_address;
    }

    getTo(): string {
        return this.recipient;
    }

    getSubject(): string {
        return this.subject;
    }

    getErrorCode(): number {
        return this.lastError;
    }

    getError(): string {
        return this.lastErrorMessage;
    }

    getNyomdaId(): number {
        return this.nyomda.id;
    }

    getNyomdaNev(): string {
        return this.nyomda.nev;
    }

    getStatus(): string {
        return this.status;
    }

    getSentAt(): string {
        return this.dateSent;
    }

    isError(): boolean {
        return (this.status === 'ERROR');
    }

    isTracked(): boolean {
        return (this.tracked) ? true : false;
    }

    getTransport(): string {
        try {
            return this.transportList.find(t => t.key === this.transport).name;
        } catch (e) {
            return this.transportList.find(t => t.key === 'default').name;
        }        
    }
}