import { Entities } from '@ratkaiga/core-nextgen';

export class OAuth2Client extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Auth\\Entity\\OAuth2Clients';

    /**
     * Nyomda ID, ezzel hivatkozunk minden nyomdára, mindenhol
     * 
     * @var number
     */
    nyomda_id: number;

    /**
     * Nyomda név, ez a nyomda teljes neve lesz 
     */
    nyomda_nev: string;

    constructor() {
        super();
    }

    getNyomdaId(): number {
        return this.nyomda_id;
    }

    getNyomdaNev(): string | null {
        return this.nyomda_nev;
    }

}