import { Injectable } from '@angular/core';
import { ScrollService } from '../scroll.service';
import { CoreRequestParams, CoreResponse, MetaArray } from '@ratkaiga/core-nextgen';
import { ICoreFileserviceQuote } from '../../interfaces/fileservice.interfaces';
import { BehaviorSubject } from 'rxjs';
import { Nyomda } from '../../entities/scroll';

@Injectable({
  providedIn: 'root'
})
export class DriveService {

  fileserviceQuoteSubject: BehaviorSubject<ICoreFileserviceQuote> = new BehaviorSubject(undefined);

  fileserviceCoreRequestParams: BehaviorSubject<CoreRequestParams> = new BehaviorSubject(undefined);

  fileserviceSelectedNyomda: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor(private scrollService: ScrollService) { }

  fetchDriveStats<T>(search: T): void {

    let filter = '';
    if (search && search.hasOwnProperty('nyomda_id'))
      filter = '?filter[nyomda_id]=' + search['nyomda_id'];

    // lekérjük a drive statokat
    this.scrollService.fsGetLimits(filter).subscribe({ 
      next: (response: CoreResponse) => {
        this.fileserviceQuoteSubject.next(response.getDataByIndex<MetaArray<ICoreFileserviceQuote>>());
      },
      error: (error: unknown) => {
        console.log(error);
      }
    });

  }
}
