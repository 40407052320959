import { Pipe, PipeTransform } from '@angular/core';
import { ScrollService } from '../service/scroll.service';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value?: number, ...args: unknown[]): unknown {
    const digit: number = (args.length > 0 && args[0].hasOwnProperty('digit')) ? Number(args[0]['digit']) : 2;
    return (value) ? ScrollService.convertBytesToHumanReadableFormat(value, true, digit) : 0;
  }

}
