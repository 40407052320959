import { Entities } from '@ratkaiga/core-nextgen';

export class IssueModule extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgModule';

    name: string;

    getName(): string {
        return this.name;
    }

}