import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { globalLayoutConfig } from './_core/configs/config.layout';
import { ThemeModeService } from './_metronic/partials/theme-mode-switcher/theme-mode.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  constructor(
    private title: Title, 
    private modeService: ThemeModeService,
    private toastrService: ToastrService,
    ) {
      title.setTitle(globalLayoutConfig.title);
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    this.modeService.init();
  }

}
