import { ISupportStatusOption } from "../../interfaces/support.interfaces";

export class SupportStatusOption implements ISupportStatusOption {

    main_type: string;
    
    current_status: string;
    
    available_status: string[];
    
    constructor(main_type: string, current_status: string, available_status: string[]) {
        this.main_type = main_type;
        this.current_status = current_status;
        this.available_status = available_status;
    }

    getMainType(): string {
        return this.main_type;
    }

    getCurrentStatus(): string {
        return this.current_status;
    }

    getAvailableStatuses(): string[] {
        return this.available_status;
    }
}