import { Entities } from '@ratkaiga/core-nextgen';
import { Issue } from './support-issue.entity';
import { SupportUserProfile } from './support-user-profile.entity';

export class IssueActor extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssueActor';

    id: number;

    issue: Issue;

    user: SupportUserProfile;

    role: string;

    getId(): number {
        return this.id;
    }

    getIssue(): Issue {
        return this.issue;
    }

    getUserProfile(): SupportUserProfile {
        return this.user;
    }

    getUserId(): number {
        return this.user.getId();
    }

    getUserName(): string {
        return this.user.getName();
    }

    getUserNameAbbr(): string {
        return this.user.getNameAbbr();
    }

    isAvatar(): boolean {
        return this.user.isAvatar();
    }

    getRole(): string {
        return this.role;
    }

    getRoleLabel(): string {
        return 'label_' + this.role;
    }

}