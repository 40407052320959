import { CUSTOM_ELEMENTS_SCHEMA, NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { CoreUIModule, PreloaderDirective } from '@ratkaiga/core-nextgen/ui';
import { DateformatPipe, DatetimeagoPipe, CoreDateTimeModule, CoreDateTimeService } from '@ratkaiga/core-nextgen/datetime';
import { TranslatePipe, CoreTranslateModule, CoreTranslateService, CORE_TRANSLATE_CONFIG_TOKEN } from '@ratkaiga/core-nextgen/translate';
import { environment } from 'src/environments/environment';

import { 
  CkeditorInstanceComponent,
  ContextMenuComponent,
  CoreTablePaginatorComponent,
  HintContentComponent,
  ToggleButtonAddRemoveComponent,
  ToggleButtonSearchComponent,
  CustomSelectComponent,
  CustomDatepickerComponent
} from '../layout/partials/common';

// common widgetek
import { 
  AlertCardComponent,
  AlertPanelComponent,
  FormValidationMessageWidgetComponent, 
  HintCardComponent,
  HintPanelComponent,
  ProgressButtonComponent
} from '../layout/widgets/common';

import { TagifyModule } from 'ngx-tagify'; 
import { Select2Module } from 'ng-select2-component';

import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { NyomdaNevPipe } from './pipes/nyomda-nev.pipe';
import { TruncatePipe } from './pipes/truncate-text.pipe';
import { ScopeAvailablePipe } from './pipes/scope-available.pipe';
import { ConfirmModalComponent, ConfirmTemplateDirective } from './service/confirm.service';
import { ScopeDirective } from './directives/scope.directive';
import { AbbrevationPipe } from './pipes/abbrevation.pipe';
import { FileHashPipe } from './pipes/file-hash.pipe';
import { SupportStatusColorPipe } from './pipes/support-status-color.pipe';
import { SupportPriorityIconPipe } from './pipes/support-priority-icon.pipe';
import { SupportStatusLabelPipe } from './pipes/support-status-label.pipe';
import { TableColumnDirective } from './directives/table-column.directive';
import { EditorComponent } from '../layout/partials/scroll/ck/editor/editor.component';
import { EditorExplorerComponent } from '../layout/partials/scroll/ck/editor-explorer/editor-explorer.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LoadingPipe } from './pipes/loading.pipe';
import { FileExtensionPipe } from './pipes/file-extension.pipe';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSortableModule } from 'ngx-sortable';
import { CoreTableSortstateComponent } from '../layout/partials/common/core-table-sortstate/core-table-sortstate.component';
import { ScopeUnavailablePipe } from './pipes/scope-unavailable.pipe';
import { ScopeUnavailableDirective } from './directives/scope.unavailable.directive';
import { DriveStatWidgetComponent } from '../modules/scroll/drive';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ScopeHasPipe } from './pipes/scope-has.pipe';
import { ScopeHasDirective } from './directives/scope.has.directive';
import { IssueLastChangedWidgetComponent, IssueLatestWidgetComponent, IssueOfferListWidgetComponent } from '../layout/widgets/scroll/support';
import { RouterModule } from '@angular/router';
import { IssueListItemComponent } from '../layout/partials/scroll/support';

@NgModule({
  declarations: [
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    
    CkeditorInstanceComponent,
    CoreTablePaginatorComponent,
    CoreTableSortstateComponent,
    ToggleButtonAddRemoveComponent,
    ToggleButtonSearchComponent,
    HintContentComponent,
    ContextMenuComponent,
    CustomSelectComponent,
    CustomDatepickerComponent,

    HintCardComponent,
    HintPanelComponent,
    AlertCardComponent,
    AlertPanelComponent,
    ProgressButtonComponent,
    FormValidationMessageWidgetComponent,

    EditorComponent,
    EditorExplorerComponent,

    // widgets
    DriveStatWidgetComponent,
    IssueLatestWidgetComponent,
    IssueLastChangedWidgetComponent,
    IssueOfferListWidgetComponent,

    // partials
    IssueListItemComponent,

    FileSizePipe,
    FileHashPipe,
    FileExtensionPipe,
    FirstLetterPipe,
    FirstLetterUppercasePipe,
    AbbrevationPipe,
    TruncatePipe,
    LoadingPipe,
    NyomdaNevPipe,
    ScopeAvailablePipe,
    ScopeUnavailablePipe,
    ScopeHasPipe,

    // használjuk a supporton kívül is
    SupportStatusLabelPipe,
    SupportStatusColorPipe,
    SupportPriorityIconPipe,

    TableColumnDirective,
    ScopeDirective,
    ScopeUnavailableDirective,
    ScopeHasDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    CoreDateTimeModule,
    CoreTranslateModule,
    CoreUIModule,

    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,

    NgApexchartsModule,
    NgbDatepickerModule,
    NgxSortableModule,

    Select2Module,
    TagifyModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    })
  ],
  exports: [
    ConfirmModalComponent,
    ConfirmTemplateDirective,

    CkeditorInstanceComponent,
    CoreTablePaginatorComponent,
    CoreTableSortstateComponent,
    ToggleButtonAddRemoveComponent,
    ToggleButtonSearchComponent,
    HintContentComponent,
    ContextMenuComponent,
    CustomSelectComponent,
    CustomDatepickerComponent,
    
    HintCardComponent,
    HintPanelComponent,
    AlertCardComponent,
    AlertPanelComponent,
    ProgressButtonComponent,
    FormValidationMessageWidgetComponent,
    
    EditorComponent,
    EditorExplorerComponent,

    // widgets
    DriveStatWidgetComponent,
    IssueLatestWidgetComponent,
    IssueLastChangedWidgetComponent,
    IssueOfferListWidgetComponent,

    // partials
    IssueListItemComponent,    

    TranslatePipe,
    DateformatPipe,
    DatetimeagoPipe,

    FileSizePipe,
    FileHashPipe,
    FileExtensionPipe,
    FirstLetterPipe,
    FirstLetterUppercasePipe,
    AbbrevationPipe,
    TruncatePipe,
    LoadingPipe,
    NyomdaNevPipe,
    ScopeAvailablePipe,
    ScopeUnavailablePipe,
    ScopeHasPipe,

    // használjuk a supporton kívül is
    SupportStatusLabelPipe,
    SupportStatusColorPipe,
    SupportPriorityIconPipe,

    PreloaderDirective,

    TableColumnDirective,
    ScopeDirective,
    ScopeUnavailableDirective,
    ScopeHasDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatepickerComponent),
      multi: true,
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CoreSharedModule {}
