import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';

// főmodul importok
import { TagifyModule } from 'ngx-tagify'; 
import { Select2Module } from 'ng-select2-component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ck module
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// routing és komponensek
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { EntityService } from '@ratkaiga/core-nextgen';
import { CoreTranslateModule, CoreTranslateService, CORE_TRANSLATE_CONFIG_TOKEN } from '@ratkaiga/core-nextgen/translate';

import { AuthService } from './_core/service/auth.service';
import { LayoutService } from './_core/service/layout.service';
import { AuthInterceptorProviders } from './_core/interceptors/token-interceptor';
import { ResponseInterceptor } from './_core/interceptors/response-interceptor';

// az app initializeren belül regisztrálunk, itt csak az entitás importokat végezzük

// scroll importok
import {
  CoreUserProfile,
  OAuth2UserProfile
} from './_core/entities/auth';

import {
  OAuth2User,
  OAuth2Client,
  Nyomda,
  TransactionEmailBlacklist,
  TransactionEmail,
  TransactionSMS,
  ScrollFile,
  FileserviceFolder,
  IssueSchema,
  IssueSLAType,
  IssueModule,
  SupportPreload,
  Issue,
  SupportUserProfile,
  Issueupdate,
  Issuechange,
  IssueGroup,
  TodoItem,
  PreSprint,
  SprintGroup,
  IssueCounter,
  SupportUserAutocomplete,
  DataPump,
  IssueActor,
  WatchlistUser,
  TransactionEmailQueue,
  Sprint,
  CmsContentToc,
  CmsContentTitle,
  IssueOffer,
  ScrollProgramType
} from './_core/entities/scroll';

import { 
  TranslateInstance, 
  TranslateNode, 
  TranslateProject
} from './_core/entities/spirit';

import { NumberEntity, StdClassEntity } from './_core/entities/common.entity';

import { environment } from 'src/environments/environment';
import { CoreSharedModule } from './_core/core-shared.module';
import { ConfigProjectEnum } from './_core/configs/config.environment';
import { getSaver, SAVER } from './_core/providers/saver.provider';
import { ToastrModule } from 'ngx-toastr';
import { LocalstorageService } from './_core/service/localstorage.service';
import { ConfirmService, ConfirmState } from './_core/service/confirm.service';
import { AppLog } from './_core/util/log.util';

import { ResponseInterceptorProviders } from './_core/interceptors/provider';
import { PusherService } from './_core/service/pusher.service';
import { ScrollSocketService } from './_core/service/scroll-socket.service';
import { TreeModule } from '@circlon/angular-tree-component';
import { ScrollService } from './_core/service/scroll.service';

function appInitializer(
  authService: AuthService, 
  entityService: EntityService, 
  pusherService: PusherService
  ) {

  return () => {
    return new Promise((resolve) => {

      // scroll auth registrations
      entityService.register(OAuth2UserProfile);
      entityService.register(CoreUserProfile);

      switch (environment.app.project) {         
        case ConfigProjectEnum.MYSCROLL:
          
          // myscroll
          entityService.register(Nyomda);
          entityService.register(OAuth2User);
          entityService.register(OAuth2Client);
          
          entityService.register(CmsContentTitle);
          entityService.register(CmsContentToc);

          entityService.register(TransactionEmailQueue);
          entityService.register(TransactionEmailBlacklist);
          entityService.register(TransactionEmail);
          entityService.register(TransactionSMS);

          entityService.register(DataPump);

          entityService.register(ScrollProgramType);

          entityService.register(ScrollFile);
          entityService.register(FileserviceFolder);

          entityService.register(Issue);
          entityService.register(IssueSchema);
          entityService.register(IssueSLAType);
          entityService.register(IssueModule);
          entityService.register(Issueupdate);
          entityService.register(Issuechange);
          entityService.register(IssueGroup);
          entityService.register(IssueCounter);
          entityService.register(IssueActor);
          entityService.register(IssueOffer);
          
          entityService.register(TodoItem);

          entityService.register(PreSprint);
          entityService.register(SprintGroup);
          entityService.register(Sprint);

          entityService.register(WatchlistUser);
          
          entityService.register(SupportUserAutocomplete)
          entityService.register(SupportUserProfile);
          entityService.register(SupportPreload);
          break;
      }

      entityService.register(NumberEntity);
      entityService.register(StdClassEntity);

      entityService.register(TranslateNode);
      entityService.register(TranslateInstance);
      entityService.register(TranslateProject);

      AppLog.log('Entities registered successfully', entityService.list());

      resolve(authService.getUserProfile().subscribe({ 
        complete: () => {
          AppLog.log('Trying to get user profile');
          return resolve;
        },
        error: () => {
          AppLog.log('User profile did not fetched, maybe a token issue');
          return resolve;
        }      
      }).add(function() {

        AppLog.log('Tearing down getUserProfile()');
      
        // pusher service initek
        try {
          
          // pusherService.initService(); 

          // rendszer szintű channelre csak dev:dev scope megléte esetén iratkozunk fel
          if (authService.isUserScopesAvailable(['dev:dev'])) {
            // pusherService.pusher.subscribe("message-api");
          }      

        } catch (e) {
          AppLog.log('Pusher service initialization failed', [e.message]);
        }                  

        return resolve;
      }));          
      
    }).catch(console.log);
  };
}

/**
 * Ezt a funkciót egy APP_INITIALIZER tokennel hívjuk, amely még a teljes inicializáció előtt meg fogja hívni 
 * a translate végpontot és betölti a projekthez aktuálisan beállított (environment-ben) nyelvi állományt.
 * 
 * @param translateService 
 */
 export function initializeTranslate(translateService: CoreTranslateService) {
  return (): Promise<any> => {
    return translateService.init();
  };
}

export function initEnvironment(): any {
  console.log('[DEBUG] Init environment');
  return environment;
}

import { registerLocaleData } from "@angular/common";
import localeHu from "@angular/common/locales/hu";
import localeHuExtra from "@angular/common/locales/extra/hu";
import localeEn from "@angular/common/locales/en";
import localeEnExtra from "@angular/common/locales/extra/en";

registerLocaleData(localeHu, "hu", localeHuExtra);
registerLocaleData(localeEn, "en", localeEnExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    CoreTranslateModule.forRoot({
      // tslint:disable-next-line:object-literal-shorthand
      environment: environment
    }),
    Select2Module,
    NgApexchartsModule,
    CKEditorModule,
    TagifyModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TreeModule,
    
    // ng bootstrap
    NgbModule,

    CoreSharedModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, EntityService, PusherService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslate,
      deps: [CoreTranslateService],
      multi: true
    },
    {
      provide: CORE_TRANSLATE_CONFIG_TOKEN,
      useFactory: initEnvironment
    },
    {
      provide: SAVER, 
      useFactory: getSaver
    },
    {
      provide: LOCALE_ID, 
      useValue: "hu_HU"
    },
    AuthInterceptorProviders,
    ResponseInterceptorProviders,
    ConfirmState,
    ConfirmService,
    LocalstorageService,
    LayoutService,
    AuthService,
    ScrollSocketService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
