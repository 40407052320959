import { Pipe, PipeTransform } from '@angular/core';
import { ISupportSeverity } from '../interfaces/support.interfaces';
import { ScrollSupportService } from '../service/scroll-support.service';

@Pipe({
  name: 'supportPriorityIcon'
})
export class SupportPriorityIconPipe implements PipeTransform {

  severityList: ISupportSeverity[] = ScrollSupportService.SeverityList;

  transform(value: unknown): unknown {

    if (typeof(value) === 'string') {
      return ['assets/media/issues', this.getPriorityIcon(value.toString())].join('/');
    } else {
      return ['assets/media/issues', this.getPriorityIconByLevel(value as number)].join('/');
    }
    
  }

  getPriorityIconByLevel(value: number): string {
    return this.severityList.find(r => r.level === value)?.icon ?? 'Trivial.png';
  }

  getPriorityIcon(value: string): string {
    return this.severityList.find(r => r.value === value)?.icon ?? 'Trivial.png';
  }

}
