import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spirit-issueupdate-close-interactive',
  templateUrl: './issueupdate-close-interactive.component.html',
  styleUrls: ['./issueupdate-close-interactive.component.scss']
})
export class IssueupdateCloseInteractiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
