import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Issue, Issuechange, Issueupdate } from 'src/app/_core/entities/scroll';
import { ScrollSupportService } from 'src/app/_core/service/scroll-support.service';
import { environment } from 'src/environments/environment';

import { CoreResponse, CoreRequestFilterModel, CoreRequestFilterOperations, CoreRequestParams } from '@ratkaiga/core-nextgen';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';

@Component({
  selector: 'spirit-issue-more-list-widget',
  templateUrl: './issue-more-list-widget.component.html',
  styleUrls: ['./issue-more-list-widget.component.scss']
})
export class IssueMoreListWidgetComponent implements OnInit, OnDestroy {

  intervalResourceID;

  issues: Issue[] = [];
  issueParams: CoreRequestParams;

  destroy$: Subject<void> = new Subject();

  constructor(
    private supportService: ScrollSupportService, 
    private translateService: CoreTranslateService,
    private cdr: ChangeDetectorRef
    ) { 

  }

  ngOnInit(): void {

    this.issueParams = new CoreRequestParams([]);
    this.issueParams.sortField = 'updated_at';
    this.issueParams.sortOrder = 'desc';
    this.issueParams.pageNumber = 0;
    this.issueParams.pageSize = 5;

    this.fetchIssueList();

    // időnként ránézünk a listára, ha esetleg a felhasználó áll az oldalon akkor 
    // a csatolt issue-ket időnként megújítjuk
    // this.intervalResourceID = setInterval(() => {
    //  this.fetchIssueList()
    // }, 86000);

  }

  ngOnDestroy(): void {

    // clearInterval(this.intervalResourceID);
    
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchIssueList(): void {

    console.log('fetch issue list')
    this.supportService.fetchIssue(this.issueParams.getQuery()).subscribe((r: CoreResponse) => {
      if (r.getData<Issue[]>()) {
        
        // megvannak az issuek. seteljük őket, hogy a sablonunk is tudjon róla
        this.issues = r.getData<Issue[]>() as unknown[] as Issue[];
        
        // mivel az entitás kívülről van linkelve, ezért seteljük a support service-t (preload dolgok miatt pl.)
        this.issues.forEach((issue: Issue) => {
          issue.setService(this.supportService, this.translateService);
        });

        this.cdr.detectChanges();
      
      }  
    }, (e: unknown) => {
      console.log(e);
      // ha bármilyen hibára futunk akkor lelőjük a poolingot. pl. lehet ilyen háttérben futó böngészőnél token 
      // error, ami nem lépteti ki a felhasználót a login oldalra.
      // clearInterval(this.intervalResourceID);
    });

  }

}
