import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'spirit-toggle-button-add-remove',
  templateUrl: './toggle-button-add-remove.component.html',
  styleUrls: ['./toggle-button-add-remove.component.scss']
})
export class ToggleButtonAddRemoveComponent {

  @Input() trigger = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();
  
  onToggle(): void {
    this.trigger = !this.trigger;
    this.valueChange.emit(this.trigger);
  }

}
