import { Entities } from '@ratkaiga/core-nextgen';
import { ScrollService } from '../../service/scroll.service';

export class ScrollFile extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Core\\Fileservice\\Entity\\ScrollFile';

    id: number;

    nyomda_id: number;

    file_name: string;

    file_size: number;

    file_mime_type: string;

    file_request_hash: string;

    file_request_hash_guest: string;

    file_ext: string;

    remarks: string;   

    getNyomdaId(): number {
        return this.nyomda_id;
    }

    getHash(): string {
        return this.file_request_hash;
    }

    getGuestHash(): string {
        return this.file_request_hash_guest;
    }

    getFQN(): string {
        return (this.file_name) ? this.file_name.concat('.', this.file_ext) : '';
    }

    getFileExtension(): string {
        return (this.file_ext) ? this.file_ext.toUpperCase() : '';
    }

    getFileMime(): string {
        return this.file_mime_type;
    }

    hasRemarks(): boolean {
        return (this.remarks) ? (this.remarks.length > 0) : false;
    }

    getRemarks(): string | null {
        return (this.remarks) ? this.remarks : '';
    }

    getFileSize(): number {
        return this.file_size;
    }

    getFileSizeHumanReadable(): string {
        return ScrollService.convertBytesToHumanReadableFormat(this.file_size, true, 2);
    }

}