import { Pipe, PipeTransform } from '@angular/core';
import { ScrollSupportService } from '../service/scroll-support.service';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';

@Pipe({
  name: 'supportStatusColor'
})
export class SupportStatusColorPipe implements PipeTransform {

    constructor(private supportService: ScrollSupportService) {}

    transform(value: string, ...args: unknown[]): string {
        return (args.length > 0) ? this.templateStatusColor(value, args[0]['prefix'] ?? '') : this.templateStatusColor(value);
    }

    /**
     * Visszakapjuk az aktuális stáuszhoz beállított bootstrap css osztályt. Prefixelve is 
     * használhatjuk, aminek tisztább kód az eredménye
     * 
     * @example getStatusColor('label-')
     * @example getStatusColor('text-)
     * @param prefix 
     */
    templateStatusColor(value: string, prefix: string = ''): string {

        switch(value) {
            case 'closed':
            case 'archived':
            case 'devlisted':
            case 'planning':
            case 'denied':                
            case 'legacy_belso_teszteles_ok':
            case 'legacy_belso_teszteles':
            case 'legacy_visszajelzes':
            case 'legacy_atadva':
            case 'offer_denied': // elutasítva 
            case 'unhandled': // csak sprint
                return [prefix, 'scroll-gray'].join('');

            case 'new':
            case 'open':
            case 'reopened':
                return [prefix, 'danger'].join('');

            case 'in_progress':
            case 'in_testing':
                return [prefix, 'info'].join('');

            case 'invoicing':
            case 'released':
            case 'accepted':
            case 'offer_approved':
            case 'offer_accepted':
            case 'completed': // csak sprint
                return [prefix, 'success'].join('');

            case 'offered':
                return [prefix, 'warning'].join('');

            default:
                // ha nincs előre beállított osztálynév, akkor sem futunk hibára hanem alapból primary-ra állítjuk
                return [prefix, 'primary'].join('');
                break;
        }

    }


}
