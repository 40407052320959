import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigProjectEnum, TConfigProject } from '../configs/config.environment';
import { globalLayoutConfig } from '../configs/config.layout';
import { ScrollService } from './scroll.service';
import { CoreResponse, MetaArray } from '@ratkaiga/core-nextgen';

import { Nyomda } from '../entities/scroll';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private scrollService: ScrollService) { }

  public GlobalServiceConfig(project?: TConfigProject) {
    
    // amennyiben nem adunk át semmilyen paramétert a metódusnak, akkor az alapértelmezett defaultLayoutConfig-ot 
    // fogjuk visszaadni (fallback)
    if (project === undefined) {
      return globalLayoutConfig;
    } else {

      // egyedi layout konfigurációkat sorolhatjuk itt fel. 
      // amennyiben az adott projekthez nincs egyedi konfiguráció, akkor használjuk a default ágban a defaultLayoutConfig -ot
      switch(project) {
        default:
          return globalLayoutConfig;
          break;
      }
    }

  }

  /**
   * Itt inicializálhatjuk a különböző projektekhez szükséges lekérdezseinket
   */
  public init(): Promise<boolean> {



    switch (environment.app.project) {
      case ConfigProjectEnum.MYSCROLL:

          return Promise.all([
            this.scrollService.globalFetchNyomdaList()
          ]).then((values) => {   

            // szépen sorban feldolgozzuk a promise all-ban visszajövő adatainkat
            values[0].subscribe((r: CoreResponse) => {
              if (r.getData<Nyomda>()) {
                this.scrollService.nyomdaLista.next((r.getData<Nyomda>() as MetaArray<Nyomda>).filter(a => a instanceof Nyomda));
              }
            });           
            
            return true;
          }).catch(() => {
            return false;
          });

        break;
    }

  }


}
