export class IssueSearchFormModel {

    subject?: string;

    onlySubject?: boolean = false;
    
    nyomda?: number[];

    group_id?: number;

    module?: number[];

    status?: string[];

    onlyMainStatus?: boolean = false;

    severity?: number[];

    actors?: number[];

    owner?: number;

    date_from?: string;

    date_to?: string;

    sortOrder?: string = 'desc';

    sortField?: string = 'created_at';

    pageSize?: number = 25;

    pageOffset?: number = 0;

}