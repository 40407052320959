import { Entities } from '@ratkaiga/core-nextgen';
import { Issue } from './support-issue.entity';
import { SupportUserProfile } from './support-user-profile.entity';

export class Issuechange extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssuechange';

    protected _robotId: number = 3468;

    public static OP_CHANGE = 'change';
    public static OP_REMOVE = 'remove';
    public static OP_ADD = 'add'

    public static FIELD_DEVELOPER = 'developer';
    public static FIELD_TESTER = 'tester';
    public static FIELD_MAIN_STATUS = 'main_status';
    public static FIELD_MAIN_TYPE = 'main_type';
    public static FIELD_DEADLINE = 'deadline';

    issue: Issue;

    owner: SupportUserProfile;

    field: string;

    field_type: string;

    format: string;

    values: string;

    visible: boolean;

    operation: string;

    getIssue(): Issue {
        return this.issue;
    }

    getOwner(): SupportUserProfile {
        return this.owner;
    }

    getOwnerName(): string {
        return this.owner.getName();
    }

    getOperation(): string {
        return this.operation;
    }

    getField(): string {
        return this.field;
    }

    getFieldType(): string {
        return this.field_type;
    }

    isVisible(): boolean {
        return this.visible;
    }

    templateFrom(): unknown {

        switch (this.field) {
            case Issuechange.FIELD_MAIN_TYPE:
            case Issuechange.FIELD_MAIN_STATUS:
                // legacy labelek miatt itt most egy kicsit hackelni kell
                return (JSON.parse(this.values)?.from.indexOf('label_') > -1) ? JSON.parse(this.values)?.from : ['label', JSON.parse(this.values)?.from].join('_'); 
                break;
            default: 
                return (this.getOperation() === 'change') ? JSON.parse(this.values)?.from : JSON.parse(this.values)?.value;
                break;
        }       

    }  
    
    templateTo(): unknown {
        
        switch (this.field) {
            case Issuechange.FIELD_MAIN_TYPE:
            case Issuechange.FIELD_MAIN_STATUS:
                // legacy labelek miatt itt most egy kicsit hackelni kell
                return (JSON.parse(this.values)?.to.indexOf('label_') > -1) ? JSON.parse(this.values)?.to : ['label', JSON.parse(this.values)?.to].join('_'); 
                break;
            case Issuechange.FIELD_DEADLINE:
                return JSON.parse(this.values)?.to;
                break;
            default: 
                return (this.getOperation() === 'change') ? JSON.parse(this.values)?.to : JSON.parse(this.values)?.value;
                break;
        }        

    }

    templateValue(type: string = null): unknown {
        console.log(type, JSON.parse(this.values));
        return JSON.parse(this.values)?.value;
    }

    /**
     * Egy adott change esemény üzenetetét próbáljuk meg a meglévő üzleti logika alapján visszaadni.
     * 
     * @TODO mindenhol legyen default érték, mert nem szeretnénk, hogy elessen a felület
     * @returns 
     */
    templateMessage(): string {

        switch(this.getFieldType().trim()) {
            case 'actor': 
                return 'label_' + this.getField().trim();              
                break;
            case 'type':
                return 'label_type_change';
                break;
            case 'deadline':
                return 'label_deadline_change';
                break;
            case 'status':
            default:
                // ha a robot ID szerepel akkor automatikus változtatásról van szó, ott mást is kell kiírni a felületre
                return (this.owner.getId() === this._robotId) ? 'label_status_change_auto' : 'label_status_change';
        }

    }

    templateOperation(): string {
        return 'label_' + this.getOperation().trim();
    }

    templateType(): string {

        switch (this.field) {
            case Issuechange.FIELD_TESTER:
            case Issuechange.FIELD_DEVELOPER:   
                return 'actor';

            case Issuechange.FIELD_MAIN_TYPE:
                return 'type';

            case Issuechange.FIELD_MAIN_STATUS:
            default:
                return 'status';
        }

    }

    isTranslateable(): boolean {

        switch (this.field) {
            case Issuechange.FIELD_MAIN_TYPE:
            case Issuechange.FIELD_MAIN_STATUS:
                return true;
                break;
            default: 
                return false;
                break;
        }        

    }

}