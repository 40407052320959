import { ICoreTranslateVersions } from '@ratkaiga/core-nextgen/translate';

// lehetséges projekt típusok, ezeket a fordítás során 
// használjuk, illetve a layout generálásnál van szerepük
export enum ConfigProjectEnum {
    MYSCROLL = 'myscroll',
    SPIRITNG = 'spiritng',
    CEE = 'cee'
}

export interface ICoreEndpointOptions {
    profile: 'auth' | 'app';
    websockets: boolean;
}

export type TConfigProject = ConfigProjectEnum.MYSCROLL | ConfigProjectEnum.SPIRITNG | ConfigProjectEnum.CEE;

export interface ILayoutVersion {
    name: string;
    visibility: {
        aside: boolean;
        header: boolean;
        toolbar: boolean;
    };
}

export interface IConfigEnvironment {
    // Default Angular production flag
    production: boolean,
    // HTTP Client Profiling
    profiler: boolean,
    // Core Debug
    debug: boolean;
    // UI Framework related options
    app: {
        name: string;
        version: string;
        theme: string;
        project: TConfigProject;
        title: string;
        params?: {
            pagination?: {
                max_buttons: number;
            },
            auth?: {
                user: string;
                password: string;
            },
            pusher?: {
                id: number;
                key: string;
                secret: string;
            }
        }
    },
    // Local storage related options
    storage: {
        token: string;
        websocket?: string;
        frontend?: string;
    },
    // Translate API related options
    translate: {
        storage?: string;
        project: string;
        versions: ICoreTranslateVersions[];
    },
    // API endpoints
    endpoints: {
        options: ICoreEndpointOptions,
        app: string;
        auth: string;
        locale?: string;
        message?: string;
        fileservice?: string;
        support?: string;
        socket?: string;
        datapump?: string;
        release?: string;
    },
    layout: {
        // kliens: nyomda, cég, stb.. a frontend alapján
        client: number;
        // layout verzió: generic vagy egy adott speciális verzió
        version: string;
        versions: ILayoutVersion[];
    }
}
