import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Injector, OnDestroy, Renderer2 } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, map, takeUntil } from 'rxjs';

// tábla kezeléshez osztályok
import { CoreTableColumn } from './models/table-column';
import { CoreTablePaginationState } from 'src/app/_core/models/table-pagination';
import { CoreTableSortDirectionEnum, CoreTableSortState } from 'src/app/_core/models/table-sort';

// tábla kezeléshehz interfészek
import { 
    ICoreTableColumn
} from 'src/app/_core/interfaces/core-table.interfaces';

import { CollectionMetadata, CoreRequestFilterModel, CoreRequestParams, CoreResponse, CoreResponsePagination, EntityService, MetaArray } from '@ratkaiga/core-nextgen';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CoreDictionary } from './models/dictionary';
import { ICoreExtendecComponentSearchOptions, ICoreExtendedComponentOptions } from './interfaces/core.interfaces';
import { merge } from "ts-deepmerge";
import { ScrollService } from './service/scroll.service';
import { Nyomda } from './entities/scroll';
import { Select2UpdateEvent, Select2Value } from 'ng-select2-component';
import { CoreComponentSearch } from './models/component-search';

@Component({
    selector: 'scroll-core-abstract-component',
    template: ''
})
export abstract class CoreAbstractComponent implements OnDestroy {

    protected validScopes: string[] = []

    // beállítjuk az alapértelmezéseket a származtatott komponensünkhöz
    private _defaultOptions: ICoreExtendedComponentOptions = {
        dataTableOptions: {
            fetchUrl: undefined,
            name: 'datatable-list',
            primaryKey: 'id',
            sort: 'created_at',
            direction: 'desc'
        }
    };

    // options setter
    set componentOptions(options: ICoreExtendedComponentOptions) {
        this._componentOptions = merge(this._defaultOptions, options);
    }

    // options getter
    get componentOptions(): ICoreExtendedComponentOptions {
        return this._componentOptions;
    }

    // beállítjuk az alapértelmezéseket a származtatott komponensünk keresőjéhez
    private _defaultSearchOptions: ICoreExtendecComponentSearchOptions = {
        paramsVersion: 1
    };

    // kereső options setter
    set componentSearchOptions(options: ICoreExtendecComponentSearchOptions) {
        this._componentSearchOptions = merge(this._defaultSearchOptions, options);
    }

    // kereső options getter
    get componentSearchOptions(): ICoreExtendecComponentSearchOptions {
        return this._componentSearchOptions;
    }

    // table 
    get table(): string {
        return this._componentOptions.dataTableOptions.name;
    }

    get tableSort(): CoreTableSortState {
        return this._columnSort;
    }

    get tableColumns(): ICoreTableColumn[] {
        return this._columnData;
    } 

    get tableColumnVisibility() {
        return this._columnVisibility;
    }

    get nyomdaLista(): Select2Value[] {
        return this._nyomdak;
    }

    get nyomdaListaInitial(): Select2Value {
        return [];
    }

    set entityId(id: number) {
        this._componentSelectedEntityId = id;
    }

    get entityId(): number {
        return this._componentSelectedEntityId;
    }

    /**
     * Tároljuk a betöltött nyomda listát, mert erre majdnem minden komponensünkben szökségünk lehet. A nyomda listákat 
     * általában a frameworkbe integrált select2 csomaggal használjuk.
     */
    private _nyomdak: Select2Value[] = [];

    private _componentOptions;

    private _componentSelectedEntityId: number;
    private _componentSelectedEntity: unknown;

    public componentCollectionMetadata: Subject<CoreResponsePagination> = new Subject();
    public componentRequestFilters: CoreRequestFilterModel[] = [];
    public componentRequestParams: CoreRequestParams = new CoreRequestParams(this.componentRequestFilters);

    private _componentSearch: CoreComponentSearch = new CoreComponentSearch();
    private _componentSearchOptions;

    /**
     * A származtatott komponens adatait egy behaviorSubjectben tároljuk. Lehetne más is, de collection típusok tárolásához jelen 
     * fázisban ez a legalkalmasabb.
     * @var BehaviorSubject
     */
    public componentList: BehaviorSubject<unknown[]> = new BehaviorSubject([]);

    /**
     * A származtatott komponensünk alapértelmezett űrlap csoportja
     * @var UntypedFormGroup
     */
    public componentForm: UntypedFormGroup;

    /**
     * A származtatott komponensünk alapértelmezett kereső űrlap csoportja
     * @var UntypedFormGroup
     */
    public componentSearchForm: UntypedFormGroup;

    /**
     * A származtatott komponensünk fő (adat)tábla oszlopainak láthatósága
     * @var CoreDictionary[]
     */
    private _columnVisibility: CoreDictionary[] = [];

    /**
     * A származtatott komponensünk fő (adat)tábla állapotának rögzítéséhez használjuk
     * @var CoreTableSortState
     */
    protected _columnSort: CoreTableSortState;

    private _tablePagination = new CoreTablePaginationState('', 0, 25);

    private _columnDefaultWidth = { px: 128, percent: 15 };
    private _columnData: ICoreTableColumn[] = [];

    protected sortSubject: Subject<CoreTableSortState> = new Subject();
    protected paginationSubject: Subject<CoreTablePaginationState> = new Subject();

    private sortSubscriptions: Subscription = new Subscription();

    /**
     * Saját privát modal szervízünk, nem engedjük közvetlenül használni mert van rá wrapper metódusunk
     * @var NgbModal
     */
    private modalService: NgbModal;

    /**
     * Komponens szintű Toastr szervizünk, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var ToastrService
     */
    protected toastrService: ToastrService;

    /**
     * Komponens szintű Renderer, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var Renderer2
     */
    protected rendererService: Renderer2;

    /**
     * Komponens szintű HttpClient, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var HttpClient
     */
    protected httpClient: HttpClient;

    /**
     * Komponens szintű ActivatedRoute, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var ActivatedRoute
     */
    protected activatedRoute: ActivatedRoute;

    /**
     * Komponens szintű Router, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var Router
     */
    protected router: Router;

    /**
     * Komponens szintű EntityService, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var EntityService
     */
    protected entityService: EntityService;

    /**
     * Komponens szintű AuthService, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var AuthService
     */
    protected authService: AuthService;

    /**
     * Komponens szintű ScrollService, amit használni fogunk az összes gyermek komponensben is ami az "absztrakt" komponensünket megvalósítja
     * @var ScrollService
     */
    protected scrollService: ScrollService;

    /**
     * @var unknown
     */
    customData: unknown;

    /**
     * Egyszerű subject, amely a feliratkozásainkat fogja megsemmisíteni, amennyiben a komponensünk életciklusa végetér.
     */
    destroy$: Subject<void> = new Subject();

    componentLoaded$: Subject<boolean> = new Subject(); 

    /**
     * Konstruktorunk, aminek csak az injector-t és a documentet adjuk át. Ezeket az argumentumokat kénnytelenek vagyunk 
     * a gyermek komponensből meghívatkozni. Minden más szerviz meghívása az injectoron keresztül történik.
     * 
     * @param _injector 
     * @param _document 
     */
    constructor(
        private _injector: Injector,
        @Inject(DOCUMENT) private _document: Document        
        ) { 
        
        // saját service injectorok
        this.modalService = this._injector.get(NgbModal);    
        this.toastrService = this._injector.get(ToastrService);    
        this.rendererService = this._injector.get(Renderer2);

        // scroll service injectorok
        this.entityService = this._injector.get(EntityService);
        this.authService = this._injector.get(AuthService);
        this.scrollService = this._injector.get(ScrollService);

        // angular injectorok
        this.httpClient = this._injector.get(HttpClient);
        this.activatedRoute = this._injector.get(ActivatedRoute);
        this.router = this._injector.get(Router);

        this.componentLoaded$.next(false);
    }

    /**
     * onDestroy metódus, ami jelenleg a destroy subjectünket állítja befejezettre
     */
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

        this.sortSubscriptions.unsubscribe();
    }

    /**
     * Egyedi setter metódus a komponensben éppen kezelt entitás tárolásához
     * 
     * @param componentSearchModel 
     */
    public setEntity<T>(selectedEntity: T): void {
        this._componentSelectedEntity = selectedEntity;
    }

    /**
     * Egyedi getter metódus a komponensben éppen kezelt entitás lekérdezéséhez
     * 
     * @returns 
     */
    public getEntity<T>() {
        return this._componentSelectedEntity as T;
    }

    /**
     * Saját metódusunk a scope-ok kezelésére és a redirectelésre, ha nincs egyezés.
     * 
     * @returns 
     */
    protected preAuth(): void {
        if (!this.authService.isUserScopesAvailable(this.validScopes)) {
            this.router.navigate(['/dashboard/permission'], { skipLocationChange: true });
            return;
        }
    }

    /**
     * Saját metódusunk a teljes komponens inicializációhoz
     */
    protected preInitComponent<T>(): void {

        this._columnSort = new CoreTableSortState(
            this._componentOptions.dataTableOptions.name, 
            this._componentOptions.dataTableOptions.sort ?? this._componentOptions.dataTableOptions.primaryKey, 
            this._componentOptions.dataTableOptions.direction ?? CoreTableSortDirectionEnum.DESC
        );

        if (this._componentOptions.dataTableOptions.name === undefined || this._componentOptions.dataTableOptions.fetchUrl === undefined) {
            throw new Error('Component options should be set before calling preInitComponent');
        }

        this.componentRequestParams.sortField = this._columnSort.getColumn();
        this.componentRequestParams.sortOrder = this._columnSort.getDirection();

        /**
         * Ha a kereséshez tartozó beállításaink üresek, akkor az alapértelmezést hozzárendeljük. Ezt a későbbiekben 
         * felülírhatjuk. 
         */
        if (this.componentSearchOptions === undefined)
            this._componentSearchOptions = this._defaultSearchOptions;

        /**
         * Feliratkozunk a központi nyomdalistánkra ami egy replay subject, tehát engedjük a késői feliratkozást is, 
         * feltöltjük vele a komponens belső tulajdonságát, ami id és név párosításban tárolja az értékeket.   
         */ 
        this.subscribeNyomdaList();

        /**
         * Ha van kódunk (partner kódot jelent), akkor elkapjuk az aktív route-n keresztül és csak utána végzünk műveleteket.
         */
        this.subscribeActivatedRoute<T>();

        /**
         * Feliratkozunk a sortSubject-re, ez automatikusan megkapja az emittelt adatokat amikor a táblázatunk (listánk) 
         * a direktívák által inicializálódik. Csak a sortField és sortOrder paramétereket állítjuk, minden mást már 
         * előtte beállítunk (jó esetben... pl. kereső form)
         */
        this.sortSubject.pipe(takeUntil(this.destroy$)).subscribe((s) => {
            this.componentRequestParams.sortField = s.getColumn();
            this.componentRequestParams.sortOrder = s.getDirection();
    
            // meghívjuk a lokális metódust, ami az adatlekérdezést intézi. 
            // ide már átadjuk a RequestParams-on belül összeállított filter tartalmát is
            this.fetchComponentData<T>(this.componentRequestParams.getQuery());
        });
    
        this.paginationSubject.pipe(takeUntil(this.destroy$)).subscribe((p) => {
            this.componentRequestParams.pageNumber = p.getStartIndex();
            this.componentRequestParams.pageSize = p.getItemsPerPage();
    
            // meghívjuk a lokális metódust, ami az adatlekérdezést intézi. 
            // ide már átadjuk a RequestParams-on belül összeállított filter tartalmát is
            this.fetchComponentData<T>(this.componentRequestParams.getQuery());
        });

    }

    /**
     * Felülírható metódus az ActivatedRoute-ra történő feliratkozáshoz (speciális esetekben kellhet)
     */
    protected subscribeActivatedRoute<T>(): void {

        this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((p: ParamMap) => {

            // van paraméterünk
            if (p.getAll('id').length > 0) {
    
                // a nullás indexben fogjuk megtalálni a nekünk szükséges értéket
                this.entityId = parseInt(p.getAll('id')[0]);

                // a partnerre kell filterezzünk alapból, tehát frissítjük a filter array-t
                this.componentRequestParams.filter = [new CoreRequestFilterModel('id', this.entityId)];

                this.fetchComponentDependencies();
                this.fetchComponentData<T>(this.componentRequestParams.getQuery());
    
            } else {   

                // normális komponens init
                this.fetchComponentDependencies();
                this.fetchComponentData<T>(this.componentRequestParams.getQuery());
            }
  
        });

    }

    /**
     * Felülírható metódus a nyomdalistára történő feliratkozáshoz (speciális esetekben kellhet)
     */
    protected subscribeNyomdaList(): void {

        /**
         * Feliratkozunk a központi nyomdalistánkra ami egy replay subject, tehát engedjük a késői feliratkozást is, 
         * feltöltjük vele a komponens belső tulajdonságát, ami id és név párosításban tárolja az értékeket.   
         */ 
        this.scrollService.nyomdaLista.subscribe((r: Nyomda[]) => {
            r.forEach(nyomda => {
                this._nyomdak.push({ value: nyomda.id, label: nyomda.nev });
            });
        });

    }

    /**
     * Felülírható metódus a komponens űrlap változásainak kezeléséhez. Az alap verzó megpróbálja a komponens 
     * űrlapon regisztrált vezérlők értékeit név szerinti tulajdonságba besetelni.
     */
    protected subscribeComponentForm<T>(): void {

        this.componentForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
            next: (value: unknown) => {
                Object.keys(value).forEach((key: string) => {
                    this.getEntity<T>()[key] = value[key];
                });
            }
        });

    }

    /**
     * Felülírható metódus a komponensben kezelt adatok backend oldali lekérdezéséhez, tehát ha bármilyen egyedi 
     * kezelés szükséges az eredemények értelmezéséhez, akkor a metódust a származtatott komponensben írjuk felül.
     * 
     * @param filterQuery 
     * @param reloadMessage 
     * @param errorCallback 
     */
    protected fetchComponentData<T>(filterQuery?: string, reloadMessage?: boolean, errorCallback?: (e: unknown) => void): void {

        // összeállítjuk a query-t (megtehetnénk a get-en belül is, de így legalább tudjuk debugolni is)
        const url = this._componentOptions.dataTableOptions.fetchUrl + (filterQuery ?? '');

        this.httpClient.get(url)
          .pipe(
            map(_result => this.entityService.parse(_result).getResponse())
          )
          .subscribe({
            next: (r: CoreResponse) => {
              if (r.getData<T>()) {          
    
                // a core automatikusan mappolja nekünk a meta adatokat, tehát csak tovább kell adnunk a megfelelő subjectnek
                this.componentCollectionMetadata.next(r.getPagination<CoreResponsePagination>());
                // a core szintén automatikusan mappolja az adatokat is (meta adatok nélkül), ezért ezt továbbadjuk a lista subjectnek
                this.componentList.next((r.getData<T>() as T[]).filter(a => !(a instanceof MetaArray)));
      
                if (reloadMessage) {
                  this.toastrService.success('A lista frissítésre került');
                }
              }
            },
            error: (e: unknown) => {

                // logoljuk a hibát ha van (ez általában 403 lesz) 
                console.info(e);

                if (errorCallback) {
                    errorCallback(e);
                }
            }
        });
    
    }

    /**
     * Felülírható metódus a komponens függőségek egyidejű betöltéséhez Ezeket az adatokat jellemzően csak egyszer az init 
     * során töltjük be, de bármikor hívhatjuk később is.
     */
    fetchComponentDependencies(): void {}

    /**
     * Felülírható metódus a komponensben kezelt adatok újratöltéséhez. A fetchComponentData metódusnak átadott boolean érték 
     * azt jelzi nekünk, hogy meg kell jelenítsünk egy üzenetet, hogy a frissítés megtörtént. Ezt az üzenetet felülírás esetén 
     * egy false érték átadásával kikapcsolhatjuk.
     * 
     * @param reloadMessage 
     */
    protected reloadComponentData<T>(reloadMessage: boolean = true): void {
        this.fetchComponentData<T>(this.componentRequestParams.getQuery(), true);
    }

    /**
     * Callback funkció, amely segítségével az eventet indító objektumhoz tartozó láthatósági 
     * flag -et billentjük át checked - vagy nem checked formára (ez ugye egy boolean) 
     * 
     * @param event 
     * @param field 
     */
    public onChangeColumnVisibility(event: Event, field: string): void {
        this.tableColumnVisibility.find(c => c.getKey() === field).setValue(event.target['checked']);
    }

    /**
     * 
     * @param reset 
     */
    private tableInitPagination(reset = false): void {

        if (reset) {
            
            this._tablePagination.setStartIndex(0);
            this._tablePagination.setItemsPerPage(25);

        } else {
            if (sessionStorage.getItem('scroll-ng-' + this.table) !== null) {

                const p = JSON.parse(sessionStorage.getItem('scroll-ng-' + this.table));
                this._tablePagination.setStartIndex(p.startIndex);
                this._tablePagination.setItemsPerPage(p.itemsPerPage);

            } else {

                this._tablePagination.setStartIndex(0);
                this._tablePagination.setItemsPerPage(25);

            }
        }

        this.paginationSubject.next(this._tablePagination);
    }

    public tablePagination(event: { startIndex: number, itemsPerPage: number }): void {
        this._tablePagination.setStartIndex(event.startIndex);
        this._tablePagination.setItemsPerPage(event.itemsPerPage);
        this.paginationSubject.next(this._tablePagination);
    }

    /**
     * Az applikációban szabványosan használt táblázat formátum th / td tagjaira állítja be központilag a megadott adatokat. 
     * A beállításokat több egyedi metódus végzi, illetve a funkció dependál a származtatott osztályban feltöltött _columnData 
     * kollekcióra is.
     * 
     * @param event 
     */
    public columnInit(event: { name: string, el: ElementRef }): void {

        // ha egy headerről van szó, akkor megnézzük, hogy a konfiguráció szerint sortolható-e
        if (event.el.nativeElement.localName === 'th') {
            if (this.isColumnSortable(event.name)) {
                this.rendererService.addClass(event.el.nativeElement, 'datatable-cell-sort');

                // ha a mezőnév megfelel az alapértelmezett sortnak, akkor rárakjuk az aktív sortot
                // majd a tartalomhoz beillesszük a sort ikont is
                if (event.name === this._columnSort.getColumn()) {
                    this.addColumnSort(event.el);
                    this.sortSubject.next(this._columnSort);
                }

                // bármilyen klikk esemény következik be, azt emitteljük a columsort funkció felé
                this.sortSubscriptions.add(
                    this.rendererService.listen(event.el.nativeElement.children[0], 'click', () => this.columnSort({ name: event.name, el: event.el }))
                );
            }
        }

        // TODO debug
        // console.log(this._renderer, event.el.nativeElement, this.getColumnWidth(event.name));

        // a szélességet mindenképpen hozzáadjuk (ha van, ha nincs akkor default érték)
        this.rendererService.setStyle(event.el.nativeElement, 'width', this.getColumnWidth(event.name));
        this.rendererService.setStyle(event.el.nativeElement, 'max-width', this.getColumnWidth(event.name));
    }

    /**
     * Callback funkció a táblázat fejlécekhez. Itt az eredeti columnInit eventet várjuk (tehát visszük tovább), mert főként 
     * ugyanazzal az ElementRef-el kell dolgozzunk a továbbiakban, illetve annak parent-child kötődéseivel.
     * 
     * @param event 
     */
    public columnSort(event: { name: string, el: ElementRef }): boolean | void {

        // azt nézzük, hogy ugyanarról az oszlopfejlécről van-e szó, mint ami az éppen aktív sortolásunk, mert ha igen, akkor
        // nem kell megszüntetnünk a stílusokat, hanem elég váltanunk a megjelenítésen és újraírni a CoreSortState tartalmát.
        if (event.name === this._columnSort.getColumn()) {

            this._columnSort.setColumn(event.name);
            this._columnSort.setDirection(this._columnSort.getDirection() === CoreTableSortDirectionEnum.ASC ? CoreTableSortDirectionEnum.DESC : CoreTableSortDirectionEnum.ASC);

            // az aktív ikont a hozzárendelt osztály alapján találjuk meg
            const c = this._document.getElementsByClassName('datatable-sort-icon');

            Array.from(c).forEach(element => {
                this.rendererService.removeClass(element, 'flaticon2-arrow-up');
                this.rendererService.removeClass(element, 'flaticon2-arrow-down');
                this.rendererService.addClass(element, (this._columnSort.getDirection() === CoreTableSortDirectionEnum.ASC) ? 'flaticon2-arrow-up' : 'flaticon2-arrow-down');
            });

            this.sortSubject.next(this._columnSort);
            // @todo emit sort transaction !
        } else {

            // egy másik oszlopfejlécen történt a klikkelés, ezért az összes sortolással kapcsolatos vizuális elemet töröljük
            this.removeAllColumSort();

            // beállítjuk az új oszlopot a CorseSortState-ben, az irányt meghagyjuk ahogy volt (nem lényeges) bár oszlopváltásnál 
            // lehet fixen asc vagy desc tetszés szerint (ehhez csak a setDirectiont kell ide beszúrni, ha kérik)
            this._columnSort.setColumn(event.name);

            // az új ElementRef alapján felépítjük a sort header vizuális részét.
            this.addColumnSort(event.el);

            this.sortSubject.next(this._columnSort);
            // @todo emit sort transaction !
        }
    }

    /**
     * Az ElementRef-ben átadott oszlopra a sort stílus aktiválása
     * 
     * @param el 
     */
    private addColumnSort(el: ElementRef): void {

        this.rendererService.addClass(el.nativeElement, 'datatable-cell-sorted');

        const icon = this.rendererService.createElement('i');
        this.rendererService.addClass(icon, 'datatable-sort-icon');
        this.rendererService.addClass(icon, (this._columnSort.getDirection() === CoreTableSortDirectionEnum.ASC) ? 'flaticon2-arrow-up' : 'flaticon2-arrow-down');

        const label = el.nativeElement.innerHTML;
        this.rendererService.setValue(el.nativeElement.children[0], label);
        this.rendererService.appendChild(el.nativeElement.children[0], icon);

    }

    /**
     * Az összes sort stílus és elem törlése.
     */
    private removeAllColumSort(): void {

        const c = this._document.getElementsByClassName('datatable-sort-icon');

        Array.from(c).forEach(element => {
            this.rendererService.removeClass(this.rendererService.parentNode(this.rendererService.parentNode(element)), 'datatable-cell-sorted');
            this.rendererService.removeChild(this.rendererService.parentNode(element), element);
        });
    }

    /**
     * Feldolgozza a collection metában esetlegesen beérkező egyedi adatokat
     * 
     * @param value 
     */
    protected handleCustomData(value: CollectionMetadata): void {        
        if (value.hasCustomData()) {
            this.customData = value.getCustomData();
        }
    }

    /**
     * A metódus visszaadja egy meghatározott nevű oszlop szélességét, amennyiben az megtalálható a komponens 
     * konfigurációjában. Ha nem található meg, akkor az alapértelmezett értéket kapjuk vissza.
     * 
     * @param key 
     * @param method 
     * @returns 
     */
    private getColumnWidthNumeric(key?: string, method = 'percent'): number {
        if (key !== undefined) {
            try {
                return this._columnData.filter(k => k.getKey() === key).shift().width[method] ?? this._columnDefaultWidth[method];
            } catch (e) {
                return this._columnDefaultWidth[method];
            }
        } else {
            return this._columnDefaultWidth[method];
        }
    }

    /**
     * A metódus visszaadja egy meghatározott nevű oszlop szélességét, amennyiben az megtalálható a komponens 
     * konfigurációjában. Ha nem található meg, akkor az alapértelmezett értéket adja vissza. A visszatérés egy string, 
     * amely tartalmazza a szélesség számítás metódusát is (px, %).
     * 
     * @param key 
     * @param method 
     * @returns 
     */
    private getColumnWidth(key?: string, method = 'percent'): string {
        return this.getColumnWidthNumeric(key, method) + CoreTableColumn.getMeasurement(method);
    }

    /**
     * 
     * @param key 
     * @returns 
     */
    private isColumnSortable(key?: string): boolean {

        if (key !== undefined)
            try { 
                return this._columnData.filter(k => k.getKey() === key).shift().isSortable(); 
            } catch (e) {
                console.info(e.getMessage());
            }

        return false;
    }

    /**
     * Modal nyitása egy adott entitással, az átadott komponens referenciával, illetve opcionálisan NgbModalOptions 
     * objektummal. NgbModalRef-et kapunk vissza, tehát a metódus hívása után az NgbModalRef promise-t használhatjuk
     * a close és dissmiss események elkapásához.
     * 
     * @example
     * const modal = this.openModalWindow<myEntityType>(myEntity, myModalComponent)
     * modal.then((result: ICoreModalSubmit) => {
     *  console.log('Closed with:', result);
     * }, (reason: unknown | ICoreModalDissmiss) => {
     *  console.log('Dismissed with:', reason);
     * });
     * 
     * modal.closed.pipe(takeUntil(this.destroy$)).subscribe((r: unknown | ICoreModalSubmit | ICoreModalDissmiss) => {
     *  console.log(r);
     * });
     * 
     * @param entity 
     * @param component 
     * @param options 
     * @returns 
     */
    protected openModalWindow<T>(entity: T, component: unknown, options?: NgbModalOptions): NgbModalRef {

        // adunk pár alapértelmezést a modalunknak
        const defaultModalOptions = {
            size: 'lg', 
            keyboard: false, 
            centered: true
        };

        // ha még kaptunk valamilyen options objektumot, akkor azt összemergeljük
        const opt = (options) ? {...defaultModalOptions, ...options} : defaultModalOptions;
        
        // nyitjuk a modal ablakunkat
        const instance = this.modalService.open(component, opt);
        instance.componentInstance.entity = entity;

        return instance;
    }

    /**
     * Egyedi setter metódus a komponens keresési modeljének átadásához
     * 
     * @param componentSearchModel 
     */
    public setComponentSearch<T>(componentSearchModel: T): void {
        this._componentSearch = componentSearchModel;
    }

    /**
     * Egyedi getter metódus a komponens keresési modeljének lekérdezéséhez
     * 
     * @returns 
     */
    public getComponentSearch<T>() {
        return this._componentSearch as T;
    }

    /**
     * Felülírható metódus az egyedi keresési mezők beállításához, amely még a filterek értelmezése előtt meghívásra kerül.
     */
    public beforeSearchFiltersInit(): void {}

    /**
     * Felülírható metódus a filterek esetleges módosításához, amely értelemszerűen a filterek értelmezése után kerül meghívásra.
     */
    public afterSearchFiltersInit(): void {}

    /**
     * Teljesen felülírható metódus a komponens keresés indításához. Amennyiben csak az absztrakt komponens által biztosított 
     * lehetőségekre van szükség, akkor elégséges meghívni ezt a metódust egy proxy metóduson belül.
     * 
     * @example
     * public onSearch(): void {
     *  super.componentSearchSubmit<ComponentSearch>();
     * }
     */
    public componentSearchSubmit<T>(): void {

        if (this.componentSearchForm.valid) {
    
            this.beforeSearchFiltersInit();
    
            // keresés esetén a page number-t és a size-t alaphelyzetbe kell állítsuk
            this.componentRequestParams.pageNumber = 0;
            this.componentRequestParams.pageSize = 25;
            
            const fm:CoreRequestFilterModel[] = [];
    
            if (this.componentSearchOptions.paramsVersion === 2) {
    
              // virtuális mezők kezelése (params v2)
              if (this.componentSearchForm.controls.search_text.value) {
                fm.push(new CoreRequestFilterModel('v!' + this.componentSearchOptions.virtualTextField, this.componentSearchForm.controls.search_text.value));
                fm.push(new CoreRequestFilterModel('vref!' + this.componentSearchOptions.virtualTextField, this.componentSearchOptions.virtualFields.join(',')));
              }
    
              // normál mezők beforgatása a keresésbe (params v2)
              Object.keys(this.getComponentSearch<T>()).forEach(k => {
                if (k !== this.componentSearchOptions.virtualTextField && this.getComponentSearch<T>()[k] !== undefined) {
                  fm.push(new CoreRequestFilterModel(k, this.getComponentSearch<T>()[k]));
                }      
              });
    
            } else {
    
              // csak a normál mezők beforgatása a keresésbe (params v1)
              Object.keys(this.getComponentSearch<T>()).forEach(k => {
                if (this.getComponentSearch<T>()[k] !== undefined) {
                  fm.push(new CoreRequestFilterModel(k, this.getComponentSearch<T>()[k]));
                }      
              });
    
            }
    
            this.afterSearchFiltersInit();
    
            // ezt a filtert tarjuk továbbra is a komponens request paramsai között
            this.componentRequestParams.filter = fm;
    
            this.fetchComponentData(this.componentRequestParams.getQuery());
    
        }
    
    }

    /**
     * Felülírható metódus a komponens kereső resetelése esetén a speciális beállítások érvényesítéséhez, pl. checkboxok, select2, stb. 
     * direkt érték állításához.
     */
    public afterSearchResetCalled() {}

    /**
     * Teljesen felülírható metódus a komponens kereső alaphelyzetbe állításához. Amennyiben csak az absztrakt komponens által biztosított 
     * lehetőségekre van szükség, akkor elégséges meghívni ezt a metódust egy proxy metóduson belül.
     * 
     * @example
     * public onSearchReset(): void {
     *  super.componentSearchReset<ComponentSearch>(new ComponentSearch());
     * }
     * 
     * @param componentSearchModel 
     */
    public componentSearchReset<T>(componentSearchModel: T): void {

        // reseteljük a beépített reset metódussal az űrlapunkat
        this.componentSearchForm.reset();
        
        // érvényesítjük a speciális beállításokat, ha adtunk meg ilyet a származtatott komponensben
        this.afterSearchResetCalled();
    
        // beállítunk egy új példányt a komponenshez tartozó kereső modelből
        this.setComponentSearch(componentSearchModel);
    
        // reset esetén a page number-t és a size-t alaphelyzetbe kell állítsuk
        this.componentRequestParams.pageNumber = 0;
        this.componentRequestParams.pageSize = 25;
        
        // töröljük az összes filtert
        this.componentRequestParams.filter = [];
    
        this.fetchComponentData(this.componentRequestParams.getQuery());

    }

    /**
     * Kényelmi metódus a komponens kereső objektumának frissítéséhez, ha a select2 választóban értéket változtat a felhasználó.
     * 
     * @param event 
     */
    public onUpdateSearchPropertyFromSelect2<T>(event: Select2UpdateEvent, prop: string): void {

        if (event.value)
            this._componentSearch[prop] = (event.value as T);

    }

    /**
     * Kényelmi metódus a komponens entitás objektumának frissítéséhez, ha a select2 választóban értéket változtat a felhasználó.
     * 
     * @param event 
     */
    public onUpdateEntityPropertyFromSelect2<T>(event: Select2UpdateEvent, prop: string): void {

        if (event.value)
            this._componentSelectedEntity[prop] = (event.value as T);

    }

}
