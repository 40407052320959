import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppLog } from '../util/log.util';

@Injectable({
  providedIn: 'root'
})
export class ScrollSocketService {

  protected ws: WebSocket;

  protected websocketState$ = new Subject<boolean>();

  protected incomingMessageQueue: BehaviorSubject<{ message: unknown, time: Date, seen: boolean }[]> = new BehaviorSubject([]);
  public incomingMessages = this.incomingMessageQueue.asObservable();

  constructor(private authService: AuthService) { }

  init(): void {

    if (environment.endpoints.options.websockets) {

      // ha már esetleg lenne példányosítva bármi is akkor levesszük a listenereket
      if (this.ws)
        this.ws.removeAllListeners();

      // csinálunk egy websocket connectiont
      this.ws = new WebSocket(`${environment.endpoints.socket}?x-scroll-auth=${this.authService.getToken()}`);

      // átváltjuk a saját figyelőnket, amire fel tudunk iratkozni
      this.ws.addEventListener('open', (evt: Event) => {
        this.websocketState$.next(true);
      });

      // átváltjuk a saját figyelőnket, amire fel tudunk iratkozni
      this.ws.addEventListener('close', (evt: Event) => {
        this.websocketState$.next(false);
      });

      this.ws.addEventListener('error', (evt: Event) => {
        AppLog.log('WebSocket error', evt);
      });
      
      // abban a pillanatban, ahogy csatlakozunk a websockethez, elkezdjük gyűjteni a bejövő üzeneteket
      this.ws.addEventListener('message', (evt: MessageEvent) => {
        
        const messages = this.incomingMessageQueue.getValue();
        messages.push({message: JSON.parse(evt.data), time: new Date(), seen: false });

        AppLog.log('WebSocket message', JSON.parse(evt.data));

        this.incomingMessageQueue.next(messages);
      });

    } else {
      // ha nem engedélyeztük az environmentben a websocket elérést akkor egyszerűen a state-t is false-ra rakjuk
      // TODO ott is használnunk kell environment vizsgálatot, ahol a websocket state-t figyeljük
      this.websocketState$.next(false);
    }

  }

  /**
   * Teljesen lezárjuk a kapcsolatot, általában kilépésnél fogjuk ezt használni, hiszen a subject complete után 
   * már nem fogunk státuszokat kapni (tehát az auto reconnect sem fog menni) 
   */
  close(): void {
    this.ws.close();
    this.ws.removeAllListeners();
    this.websocketState$.complete();
  }

  /**
   * Visszaadjuk a websocket példányunkat
   * @returns 
   */
  getWs(): WebSocket {
    return this.ws;
  }

  /**
   * Visszaadunk egy obszervert, amin keresztül az aktuális státuszt tudjuk figyelni (connected, disconnected)
   * @returns 
   */
  getWsState(): Observable<boolean> {
    return this.websocketState$;
  }

}
