import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { Subject, takeUntil } from 'rxjs';
import { ICoreFileserviceQuote } from 'src/app/_core/interfaces/fileservice.interfaces';
import { DriveService } from 'src/app/_core/service/scroll/drive.service';

@Component({
  selector: 'spirit-drive-stat-widget',
  templateUrl: './drive-stat-widget.component.html',
  styleUrls: ['./drive-stat-widget.component.scss']
})
export class DriveStatWidgetComponent implements OnInit, OnDestroy {

  @ViewChild('chart', {static: false})
  chartComponent: ChartComponent;

  @Input() header: boolean = false;

  driveStats: ICoreFileserviceQuote;
  driveCurrentNyomda: string;

  quotaChartOptions;

  destroy$: Subject<void> = new Subject();

  constructor(
    private driveService: DriveService) {
      
  }

  ngOnInit(): void {

    this.quotaChartOptions = this.getChartOptions();

    this.driveStats = {} as ICoreFileserviceQuote;

    this.driveService.fileserviceQuoteSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: (quoteObject: ICoreFileserviceQuote) => {
        this.updateQuoteChart(quoteObject);
        this.driveStats = quoteObject;
      }
    });

    this.driveService.fileserviceSelectedNyomda.pipe(takeUntil(this.destroy$)).subscribe({
      next: (name: string) => {
        if (name)
          this.driveCurrentNyomda = name;

      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  /**
   * Frissítjük az apex chartunkat (radial)
   * @param quoteObject 
   */
  protected updateQuoteChart(quoteObject: ICoreFileserviceQuote | undefined): void {
    if (quoteObject)
      this.quotaChartOptions.series = [Number (quoteObject.quote_allocation_percent.toPrecision(2))];
  }

  protected getChartOptions() {
    return {
      chart: {
          height: 350,
          type: 'radialBar',
      },
      series: [0],
      stroke: {
        lineCap: "round"
      },
      labels: ['Helyfoglalás'],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 5,
            size: "50%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "13px"
            },
            value: {
              color: "#006AE6",
              fontSize: "30px",
              show: true
            }
          }
        }
      },
    };
  }

}
