import { ICoreTablePaginationState, ICoreTablePaginatorOptions } from "../interfaces/core-table.interfaces";

export class CoreTablePaginatorOptions implements ICoreTablePaginatorOptions {

    allowButtons = {
        first: true,
        last: true,
        next: true,
        previous: true
    };

    allowDots = {
        use: false,
        treeshold: 5
    };

    allowedItemCounts = [5, 10, 25];

    maxPage = 1;

    pagerButtons: { index: number, label: number, dot?: boolean }[] = [];

    pagerCurrentPage = 0;

    // workaround
    pagerItemsPerPage = 25;
}

export class CoreTablePaginationState implements ICoreTablePaginationState {

    private _table: string;

    private _startIndex = 0;

    private _itemsPerPage = 25;

    private _reset = false;

    constructor(table?: string, startIndex?: number, itemsPerPage?: number, reset?: boolean) {
        this._table = table;
        this._startIndex = startIndex;
        this._itemsPerPage = itemsPerPage;
        this._reset = reset;
    }

    setTable(value: string): CoreTablePaginationState {
        this._table = value;
        return this;
    }

    getTable(): string {
        return this._table;
    }

    setStartIndex(value: number): CoreTablePaginationState {
        this._startIndex = value;
        return this;
    }

    getStartIndex(): number {
        return this._startIndex;
    }

    setItemsPerPage(value: number): CoreTablePaginationState {
        this._itemsPerPage = value;
        return this;
    }

    getItemsPerPage(): number {
        return this._itemsPerPage;
    }

    setReset(value: boolean): CoreTablePaginationState {
        this._reset = value;
        return this;
    }

    getReset(): boolean {
        return this._reset;
    }

}
