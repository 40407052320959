import { Entities, MetaArray } from '@ratkaiga/core-nextgen';
import { CoreTranslateService } from '@ratkaiga/core-nextgen/translate';
import { ScrollSupportService } from '../../../service/scroll-support.service';
import { Nyomda } from './../nyomda.entity';
import { IssueModule } from './support-module.entity';
import { IssueSchema } from './support-shema.entity';
import { SupportUserProfile } from './support-user-profile.entity';
import { ScrollFile } from '../fileservice-file.entity';

export class Issue extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgIssue';

    protected _supportService;
    protected _translateService;

    id: number;

    nyomda: Nyomda;

    module: IssueModule | unknown;

    owner: SupportUserProfile;

    parent: Issue | unknown;

    children: Issue[] | unknown[] | MetaArray<Issue> | MetaArray<unknown>;

    project_id: number;

    schema: string;

    main_status: string;

    internal_status: string;

    main_type: string;

    sub_type: string;

    severity: number;

    deadline: string;

    subject: string;

    content: string;

    resolution_time: number;

    priority: number;

    priority_business: boolean;

    priority_development: boolean;

    last_updated_by: SupportUserProfile;

    files: ScrollFile[];

    tags: string | string[] = [];

    getId(): number {
        return this.id;
    }

    getSubject(): string {
        return this.subject;
    }

    getContent(): string {
        return this.cleanup(this.content);
    }

    getSchema(): string {
        return this.schema;
    }

    getSchemaObjects(): IssueSchema[] {
        return this._supportService?.getPerloadEntity().getSchemas().filter(s => s.schema === this.schema);
    }

    getMainType(): string {
        return this.main_type;
    }

    getSubType(): string {
        return this.sub_type;
    }

    getModule(): unknown {
        return this.module;
    }

    getDeadlineAt(): string {
        return this.deadline as string;
    }

    getResolutionTime(): number {
        return this.resolution_time;
    }

    isQuoted(): boolean {
        return (this.main_type === 'kvotasfejlesztes');
    }

    /**
     * Az issue létrehozó ID értékét kapjuk vissza. Ez gyakorlatilag az OAuth ID is egyben.
     * 
     * @returns 
     */     
    getOwnerId(): number {
        return this.owner.getId();
    }

    /**
     * Az issue létrehozó nevét kapjuk vissza.
     * 
     * @returns 
     */    
    getOwnerName(): string {
        return this.owner.getName();
    }

    /**
     * Az issue létrehozó nevének rövidítését kapjuk vissza.
     * 
     * @returns 
     */     
    getOwnerNameAbbr(): string {
        return this.owner.getNameAbbr().toLocaleUpperCase();
    }

    /**
     * Az utolsó hozzászóló (az issue-t frissítő) nevét kapjuk vissza. Ha ilyen nem lenne, 
     * akkor a backend issue owner UserProfilját adja vissza, tehát mindenképpen lesz nevünk.
     * 
     * @returns 
     */
    getLastUpdatedByName(): string {
        return this.last_updated_by.getName();
    }

    /**
     * Az utolsó hozzászóló (az issue-t frissítő) nevének rövidítését kapjuk vissza. Ha ilyen 
     * nem lenne, akkor a backend issue owner UserProfilját adja vissza, tehát mindenképpen lesz értékünk.
     * 
     * @returns 
     */
    getLastUpdatedByNameAbbr(): string {
        return this.last_updated_by.getNameAbbr().toLocaleUpperCase();
    }

    getNyomdaId(): number {
        return this.nyomda.getId();
    }

    getNyomdaName(): string {
        return this.nyomda.getName();
    }

    getSeverity(): number {
        return this.severity;
    }

    /**
     * Visszakapjuk az aktuális séma objektumot, ami a value tulajdonságban tartalmazza 
     * az éppen aktuális státuszunkat is.
     * 
     * @returns 
     */
    getStatusObject(): IssueSchema {
        return this.getSchemaObjects()?.find(s => s.getValue() === this.main_status);
    }

    getInternalStatusObject(): IssueSchema {
        return this.getSchemaObjects()?.find(s => s.getValue() === this.internal_status);
    }

    /**
     * Visszakapjuk az aktuális sátuszunk szöveges leírását, amit a séma objektum alapján 
     * állítunk elő.
     * 
     * @returns 
     */
    getStatusDescription(): string {
        return this.getStatusObject()?.getDescription();
    }

    getInternalStatusDescription(): string {
        return this.getInternalStatusObject()?.getDescription();
    }

    getFiles(): ScrollFile[] {
        return this.files;
    }

    getTags(): string[] {
        return (typeof(this.tags) === 'string') ? this.tags.split(';') : this.tags;
    }

    public override __init(): void {
        // TODO fixelni, ez az override nem működik valamiért...
        console.log('Init override!');
    }

    /**
     * Minden a tárolt text tartalommal kapcsolatos műveletet itt végezzünk el, ha lehetséges. 
     * 
     * @param value 
     * @returns 
     */
    protected cleanup(value: string): string {

        const mentionRegex = /class="mention/gm;
        const mentionReplace = `class="mention badge badge-secondary`;
        value = value.replace(mentionRegex, mentionReplace);

        return value.replace('<p>&nbsp;</p>', '');
    }

    /**
     * 
     * @param service 
     * @returns 
     */
    setService(supportService: ScrollSupportService, translateService?: CoreTranslateService): Issue {
        this._supportService = supportService;
        this._translateService = translateService;
        return this;
    }
}