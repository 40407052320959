import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Entities } from '@ratkaiga/core-nextgen';

export class SprintGroup extends Entities.CoreEntity {

    public static kind: string = 'ScrollMax\\Scrollsupport\\Entity\\NgSprintGroup';

    name: string;

    date_from: string | NgbDate | NgbDateStruct;

    date_to: string | NgbDate | NgbDateStruct;

    target_score: number = 10000;

    /**
     * A sprint nevét adjuk vissza
     * @returns 
     */
    getName(): string {
        return this.name;
    }

    setName(name: string): SprintGroup {
        this.name = name;
        return this;
    }

    /**
     * @returns 
     */
    getDateFrom(): string | NgbDate | NgbDateStruct {
        return this.date_from;
    }

    setDateFrom(date: string | NgbDate | NgbDateStruct): SprintGroup {
        this.date_from = date;
        return this;
    }

    /**
     * @returns 
     */
    getDateTo(): string | NgbDate | NgbDateStruct {
        return this.date_to;
    }

    setDateTo(date: string | NgbDate | NgbDateStruct): SprintGroup {
        this.date_to = date;
        return this;
    }

    /**
     * @returns 
     */
    getTargetScore(): number {
        return this.target_score;
    }

    setTargetScore(score: number): SprintGroup {
        this.target_score = score;
        return this;
    }

}